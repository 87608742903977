import React, { useRef, useState } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import EventForm from '../Forms/EventForm';
import EventsTable from '../Tables/EventsTable';
import EventGuide from '../UserGuide/EventGuide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faFileExcel, faFilter, faSave } from '@fortawesome/free-solid-svg-icons';
import CustomHover from '../Hover/CustomHover';
import { downloadToExcelGlobal } from '../../utils/download';
import EventFilter from './EventFilter';
import { UseContextClues } from '../../context/ContextClues';
import EventScenarios from './EventScenarios';
import { globalBoolHandler } from '../../utils/globalHandlers';

export default function Events() {

    const toggler = UseToggleContext();
    const context = UseContextClues();

    const downloadRef = useRef();

    const [ target, setTarget ] = useState('');
    const [ drugs, setDrugs ] = useState(null);
    const [ help, askHelp ] = useState(false);
    const [ hover, setHover ] = useState({
        basis: false,
        magnitude: false,
    });
    const [ edit, editEvent ] = useState({});

    
  
    const [ compoundList, updateCompoundList ] = useState({});

    // const [ scenarios, toggleScenarios ] = useState();
    const [ accessToggler, toggle ] = useState({
        filter : false,
        scenarios : false,
    });
    const [ filtered, modify ] = useState({
       filteredEvents: []
    });

    return (
        <>
             <div className={`fixed right-0 bottom-[30px] top-[100px] w-[1400px] bg-white z-[50] py-3 pr-8 transition-all duration-500 shadow-xl ${toggler.aux ==='events' ? " translate-x-0 ":"translate-x-[2000px] "}`}>
                <div style={{height: toggler.pageHeight-150}} className=' px-8 '>
                    <div className='relative object-contain row-span-2 ' id="events" >
                        <div className='flex justify-between'>
                            <div className='text-lg font-bold pb-2 '>Events</div>
                            <div>
                                {/* temp hide pipeline beta until working */}
                                {/* <button className='pr-6 hover:underline' onClick={() => globalBoolHandler("pipelineEvents", toggler.toggle, toggler.setToggle, !toggler.toggle.pipelineEvents)} >Pipeline beta</button> */}
                                <button aria-label='download to excel' type='button' onClick={() => globalBoolHandler('filter', accessToggler, toggle, !accessToggler.filter)} className={`text-lg px-3 py-1  font-medium  ${accessToggler.filter?'bg-light-blue text-white':""}`}>Sort
                                    <FontAwesomeIcon className={`text-xl hover:text-hover-blue pl-2 ${accessToggler.filter?'text-white':"text-light-blue"}`} icon={faFilter} />
                                </button>
                                <button aria-label='download to excel' type='button' onClick={() => globalBoolHandler("scenarios", accessToggler, toggle, !accessToggler.scenarios)} className={`text-lg px-3 py-1 mr-3 font-medium  ${accessToggler.scenarios?'bg-light-blue text-white':""}`}>Scenarios
                                    <FontAwesomeIcon className={`text-xl hover:text-hover-blue pl-2 ${accessToggler.scenarios?'text-white':"text-light-blue"}`} icon={faSave} />
                                </button>
                                <button aria-label='download to excel' type='button' onClick={() => downloadToExcelGlobal(downloadRef, toggler.setStatus)} className='text-lg font-bold pb-2 pr-6 '>
                                    <FontAwesomeIcon className={'text-xl text-light-blue hover:text-hover-blue'} icon={faFileExcel} />
                                </button>
                                <button aria-label='toggle help window' type='button' onClick={() => {askHelp(!help)}} className='text-lg font-bold pb-2 '>
                                    <FontAwesomeIcon className={'text-xl text-light-blue hover:text-hover-blue'} icon={faCircleInfo} />
                                </button>
                            </div>
                        </div>
                        {/* <!-- user guide popup --> */}
                            {help&&<EventGuide />}
                        {/* <!-- Form to create a new event --> */}
                            <EventForm {...{drugs, setDrugs, target, setTarget, edit, editEvent }}/>
           
                        {/* <!-- Table to display events --> */}
                            <EventsTable {...{ convertedList: context.convertedList, updateList: context.updateList, compoundList, updateCompoundList, setDrugs, target, hover, setHover, reference:downloadRef, editEvent, edit, events: filtered.filteredEvents.length>0?filtered.filteredEvents:context.events }} /> 
                        {/* too tips */}
                            <CustomHover {...{hovered:hover.magnitude, right:"right-[145px]", top: "bottom-[-20px]", width:"", text:"text-sm text-center" }}  >
                                Enter a positive number for an increase, 
                                <br/>
                                or a negative number for a decrease.
                            </CustomHover>
                            <CustomHover {...{hovered:hover.basis, right:"right-[65px]", top: "bottom-[-20px]", width:"", text:"text-xs text-center" }}  >
                                Enter ‘relative’ for a % increase or decrease. 
                                <br />
                                    <i>E.g. -50 relative will result in a reduction by half. </i>
                                <br />
                                <br />
                                Enter ‘absolute’ for a volume increase or decrease. 
                                <br />
                                <i>E.g. 4 absolute will result in a value pickup of 4.</i>
                            </CustomHover>
                    </div>
                    {/* <!-- user filter --> */}
                    {accessToggler.filter&&<EventFilter {...{ toggle, events: context.events, modify }} />}
                </div>
                {accessToggler.scenarios&&<EventScenarios {...{ convertedList: context.convertedList, updateList: context.updateList, toggle, accessToggler }} />}
            </div>
        </>
    )
};