import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import EventScenarioCreate from './EventScenarioCreate';
import { UseToggleContext } from '../../context/ToggleContexts';
import EventScenarioControls from './EventScenarioControls';
import EventScenarioMain from './EventScenarioMain';
import { postScenario, updateScenario } from '../../utils/events';
import { UseContextClues } from '../../context/ContextClues';

export default function EventScenarios({ updateList, toggle, accessToggler}) {
    
    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    return (
        <>
            <PopUpSection poptwo={'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full '}>
                <div style={{height: toggler.pageHeight<1000?toggler.pageHeight-130:850}} className={`w-[80%] mx-auto bg-white rounded-xl p-4`}>
                    <div className=' h-full'>
                        <div style={{height: toggler.pageHeight<1000?toggler.pageHeight-230: 753.2}} className='border h-full overflow-y-auto'>
                            {
                                context.controller.currentPage==="POST"?
                                    <EventScenarioCreate {...{control:context.control, controller: context.controller, eventType: "createEvents", handleSubmit: function handleSubmit(e) {
                                        e.preventDefault();
                                        postScenario(context.controller.createEvents, context.control)
                                    }}} />:
                                context.controller.currentPage==="GET"?
                                    <EventScenarioMain {...{toggle, accessToggler, updateList, control:context.control, controller: context.controller}} />:
                                context.controller.currentPage==="PUT"?
                                    <EventScenarioCreate  {...{control:context.control, controller: context.controller, eventType: "editEvent", handleSubmit: function handleSubmit(e) {
                                        e.preventDefault();
                                        updateScenario(context.controller.editEvent.id, context.controller.editEvent, context.control, context.settings.currentScenario, context.controller.editEvent, context);
                                    }}} />:
                                <></>
                            }
                        </div>
                        <EventScenarioControls  {...{ control:context.control, controller: context.controller, toggle, accessToggler }} />
                    </div>
                </div>
            </PopUpSection>
        </>
    );
};