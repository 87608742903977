import React, { useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { handleInputChange, importClaims, importXLSXClaims } from '../../utils/claimsLoad';
import { tailChase } from 'ldrs'
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import ImportGuide from '../UserGuide/ImportGuide';
import ImportPanel from './ImportPanel';
import ImportTableRow from '../Tables/ImportTableRow';
import { UseUserContext } from '../../context/UserContext';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { loadSetup, renderSheetsData } from '../../utils/imports';

export default function ImportPayment({ setPageData }){

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    tailChase.register();

    const [ paymentImportData, setContentData ] = useState({
        file: null,
        headersAvail: true,
    })

    const [step, nextStep] = useState(0);

    const [ table, setTable ] = useState([]);
    
    const [ tableName, setTableName ] = useState("");
    const [ fields, setFeilds ] = useState({});
    // const [ options, setOptions ] = useState({});

    // add error handlers next
    const [ messages, setMessage ] = useState("");

    const [ loaded, setLoaded ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    
    const [ agreement, clicked ] = useState({
        phi: false,
        standard: false,
    });
    const [ validated, validate ] = useState(false);

    // this changed ----------------------------------------- remove before push

    const [ XLSXData, setXLSX ] = useState({
        worksheets: [],
        selectedWorksheet: null,
        worksheetNumber: 0,
        worksheetPopup: false
    });
    const [ options, setOptions ] = useState({});

    
    function handleWorksheetChange (e) {
        const sheetNumber = e.target.value;

        setXLSX(content=> ({
            ...content,
            worksheetNumber: sheetNumber,
        }))

        renderSheetsData(sheetNumber, setTable, setXLSX, paymentImportData.file)
    };

    const handleSubmit = function(e) {
        e.preventDefault();
        if (paymentImportData.file.name.endsWith(".xlsx")) {
            setMessage([]);
            importXLSXClaims(tableName.split(" ").join("_"), XLSXData.worksheets[XLSXData.worksheetNumber], fields, paymentImportData.file, setMessage, setLoaded, setLoading, options, validate, paymentImportData.headersAvail, 'claims');
        } else {
            setMessage([]);
            importClaims(tableName.split(" ").join("_"), fields, paymentImportData.file, setMessage, setLoaded, setLoading, null, validate, paymentImportData.headersAvail, 'payment', setPageData, account.user.userData.username);
        }
    };


    // this changed up to here----------------------------------------- remove before push



    function handleMessage(e) {
        e.preventDefault()
        const noTable = 'Please make sure a table name is provided'
        const noHeaders = "Please map all the necessary column headers below"

        const table = handleMessageHelper(!tableName, noTable)
        const names = handleMessageHelper(Object.keys(fields).length <= 18, noHeaders)

        return [table, names]
    };

    function updateTableName(e) {
        let typed = e.target.value;

        if ( !typed.match("^[\b]") !== null) {
            setTableName(typed);
        };
        if (/^[a-zA-Z][a-zA-Z0-9_ ]*$/.test(typed) ) {
            setTableName(typed);
            setMessage([]);
        } else {
            setMessage(['Table Names must only include alphanumeric characters, underscores, and cannot start with a number!']);
        };
        if (!typed) setMessage([]);
    };

    function handleMessageHelper(what, note) {
        if (what) {
            if (!messages.includes(note)) {
                setMessage([
                    ...messages,
                    note
                ])
            }
        } else {
            if (messages.includes(note)) {
                setMessage(messages.filter(mess => mess !== note))
            };
        };
    };

    // var check;

    // useEffect(() => {
    //     check = function () {
    //         return Object.entries(fields).reduce(field => {
    //             return field
    //         });
    //     }
    // }, [fields])

    const headerKeys = Object.keys(Object.assign({}, ...table));

    function handleClickAgreement(type){
        clicked(clicks => ({
            ...clicks,
            [type]: !agreement[type]
        }));
    };

    useEffect(() => {
        setMessage([]);
    }, [step]);

    const selectStyles = "px-2 py-2  border-none rounded-md w-full ";
    const tableHeadStyles = "border px-2 py-1 bg-primary-dark text-white";
    const tableBodyStyles = "border px-2 text-sm";

    function handleAgreeNextStep(e) {
        if (agreement.standard) {
            nextStep(4);
        } else {
            nextStep(step+1);
        }
        loadSetup(paymentImportData.file, XLSXData.worksheetNumber, setTable, setXLSX, paymentImportData.headersAvail);
    };

    function handleTableload(e) {
        handleInputChange(e, context);
        globalBoolHandler("importPayment", toggler.toggle, toggler.setToggle, false);
    };

    function handleEscape(){
        globalBoolHandler("importPayment", toggler.toggle, toggler.setToggle, false);
    };
    HandleKeydownEscape(handleEscape);


    const invoicePaymentObj = [
        {name: "NDC", search: ["ndc"], show: true},
        // {name: "GPI", search: ["gpi"], show: true},
        {name: "Date of Service", search: ["date"], show: true},
        {name: "LOB1", search: ["business", "manufacturer"], show: true},
        {name: "LOB2", search: ["business", "manufacturer"], show: fields.lob1?true:false},
        {name: "LOB3", search: ["business", "manufacturer"], show: (fields.lob2&&fields.lob2!=="&&&")?true:false},
        {name: "LOB4", search: ["business", "manufacturer"], show: (fields.lob3&&fields.lob3!=="&&&")?true:false},
        {name: "LOB5", search: ["business", "manufacturer"], show: (fields.lob4&&fields.lob4!=="&&&")?true:false},
        // {name: "Pass Through 1", search: ["ndc"], show: true},
        // {name: "Pass Through 2", search: ["ndc"], show: (fields.pass_through_1&&fields.pass_through_1!=="&&&")?true:false},
        // {name: "Pass Through 3", search: ["ndc"], show: (fields.pass_through_2&&fields.pass_through_2!=="&&&")?true:false},
        {name: "Guarantee 1", search: ["ndc"], show: true},
        {name: "Guarantee 2", search: ["ndc"], show: (fields.guarantee_1&&fields.guarantee_1!=="&&&")?true:false},
        {name: "Guarantee 3", search: ["ndc"], show: (fields.guarantee_2&&fields.guarantee_2!=="&&&")?true:false},
        {name: "Formulary ID", search: ["ndc"], show: true},
        {name: "Claim ID", search: ["id"], show: true},
        {name: "Gross Amt 1", search: ["gross"], show: true},
        {name: "Gross Amt 2", search: ["gross"], show: (fields.gross_amt_1&&fields.gross_amt_1!=="&&&")?true:false},
        {name: "Gross Amt 3", search: ["gross"], show: (fields.gross_amt_2&&fields.gross_amt_2!=="&&&")?true:false},
        {name: "Net Amt 1", search: ["net"], show: true},
        {name: "Net Amt 2", search: ["net"], show: (fields.net_amt_1&&fields.net_amt_1!=="&&&")?true:false},
        {name: "Net Amt 3", search: ["net"], show: (fields.net_amt_2&&fields.net_amt_2!=="&&&")?true:false},
    ];

    // console.log('check me outtt-----------------------------------------------------', file)

    return (

        <>
            <div className='absolute top-0 z-[150] h-[100vh] w-[100vw] bg-gray-100 opacity-[1.0]'></div>
            <div className='absolute top-[0%] z-[200] w-full overflow-y-auto'>
                
                <form style={{height: toggler.pageHeight-30}} className={`relative ${step === 0?"translate-y-[0%]":"translate-y-[0%]"} max-w-4xl mx-auto justify-center`} onSubmit={handleSubmit}>
                    <div className=' mx-auto bg-white opacity-1 overflow-y-auto py-6 rounded-md h-full' >
                        <div className='text-xl font-medium text-center'>Invoice / Payment Import Wizard</div>
                        {step === 0 ? <div className=''>
                            <div className='p-4 flex justify-around items-center text-sm border-b-2'>
                                <div className='flex items-center'>
                                    {!paymentImportData.file?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                                    <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                                    upload file
                                </div>
                                <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                                <div className='flex items-center'>
                                    {Object.keys(fields).length  < 8 ?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                                    <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                                    column mapping
                                </div>
                                <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                                <div className='flex items-center'>
                                    {((Object.keys(fields).length >= 9)&&tableName)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                                    <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                                    Ready to load
                                </div>
                                <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                                <div className='flex items-center'>
                                    {((Object.keys(fields).length >= 9)&&loaded)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                                    <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                                    File Loaded
                                </div>
                            </div>
                            <div className='px-6 pb-20'>

                                <div className='text-center font-medium text-sm mt-6'>STEP 1 OF 3</div>
                                <div className='text-center font-bold mt-3 mb-6 text-lg'>Choose a file</div>
                                <div className="text-center h-12 font-medium py-1 text-sm flex items-center justify-center ">
                                {messages.length > 0&&
                                    <div className=''>
                                        {messages.map(message => (
                                            <div className={message.includes('next')?"text-primary-green":`${message.includes('moment')?"text-orange-400 w-[600px]":"text-primary-red"}`}>
                                                <FontAwesomeIcon className={`text-lg mr-4 ${message.includes('next')&&"hidden"}`} icon={faTriangleExclamation}/>
                                                {message}
                                            </div>
                                        ))}
                                    </div>
                                }
                                </div>
                                <ImportPanel {...{file: paymentImportData.file, setFile: setContentData, setMessage}} />
                                <div className='pt-8 pb-1 flex items-center'>
                                    <input className='mr-4 w-[16px] h-[16px]'  type='checkbox' onChange={() => handleClickAgreement('phi')} checked={agreement.phi} />
                                    <span className='text-sm '>“I certify that the attached data file does not contain any <b>Protected Health Information (PHI)</b>.“</span>
                                </div>
                                {/*  ------------------------------------------- hiding until fully functional -------------------------------------------  */}
                                {/* <div className='pt-2 flex items-center'>
                                    <input className='mr-4 w-[16px] h-[16px]'  type='checkbox' onChange={() => handleClickAgreement('standard')} checked={agreement.standard} />
                                    <span className='text-sm '>“I am using a Standard Claims Submission Layout.“</span>
                                </div> */}
                                <ImportGuide />

                            </div>
                            <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 bg-white'>
                                <button className='border mt-4 px-6 py-2 rounded-md border-light-blue text-light-blue font-medium hover:bg-light-blue hover:text-white' onClick={() => globalBoolHandler("importPayment", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                                {agreement.phi&&<button className=' disabled:cursor-not-allowed border float-right mt-4 px-6 py-2 rounded-md bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={handleAgreeNextStep} disabled={!paymentImportData.file ? true : false}>next step</button>}
                            </div>
                        </div>:
                        step === 1 ? <div className='pb-20'>
                        <div className='p-4 flex justify-around items-center text-sm border-b-2'>
                            <div className='flex items-center'>
                                {!paymentImportData.file?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                                <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                                upload file
                            </div>
                            <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                            <div className='flex items-center'>
                                {Object.keys(fields).length  < 9 ?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                                <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                                column mapping
                            </div>
                            <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                            <div className='flex items-center'>
                                {((Object.entries(fields).length >= 9)&&tableName)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                                <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                                Ready to load
                            </div>
                            <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                            <div className='flex items-center'>
                                {((Object.keys(fields).length>=9)&&loaded)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                                <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                                File Loaded
                            </div>
                        </div>
                        <div className='px-6'>
                            <div className="text-center font-medium text-sm mt-6">STEP 2 OF 3</div>
                            <div className="text-center font-bold my-3 text-lg">Map Header Columns</div>
                            <div className="text-center font-light mt-3 mb-2 text-sm">Confirm the mapping of core item attributes with the columns in your import file and add a title to your <span className='underline underline-offset-2'>Table Name</span> </div>
                            <div className="text-center h-12 font-medium py-1 text-sm flex items-center justify-center ">
                                {messages.length > 0&&
                                    <div className=''>
                                        {messages.map(message => (
                                            <div className={message.includes('next')?"text-primary-green":`${message.includes('moment')?"text-orange-400 w-[600px]":"text-primary-red"}`}>
                                                <FontAwesomeIcon className={`text-lg mr-4 ${message.includes('next')&&"hidden"}`} icon={faTriangleExclamation}/>
                                                {message}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className='py-2 flex '>
                                <label htmlFor='tableNameInput' className='w-full'> Table Name
                                    <input name='tableNameInput' className="border-2 w-full rounded-md px-3 py-2" pattern="[a-zA-Z0-9 ]+" placeholder='Add a table name..' value={tableName} onInput={updateTableName} aria-label='Name your table here.'/>
                                </label>
                                {paymentImportData.file.name.endsWith(".xlsx")&&<label className=''><span className='pl-2'>Worksheet</span>
                                    <select className='border h-[42.17px] rounded ml-2 px-2' onChange={handleWorksheetChange} value={XLSXData.worksheetNumber}>
                                        {XLSXData.worksheets.map((worksheet, index)=> (
                                            <option value={index} key={`${index}: selection of ${worksheet}`}>
                                                {worksheet}
                                            </option>
                                        ))}
                                    </select>
                                </label>}
                                <label htmlFor='tableHeaders' className='' ><span className='whitespace-nowrap pl-1'>Headers available?</span>
                                    <div onClick={() => setContentData(prev=>({ ...prev, headersAvail:!paymentImportData.headersAvail}))} className='text-lg font-extrabold cursor-pointer border rounded h-[42.17px] flex items-center justify-center'>
                                        <input name='tableHeaders' className="h-0 w-0 opacity-0 " type='checkbox' />
                                        <span className={paymentImportData.headersAvail?"text-light-blue":"text-primary-dark"}>Yes</span> / <span className={paymentImportData.headersAvail?"text-primary-dark":"text-light-blue"}>No</span>
                                    </div>
                                </label>
                            </div>
                            <table className='w-full mt-2'>
                                    <thead>
                                        <tr className='text-left'>
                                            <th className={tableHeadStyles}>Core Item Attribute</th>
                                            <th className={tableHeadStyles}>Columns in Uploaded Document</th>
                                            <th className={tableHeadStyles}>Sample Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {invoicePaymentObj.map((invoice, idx) => (
                                            invoice.show&&<ImportTableRow keyNumber={idx+1} rowTitle={invoice?.name} name={invoice?.name?.toLowerCase().split(" ").join('_')} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, null, setFeilds)} value={fields[invoice?.name?.toLowerCase().split(" ").join('_')]} included={invoice?.search} optional={false} options={table[0]?.[fields[invoice?.name?.toLowerCase().split(" ").join('_')]]} headerKeys={headerKeys} unavailable={true}/>
                                        ))}
                                    </tbody>
                            </table>
                        </div>
                        <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 bg-white pt-4'>
                            <button className='border  px-6 py-2 rounded-md border-light-blue text-light-blue font-medium hover:bg-light-blue hover:text-white' onClick={() => globalBoolHandler("importPayment", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                            <div className='float-right'>
                                <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(step-1)}>prev step</button>
                                {
                                !tableName||Object.entries(fields).length <= 8 ? <button onClick={handleMessage} className={'cursor-not-allowed border rounded-md px-6 py-2 ml-4 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white'}>Load</button>:
                                <button className='border rounded-md px-6 py-2 ml-4 border-light-blue font-medium text-white bg-light-blue hover:bg-hover-blue ' type='submit'>{loading?<l-tail-chase size={"12"} speed="1.75" color="white" ></l-tail-chase>:'Load'}</button>
                                }
                                {validated&&tableName&&Object.keys(fields).length <= 18&&loaded&&<button className='border rounded-md px-6 py-2 ml-3 bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={() => nextStep(step+1)}>next step</button>}
                            </div>
                        </div>
                        </div>:
                        step === 2 ? <>
                        <div className='px-6'>
                            <div className="text-center mt-20 font-medium text-sm">STEP 3 OF 3</div>
                            <div className="text-center font-bold my-6 text-xl">Check column values</div>
                            <div className="text-center font-light my-6 text-sm">Check the consistency of your table data. Once finished, click load to initiate the process. </div>
                            <div className='text-sm font-medium pb-2'>Tablename: <span className=''>{tableName}</span></div>
                            <div style={{height: toggler.pageHeight*0.5}} className='overflow-auto ' >
                                <table className='text-xs'>
                                    <thead>
                                        <tr className='border' key={"header"}>
                                                {headerKeys.map((key, idx) => (
                                                    <th key={`${idx} - ${key}`} className='px-2 border py-3'>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>

                                    <tbody className='text-center'>
                                        {table.map((el,idx) => (
                                            <tr key={`${idx} table ${el.id}`}>
                                                {Object.values(el).map((val) => (
                                                    <td className='border'>{val}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 pt-4 bg-white'>
                            <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(step-1)}>prev step</button>
                            <button name='tName' value={tableName} className='border rounded-md px-6 py-2 bg-light-blue text-white font-medium hover:bg-hover-blue float-right' onClick={handleTableload}>load table</button>
                        </div>
                        </>:
                        step === 4 ? <>
                        <div style={{height: toggler.pageHeight-110}} className='flex flex-col justify-center items-center mx-auto gap-20' > 
                            <label className='flex flex-col gap-20'> ---- under construction ---
                                
                                <select className='border py-3 px-5'>
                                    <option>---select one---</option>
                                </select>
                            </label>
                            <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(0)}>prev step</button>
                        </div>
                    </>:null}
                    </div>
                </form>
            </div>
        </>
    )
};