import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import FormButton from "../Buttons/FormButton";
import { useNavigate } from "react-router-dom";
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';
import { login } from '../../utils/accounts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { globalBoolHandler } from '../../utils/globalHandlers';


export default function AuxLogin({ setAuth, toggle, setTogg}){
    
        const navigate = useNavigate()
        const account = UseUserContext();
        const toggler = UseToggleContext();
    
        const [ pass, setPass ] = useState();
        const [ loading, setLoading ] = useState(false);
        const [ message, setMessage ] = useState("");
        const [ username, setUsername ] = useState("");
    
        const labelStyles = "flex flex-col ";
        const inputStyles = "px-2 py-3 border rounded mt-2 mb-3 ";
    
        async function handleLogin(e){
            e.preventDefault();
            setMessage('');
            setLoading(true);
            
            const authenticated = await login(
                {
                    "username": username,
                    "password": pass,
                    "scope": "me"
                },
                account,
                setAuth
            );
    
            if (authenticated) {
                setMessage('');
                navigate('/');
                setLoading(false)
    
                globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, false, "showTime", false)
    
            } else {
                setMessage('Your username and password combination may be incorrect, please try again.');
                setLoading(false);
            };
        };

    return (
        <>
            <PopUpSection 
                popone={"absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]"} >
                <div className='relative flex flex-col'>
                    <form className={`flex flex-col justify-center items-center  h-[100vh]`} onSubmit={handleLogin}>
                        <div className="flex relative bg-primary-light flex-col border py-20 px-24 rounded-xl shadow-lg shadow-gray-600">
                            <button aria-label='close panel' type='button' className={"absolute top-3 right-3 "} onClick={() => globalBoolHandler('challenge', toggle, setTogg)}>
                                <FontAwesomeIcon className={`text-sm transition-all duration-500 hover:rotate-[180deg] `} icon={faXmark}/> 
                            </button>
                            <label htmlFor="username" className={labelStyles}>
                                Username<input name="username" className={inputStyles} type="text" autoComplete="username" aria-labelledby="Enter your username" onChange={e => setUsername(e.target.value)} required/>
                            </label>
                            <label htmlFor="password" className={labelStyles}>
                                Password<input name="password" className={inputStyles} type="password" autoComplete="current-passord" aria-labelledby="Enter your password for above username" onChange={e => setPass(e.target.value)} required/>
                            </label>
                            <div className={`${message.length>0?"my-2":''} text-primary-red max-w-[250px] text-xs`}>{message}</div>
                            <FormButton buttonText={"log in"} loading={loading} loadingSize={15} />
                        </div>
                    </form>
                </div>   
            </PopUpSection> 
        </>
    );
};