import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <div className='fixed z-[150] left-0 bottom-0 right-0 h-[30px] bg-background-light border-t-4 w-full px-2 text-[0.65em] justify-end pr-12 flex items-center'>
                <span className=''>259Rx LLC, Updated February 2025. By using this website, you agree with our <Link className='hover:underline hover:text-blue-500' to={'/privacy'}>Privacy Policy</Link> </span>
            </div>
        </>
    )
}