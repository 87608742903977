import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft, faPen, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { deleteRebateCredit } from '../../utils/creditForecast';

export default function RebateCreditWindow({data, set, change, setting, handleReturnToList}) {

    function handleDelete(e, id) {
        e.stopPropagation();
        deleteRebateCredit(id, data.creditlist, data.creditSelect, set, change);
    };
    function handleChange(e, details) {
        e.stopPropagation();
        change('edit')
        set(data => ({
            ...data,
            editList: details
        }));
    };
    
    function selectList(e, credit) {
        e.stopPropagation();
        set(data => ({
            ...data,
            creditSelect: credit
        }));
    }
    
    function clearSelect(e) {
        e.stopPropagation();
        handleReturnToList();
        set(data => ({
            ...data,
            creditSelect: null
        }));
    };

    const listStyles = 'border-b rounded hover:bg-primary-dark hover:text-white py-4 px-4 flex justify-between';

    return (
        <>  
            {(setting!=="list"||data.creditSelect)&&<button type='button' onClick={e=>clearSelect(e)} className='float-right pt-2'>
                <FontAwesomeIcon className={`text-2xl text-light-blue hover:text-hover-blue`} icon={faCircleLeft}/> 
            </button>}
            <di className='border p-1 mb-10 h-[650px] overflow-y-auto flex flex-col gap-1 rounded'>
                {!data.creditSelect&&data.creditlist&&Object.keys(data.creditlist).map((creditKey, index) => (
                    <li onClick={e =>selectList(e, creditKey)} key={`${index}: rebate credit console ${creditKey}`} className={listStyles+' cursor-pointer'}>
                        {creditKey}
                    </li>
                ))}
                {data.creditSelect&&<table className='text-left '>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>drugname</th>
                            <th>NDC</th>
                            <th className='px-5'>Mag</th>
                            <th>Type</th>
                            <th className='text-center w-[50px]'>Edit</th>
                            <th className='text-center w-[50px]'>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(data.creditlist[data.creditSelect]).map((creditDetails, index) => (
                            <tr key={`${index}: rebate credit details console ${creditDetails}`} className={`h-[70px] border-t border-b ${index%2===0?"bg-gray-300":""}`}>
                                <td>
                                    {index+1}.
                                </td>
                                <td>
                                    {creditDetails[1].drugname}
                                </td>
                                <td>
                                    {creditDetails[1].ndc}
                                </td>
                                <td  className='px-5 text-right'>
                                    {creditDetails[1].mag}
                                </td>
                                <td className='capitalize'>
                                    {creditDetails[1].credit_type.split('_').join(' ')}
                                </td>
                                <td>
                                    <button className='mx-auto w-[50px]' type='button' onClick={e => handleChange(e, creditDetails[1])}>
                                        <FontAwesomeIcon className={`text-2xl text-light-blue hover:text-hover-blue`} icon={faPen}/> 
                                    </button>
                                </td>
                                <td>
                                    <button className='mx-auto w-[50px]' type='button' onClick={e => handleDelete(e, creditDetails[1].id)}>
                                        <FontAwesomeIcon className={`text-2xl text-primary-red hover:text-red-700`} icon={faXmarkCircle}/> 
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </di>
        </>
    )
}