import { extractFromLocal } from "./loadSave"

export async function specialtySweeper(listname, set) {
    try {   
        set(prev => ({
            ...prev,
            loadSpecial: true
        }));
        const response = await fetch(`/api/specialty_sweeper?listname=${listname}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            set(prev => ({
                ...prev,
                specialty : listname,
                loadSpecial: false,
                specialtyData: data
            }));
        };

    } catch (err) {
        set(prev => ({
            ...prev,
            loadSpecial: false
        }));
        console.log(err)
    };
};

export async function fuzzySweeper(orgId, formularyId, set) {
    try {
        set(prev => ({
            ...prev,
            loadFuzzy: true
        }));
        const response = await fetch(`/api/fuzzy_rate_chart_sweeper?orgid=${orgId}&formularyid=${formularyId}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });

        const data = await response.json();
        if (response.ok) {
            set(prev => ({
                ...prev,
                loadFuzzy: false,
                fuzzyData: data
            }));
        };
    } catch (err) {
        set(prev => ({
            ...prev,
            loadFuzzy: false
        }));
        console.log(err)
    };
};

export async function specialtyAddNDC(listname, list) {
    try {
        const payload = {
            listname,
            ndcs: list
        };

        const response = await fetch(`/api/specialty_sweeper/add_ndcs`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify(payload)
        });

        const data = await response.json();

        if (response.ok) {
            alert(data.detail);
        };

    } catch (err) {
        console.log(err);
    };
};