import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import XButton from '../Buttons/XButton';
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import FormButton from '../Buttons/FormButton';
import { copyFormulary } from '../../utils/formularySelection';
import { UseContextClues } from '../../context/ContextClues';
import CustomFormulary from '../CustomSelect/CustomFormulary';

export default function CopyFormulary() {

    const toggler = UseToggleContext();
    const context = UseContextClues();

    const [ payload, set ] = useState({
        source : "",
        destination : "",
        loading : "",
        index: 100,
    });

    function handleChoice(__, id) {
        set(data => ({
            ...data,
            source: id
        }));
    };

    function handleClose() {
        return globalBoolHandler("copyFormularyNewOrg", toggler.toggle, toggler.setToggle, false);
    };
    HandleKeydownEscape(handleClose);

    function handleSubmit(e) {
        e.preventDefault();
        return copyFormulary(payload.source, payload.destination, set, handleClose, payload.index/100);
    };

    const labelStyles = "flex flex-col";
    const inputStyles = "py-3 px-3 border rounded mt-2 mb-4";

    return (
        <>
            <PopUpSection>
                <div className='relative w-[600px] h-[490px] mx-auto bg-white'>
                    <XButton clickHandler={handleClose} additionalClasses={'pt-2'}/>
                    <div className='p-8'>
                        <form onSubmit={handleSubmit} className='border p-8 '>
                            <label className={labelStyles}>formulary Name
                                <CustomFormulary dropHeight={"max-h-[300px]"} data={context.formularies} value={payload.source} clickHandle={handleChoice} />
                            </label>
                            <label className={labelStyles}>Organization Name
                                <input name="destination" className={inputStyles} onChange={e => globalChangeHandler(e, null, set)} />
                            </label>
                            <label className='p-4 border rounded flex mt-4 items-center'><span className='pr-4'>Index:</span>
                                <input name='index' onChange={e => globalChangeHandler(e, null, set)} className="border rounded font-medium py-2 px-4  w-full" type='number' min={0}  max={100} value={payload.index} />%
                            </label>
                            <FormButton buttonText={'create copy'}  loading={payload.loading} marginTop={'mt-8'}/>
                        </form>
                    </div>
                </div>
            </PopUpSection>    
        </>
    );
};