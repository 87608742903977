import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import XButton from '../Buttons/XButton';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import FormButton from '../Buttons/FormButton';
import { UseContextClues } from '../../context/ContextClues';
import {  runRepriceOnlyModel } from '../../utils/resultsSummary';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { handleInputChange } from '../../utils/claimsLoad';
import ResultsTable from '../Tables/ResultsTable';

export default function RepriceOnlyModel() {
    
    const toggler = UseToggleContext();
    const context = UseContextClues();
    
    const [ chosenSettings, chooseSettings ] = useState({
        formularySelection: null,
        repriceOnlyModelList: null,
        loadingInitial: false,
        loading: false
    });
    
    function handleMultiSubmit(e, selectedStartDateValue = context.settings.claims.startDate, selectedEndDateValue = context.settings.claims.endDate, year1StartDateValue = context.settings.year.year1) {
        var selectedStartDateObj = new Date(selectedStartDateValue);
        var selectedEndDateObj = new Date(selectedEndDateValue);
        var base_midpointTimestamp = selectedStartDateObj.getTime() + (selectedEndDateObj - selectedStartDateObj) / 2;
        var base_midpoint = dateToDaysSinceEpoch(new Date(base_midpointTimestamp));
    
        var year1StartDateObj = new Date(year1StartDateValue);
        year1StartDateObj.setDate(year1StartDateObj.getDate() + 180);  // add 180 days
        var yr1midpoint = dateToDaysSinceEpoch(year1StartDateObj);
    
        var annualizationFactor = 365 / (selectedEndDateObj - selectedStartDateObj) * (1000 * 60 * 60 * 24);
        annualizationFactor = parseFloat(annualizationFactor.toFixed(3));
    
        function dateToDaysSinceEpoch(dateObj) {
            // Convert milliseconds since Epoch to days since Epoch
            return Math.floor(dateObj.getTime() / (1000 * 60 * 60 * 24));
        }
        e.preventDefault();

        runRepriceOnlyModel(context.exclusionSettings, base_midpoint, yr1midpoint, annualizationFactor, chosenSettings.formularySelection, context.casenumber, chooseSettings)
    };

    function xOutPanel() {
        globalBoolHandler("repriceOnlyModel", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(xOutPanel);

    const lineStyles = 'w-full h-full flex items-center px-3'
    const labelStyles = 'flex flex-col mt-2 tracking-widest capitalize'
    const buttonStyles = 'px-20 py-3 border bg-light-blue hover:bg-hover-blue text-white font-bold rounded-lg'

    return (
        <>
            {!chosenSettings.repriceOnlyModelList?<PopUpSection>
                <div className='relative bg-white w-[400px] h-full mx-auto'>
                    <XButton additionalClasses={'mt-3'} clickHandler={() =>xOutPanel()} />
                    <div className='text-center pt-3'> 
                        Reprice only model
                    </div>
                    <form onSubmit={handleMultiSubmit} className='p-8'>
                        <label className={labelStyles}>base period start date
                            <input
                                name="startDate" 
                                value={context.settings.claims.startDate} 
                                type="date" 
                                className='border p-3 rounded my-1'
                                onChange={e => handleInputChange(e, context)} 
                                required
                            />
                        </label>
                        <label className={labelStyles}>base period end date
                            <input
                                name="endDate" 
                                value={context.settings.claims.endDate} 
                                type="date" 
                                className='border p-3 rounded my-1'
                                onChange={e => handleInputChange(e, context)} 
                                required
                            />
                        </label>
                        <label className={labelStyles}>Year 1 start date
                            <input
                                name='year1'
                                type="date" 
                                className='border p-3 rounded my-1'
                                onChange={e => handleInputChange(e, context)} 
                                value={context.settings.year.year1} 
                                required
                            />
                        </label>
                        <label><span className='tracking-widest'>Formulary selection </span>
                            <select className='border w-full p-3' required onChange={(e) => globalBoolHandler('formularySelection', chosenSettings, chooseSettings, e.target.value)}>
                                <option value={''}>
                                    --- Choose a formulary ---
                                </option>
                                {context.formularies.map((formulary, index)=>(
                                    <option key={`${index}: reprice only selection ${formulary.formularyid}`}>
                                        {formulary.formularyid}
                                    </option>
                                ))}
                            </select>
                            <FormButton loading={chosenSettings.loadingInitial} buttonText={'run reprice only'} />
                        </label>
                    </form>
                </div>    
            </PopUpSection>:
            <PopUpSection poptwo={'absolute top-[55%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full overflow-y-auto'} >
                <div style={{height: toggler.pageHeight<850?toggler.pageHeight:toggler.pageHeight}}  className='mx-auto w-[500px] overflow-y-auto'>
                    <div className='px-6 pt-4 bg-background-light border-8 border-primary-dark '>
                        <div className='tracking-widest text-xl mb-3 font-bold'>
                            {chosenSettings.formularySelection}
                        </div>
                        <ResultsTable omitTotals={true} feedYear={'Year1'} context={chosenSettings.repriceOnlyModelList} next={context} dealLength={1} annualization={context?.mainData?.annualization} />
                        <div className=' rounded-b-xl flex justify-between  py-4'>
                            <FormButton buttonText={'close'} marginTop={'mt-0'} onClick={() => xOutPanel()} className={buttonStyles} />
                        </div>
                    </div>
                </div>
            </PopUpSection>}
        </>
    );
};