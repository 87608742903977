import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import XButton from '../Buttons/XButton';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { getCreditlists } from '../../utils/creditForecast';
import RebateCreditWindow from './RebateCreditWindow';
import RebateCreditForm from './RebateCreditForm';

export default function RebateCreditConsole() {

    const toggler = UseToggleContext();

    const [ setting, change ] = useState("list");

    const [ data, set ] = useState({
        creditlist: null,
        editList: null,
        creditSelect: null,
    });

    const [ fields, setFields ] = useState({
        listname : '',
        drugname : '',
        credit_type : '',
        ndc : '',
        startdate : '',
        mag : ''
    });
    
    useEffect(() => {
        getCreditlists(set);
    }, []);
    
    function handleXout() {
        globalBoolHandler("rebateCredit", toggler.toggle, toggler.setToggle, !toggler.toggle.rebateCredit)
    };
    HandleKeydownEscape(handleXout);

    function handleReturnToList() {
        setFields({
            listname : '',
            drugname : '',
            credit_type : 'per unit',
            ndc : '',
            startdate : '',
            mag : '0.0'
        })
        change("list")
    };

    return (
        <>
            <PopUpSection poptwo={'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full '}>
                <div style={{height: toggler.pageHeight<800?toggler.pageHeight:800}} className='relative w-[800px]  mx-auto bg-white rounded-lg'>
                    <div className='relative border-b bg-primary-dark py-3 pl-6 text-white rounded-t-lg tracking-widest uppercase text-sm'>
                        Rebate credit console
                        <XButton additionalClasses={''} clickHandler={handleXout} />
                    </div>
                    <div className='overflow-y-auto h-full pb-10'>
                        {setting === "create"? 
                            <div className='p-10 pt-5'>
                                <RebateCreditForm {...{fields, setFields, data, set, change, selected: data.creditSelect, setting, handleReturnToList}} />
                            </div>
                            :
                            setting === "list" ?
                            <div className='px-12 pt-3 '>
                                <button className='uppercase tracking-wider border py-1 px-3 bg-light-blue hover:bg-hover-blue text-white rounded hover:shadow-lg mb-2' onClick={() => change('create')}>
                                    {data.creditSelect?"add to list":"create new"}
                                </button>
                                <RebateCreditWindow {...{data, set, change, setting, handleReturnToList}} />
                            </div>
                            :
                            setting === "edit"?
                            <div className='p-10 pt-5'>
                                <RebateCreditForm {...{fields, setFields, data, set, change, editFields: data.editList, handleReturnToList }}  />
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </PopUpSection>
        </>
    );
};