import React, { useEffect, useRef, useState } from 'react';
import HandleOutsideClick from '../../hooks/HandleOutsideClick';
import { produceDate } from '../../utils/globalHandlers';
import { previewClaimsDistribution } from '../../utils/claimsLoad';
import { UseToggleContext } from '../../context/ToggleContexts';


export default function SearchField({setName, set, current, addedStyle, placeholder, searchId, name, textBlack, specialObj, dateRange}){

    const boxRef = useRef(null);
    const focusRef = useRef(null);

    const toggler = UseToggleContext();

    const [ selected, setSelected ] = useState('')
    const [ focus, toggleFocus ] = useState(true);

    const [ delayedTimer, setDelayedTimer ] = useState(null);
    const [ searchWord, setFilter ] = useState(null);

    var focused = HandleOutsideClick(boxRef); 

    function handleMouseDown() {
        toggleFocus(true)
    };

    // function handleKeydown(e) {
    //     if (e.key==="Escape") {
    //         toggleFocus(false)
    //     };
    //     if (e.key==="ArrowDown") {
    //         setSelected('')
    //         const item = focusRef.current;
    //         item.focus();
    //     };
    // };

    function openDateRange(tableName, event) {
        previewClaimsDistribution(tableName, event, toggler.setDateRange) 
    };

    // this closes the date popup once clicked
    useEffect(() => {
        toggler?.setDateRange(data => ({
           ...data,
           focus: false
        }));    
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [focus])

    function handleMouseDateRangeHover(event, tablename) {
        const timer = setTimeout(() => {
            openDateRange(tablename, event)
        }, 2000);
        setDelayedTimer(timer)
    };

    function handleMOuseLeave() {
        clearTimeout(delayedTimer);
        toggler?.setDateRange({
            location: {
                pageX: 0,
                pageY: 0
            },
            data: [],
            dates: {
                min: null,
                max: null,
            },
            focus: false
        });
    };

    function handleSelection(e, el) {
        setSelected([el?.[1]?.table_name]);
        setName(el?.[1]?.table_name);
        boxRef.current.blur();
        toggleFocus(false);
        toggler.setDateRange(data => ({
            ...data,
            focus: false
        })); 
        clearTimeout(delayedTimer);   
    };
    
    function handleSimpleSelection(e) {
        setSelected([e.target.innerHTML]);
        setName(e.target.innerHTML);
    };

    function handleSearchWord(e) {
        setFilter(e.target.value);
        setName(e.target.value)
    };

    return (
        <div className='relative'  ref={boxRef}>
            <input  name={name}  className={addedStyle} type="text" id={searchId} placeholder={placeholder} onChange={handleSearchWord} onMouseDown={handleMouseDown} value={current} autoComplete='off' required/>
            {focus&&!focused&&
                <ul ref={focusRef} className={`absolute w-full bg-white z-20  overflow-y-auto border border-t-0 rounded-b-lg shadow-xl max-h-[350px] `}>
                    {specialObj?
                    specialObj?.filter(x => x !== '' && !selected.includes(x) && (x[1].table_name)?.toLowerCase().includes(searchWord?.toLowerCase()) ).map((el, idx) => (
                        <li key={`Search query list table name: ${el?.[1]?.table_name}, index: ${idx}`} className={`relative p-2 hover:bg-black ${textBlack?'text-black':""} hover:text-white border cursor-pointer`} 
                            onClick={e => handleSelection(e, el)}
                            onMouseEnter={e => handleMouseDateRangeHover(e, el?.[1]?.table_name)}
                            onMouseLeave={handleMOuseLeave}
                            >
                                <div className='flex justify-between'>
                                    <span className='font-bold'>{el?.[1]?.table_name}</span>
                                    <span className=' font-medium'>{el?.[1]?.created_by}</span>
                                </div>
                                <div className='text-[9px] text-gray-400 font-light'>{produceDate(el?.[1]?.created_at)}</div>
                        </li>
                    )):
                    current?.length>1&&set?.filter(x => x !== '' && !selected.includes(x)).map((el, idx) => (
                        <li key={`Search query list item: ${el}, index: ${idx}`} 
                            className={`p-2 hover:bg-black ${textBlack?'text-black':""} hover:text-white cursor-pointer`} 
                            onClick={handleSimpleSelection}
                        >
                            {el}
                        </li>
                    ))}   
                </ul>
            }
        </div>
    );
};