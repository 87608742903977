import * as XLSX from 'xlsx';
import { extractFromLocal } from './loadSave';

export const convertCsvFile = function(string, headers, set) {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + headers?1:0).split("\n");
    
    const arr = csvRows.slice(0,100).map((el, index) => {
        const vals = el.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        // const vals = el.split(',');
        const obj = csvHeader.reduce((o,h,i) => {
            o[h] = vals[i]
            return o
        }, {});

        return obj
    });

    set(arr.slice(1,200))

};

export const convertPsvFile = function(string, set) {
    const csvHeader = string.slice(0, string.indexOf("\n")).split("|");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    
    const arr = csvRows.slice(0,100).map(el => {
        const vals = el.split('|');
        const obj = csvHeader.reduce((o,h,i) => {
            o[h] = vals[i]
            return o
        }, {});
        return obj
    });


    set(arr.slice(1,200))
};

export const convertLargePsvFile = function(string, set) {
    const unit8view = new Uint8Array(string)
    const parseData = Uint8Array.from(unit8view)
    const blob = new Blob([parseData])
    
    const reader = blob.stream().getReader();
    const decoder = new TextDecoder();

    reader.read().then( function process({ done, value }) {
        const str = decoder.decode( value, { stream: true } );

        const csvHeader = str.slice(0, str.indexOf("\n")).split("|");
        const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");

        const arr = csvRows.map(el => {
            const vals = el.split('|');
            const obj = csvHeader.reduce((o,h,i) => {
                o[h] = vals[i]
                return o
            }, {});
            return obj
        });

        
        set(arr.slice(1,200))
    });
                
};

export function renderSheetsData(sheetNumber, setTable, setXLSX, file) {
    const fileReader = new FileReader();
    fileReader.onload = function(e) {
        const text = e.target.result;
        const workbook = XLSX.read(text, { type:'buffer' });
        
        
        const wroksheet = workbook.Sheets[workbook.SheetNames[sheetNumber]];
        
        const workset = XLSX.utils.sheet_to_json(wroksheet);

        setTable(workset);
        
        setXLSX(previousData => ({
            ...previousData,
            worksheetPopup: true,
            worksheets: workbook.SheetNames,
            data: workset
        }));

    };
    fileReader.readAsArrayBuffer(file)
}

export function loadSetup(file, worksheet, setTable, setXLSX, headersAvail){
    const fileReader = new FileReader();
    if (file.name?.endsWith("xlsx")) {
        return renderSheetsData(worksheet, setTable, setXLSX, file)
    } else {
        fileReader.onload = function(e) {
            const text = e.target.result;
            if (file.type.includes("csv")) {
                return convertCsvFile(text, headersAvail, setTable);
            } else {
                if (file.size > 500000) {
                    return convertLargePsvFile(text, setTable);
                } else {
                    return convertPsvFile(text, setTable);
                }
            }
        };

        if (file.type?.includes("csv")) {
            fileReader.readAsText(file)
        } else if (file.name?.endsWith('.psv')) {
            if (file.size > 500000) {
                fileReader.readAsArrayBuffer(file.slice(0,1024))
            } else {
                fileReader.readAsText(file)
            }
        }
    };
};

export async function getSubmissionLayouts(set) {
    try {
        const response = await fetch(`/api/get_submission_layouts`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });
        const data = await response.json();
        if (response.ok) {
            set(prev=> ({
                ...prev,
                standardLayouts: data
            }))
        };
    } catch (err) {
        console.log(err);
    };
};