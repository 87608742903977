import React, { useEffect, useState } from 'react';
import { handle2Factor, resendOTP } from '../../utils/accounts';
import FormButton from '../Buttons/FormButton';
import { UseUserContext } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateForward} from "@fortawesome/free-solid-svg-icons";

export default function TwoFactor({challengeId, username, twoFactor}) {

    const account = UseUserContext();

    const [ twoFactorCode, authenticateTwoFactor ] = useState(null);
    const [ loading, setLoader ] = useState(null);
    const [ response, setR ] = useState({});
    const [ resend, timer ] = useState(10);

    useEffect(() => {
        var setTimer = setInterval(() => {
            timer(number => number-1)
        }, 1000);
        return () => clearInterval(setTimer)
    }, [resend]);

    const labelStyles = "flex flex-col";
    const inputStyles = "px-3 py-3 border rounded mt-2 mb-3 tracking-[10px] text-center";

    return (
        <>
            <form className={`flex flex-col justify-center items-center h-[100vh] max-w-[500px] mx-auto`} onSubmit={e => handle2Factor(e, twoFactorCode, username, challengeId, setLoader, account, setR)}>
                <div className="flex relative bg-primary-light flex-col border py-28 px-24 rounded-xl shadow-lg shadow-gray-600">
                    {/* {addXMark&&<button aria-label='close panel' type='button' className={"absolute top-3 right-3 "} onClick={() => globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, false)}> */}
                        {/* <FontAwesomeIcon className={`text-sm transition-all duration-500 hover:rotate-[180deg] ${false ? "" : ""}`} icon={faXmark}/>  */}
                    {/* </button>} */}
                    <div className="text-2xl font-medium pb-4">2FA</div>
                    <div className="text-sm pb-4">Please enter the <span className='font-bold'>6-digit</span> code sent to your phone number to confirm your account.</div>
                    <label htmlFor="twoFactor" className={labelStyles}>
                        <input name="twoFactor" className={inputStyles} type="text" maxLength={6} minLength={6} autoComplete="twoFactor" aria-labelledby="Enter your two factor code sent to your mobile device" onChange={e => authenticateTwoFactor(e.target.value)} required/>
                    </label>
                    {response.detail&&<div className={`${response.detail.length>0?"my-2":''} text-primary-red max-w-[250px] text-xs`}>{response.detail}</div>}
                    <FormButton buttonText={"Verify"} loading={loading} loadingSize={15} />
                    <div className='text-xs mt-5 tracking-tighter'>
                        {resend<=0?<button type='button' onClick={() => (resendOTP(challengeId, setR), timer(10))} className=' text-blue-600 hover:text-blue-900 underline underline-offset-2 ' >Resend verification code <FontAwesomeIcon className={` text-blue-600 hover:text-blue-900 cursor-pointer `}  icon={faArrowRotateForward}/></button>:
                        `Resend available in ${resend}`}
                    </div>
                </div>
            </form>
        </>
    );
};