import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler } from '../../utils/globalHandlers';
import SimpleButton from '../Buttons/SimpleButton';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import moment from 'moment';

export default function TableManagement() {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    function handleEscape(){
        globalBoolHandler("tableManagement", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(handleEscape);

    return (
        <>
            <PopUpSection 
                poptwo={`absolute left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full  ${toggler.pageHeight<800?'top-[1%] bottom-[2%] translate-y-[0%]':"top-[50%] translate-y-[-50%]"}`}
            >   
                <div style={{height: toggler.pageHeight<800?toggler.pageHeight-50:"800px"}} className={`w-[1200px] bg-white mx-auto overflow-y-auto shadow-2xl rounded-2xl`}>
                    <table className='w-full rounded-xl mb-[200px]'>
                        <thead>
                            <tr className='bg-primary-dark text-white text-center uppercase tracking-widest sticky top-0 '>
                                <td className='py-4 border-x  '>
                                    Table name
                                </td>
                                <td className='py-4 border-x '>
                                    created by
                                </td>
                                <td className='py-4 border-x '>
                                    date
                                </td>
                                {/*  ------------------------------------------- hiding until fully functional -------------------------------------------  */}
                                {/* <td className='py-4 border-x '>
                                    vendor pricing
                                </td> */}
                                <td className='py-4 border-x '>
                                    delete
                                </td>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {context.models?.modelList?.claims_tables_details.map((table, index) => (
                                <tr key={`${index}: table management for ${table.table_name}`} className='hover:bg-light-blue hover:text-white border'>
                                    <td className='py-3 '>{table.table_name}</td> 
                                    <td className='py-3 '>{table.created_by}</td> 
                                    <td className='py-3 '>{moment(table.created_at).format('M/D/YYYY - k:m:s')}</td> 
                                    {/*  ------------------------------------------- hiding until fully functional -------------------------------------------  */}
                                    {/* <td className=''>
                                        <SimpleButton {...{ tablename: table.table_name}}  />
                                    </td> */}
                                    <td className=''>
                                        <SimpleButton {...{tableName:table.table_name, setModels: context.setModels}} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='w-[1200px] bg-primary-dark absolute bottom-0 rounded-b-2xl'> 
                        <button className='hover:text-white border rounded-lg  py-2 w-40 hover:bg-light-blue bg-white my-6 ml-8' onClick={() => globalBoolHandler("tableManagement", toggler.toggle, toggler.setToggle, false)}>
                                Cancel
                        </button>
                    </div>
                </div>
            </PopUpSection>
        </>
    )
};