import React from 'react';
import { UseContextClues } from '../../context/ContextClues';
import FormButton from '../Buttons/FormButton';
import { globalUpdateKeyValue } from '../../utils/globalHandlers';
import moment from 'moment';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EventScenarioCreate({control, controller, eventType, handleSubmit }) {
    const context = UseContextClues();

    const labelStyles = 'flex flex-col border p-3';
    const inputStyles = 'border w-full py-2 px-2 rounded';

    function handleAddEvent(e) {
        e.stopPropagation();
        const { name } =  e.target;
        const checkArray = [...controller[eventType].event_ids];

        if (checkArray.includes(+name)) {
            globalUpdateKeyValue("event_ids", checkArray.filter(id => id !== +name), control, eventType)
        } else {
            checkArray.push(+name)
            globalUpdateKeyValue("event_ids", checkArray, control, eventType)
        }
    };

    function handleClear() {
        control(prev => ({
            ...prev,
            [eventType]:  {
                name: "",
                description: "",
                event_ids: [],
            }
        }));
    };

    function goBack() {
        control(prev => ({
            ...prev,
            currentPage:"GET",
            [eventType]:  {
                name: "",
                description: "",
                event_ids: [],
            }
        }));
    };

    return (
        <>
            <div className='h-full'>
                <form className={`border flex flex-col h-full `} onSubmit={e => handleSubmit(e)} >
                    <label className={labelStyles}>name
                        <input value={controller[eventType].name} onChange={(e) => globalUpdateKeyValue("name", e.target.value, control, eventType)} className={inputStyles}/>
                    </label>
                    <label className={labelStyles}>description
                        <textarea value={controller[eventType].description} onChange={(e) => globalUpdateKeyValue("description", e.target.value, control, eventType)} className={inputStyles+" min-h-[90px]"} />
                    </label>
                    <label className={labelStyles}>
                        <div className='h-[400px] overflow-y-auto w-full '>
                            <table className='text-left w-full'>
                                <thead className='sticky top-0 box-border  bg-white'>
                                    <tr className='shadow'>
                                        <th className='pr-3'>
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </th>
                                        <th className='max-w-[300px]'>
                                            Event Name
                                        </th>
                                        <th>
                                            Start Date
                                        </th>
                                        <th className='px-3'>
                                            Target
                                        </th>
                                        <th className='pr-3'>
                                            Type
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='overflow-y-auto h-[300px]'>
                                    {context.events.map(event => (
                                        <tr>
                                            <td className='pr-3'>
                                                <input checked={controller[eventType].event_ids.includes(event.id)} name={event.id} className='w-5 h-5' type='checkbox' onChange={e => handleAddEvent(e)} />
                                            </td>
                                            <td>
                                                {event.ename}
                                            </td>
                                            <td>
                                                {moment(event.startdate).format('MMM Do, YYYY')}
                                            </td>
                                            <td className='max-w-[300px] p-3'>
                                                {event.target}
                                            </td>
                                            <td>
                                                {event.etype}
                                            </td>
                                        </tr>
                                    ))} 
                                </tbody>
                            </table>
                        </div>
                    </label>
                    <div className='flex gap-20 p-4'>
                        {eventType==="editEvent"?
                        <FormButton typeB={true} opposite={true} buttonText={'go back'} onClick={goBack}  marginTop={"mt-0"}/>:
                        <FormButton typeB={true} opposite={true} buttonText={'clear'} onClick={handleClear}  marginTop={"mt-0"}/>}

                        <FormButton buttonText={'save'} marginTop={"mt-0"} />
                    </div>
                </form>
            </div>
        </>
    );
};