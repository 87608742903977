import React, {  useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import FormButton from '../Buttons/FormButton';
import { handleInputChange, importClaims, importClaimSimple, importXLSXClaims, uploadCSV2500 } from '../../utils/claimsLoad';
import { tailChase } from 'ldrs'
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import ImportGuide from '../UserGuide/ImportGuide';
import ImportPanel from './ImportPanel';
import ImportTableRow from '../Tables/ImportTableRow';
import { UseUserContext } from '../../context/UserContext';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { getSubmissionLayouts, loadSetup, renderSheetsData } from '../../utils/imports';

export default function ImportClaim({setPageData, mainPageData}){

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    tailChase.register();

    const [ claimsImportData, setContentData ] = useState({
        file: null,
        headersAvail: true,
        validated: false,
     
    });
    const [ loaded, setLoaded ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ step, nextStep] = useState(0);
    const [ table, setTable ] = useState([]);
    const [ tableName, setTableName ] = useState("");
    const [ fields, setFeilds ] = useState({});
    const [ options, setOptions ] = useState({});

    // add error handlers next
    const [ messages, setMessage ] = useState("");

    
    const [ agreement, clicked ] = useState({
        phi: false,
        standard: false,
    });

    const [ uploadNoFields, setUploadNoFields ] = useState({
        filename: null,
        headers: null, 
        fileType: null,
        standardLayouts: [],
        layoutSelection: null,
    })
    
    const [ XLSXData, setXLSX ] = useState({
        worksheets: [],
        selectedWorksheet: null,
        worksheetNumber: 0,
        worksheetPopup: false
    });
 
    function handleWorksheetChange (e) {
        const sheetNumber = e.target.value;

        setXLSX(content=> ({
            ...content,
            worksheetNumber: sheetNumber,
        }));

        renderSheetsData(sheetNumber, setTable, setXLSX, claimsImportData.file)
    };

    const handleSubmit = function(e) {
        e.preventDefault();
        if (claimsImportData.file.name.endsWith(".xlsx")) {
            setMessage([]);
            importXLSXClaims(tableName.split(" ").join("_"), XLSXData.worksheets[XLSXData.worksheetNumber], fields, claimsImportData.file, setMessage, setLoaded, setLoading, options, setContentData, claimsImportData.headersAvail, 'claims');
        } else {
            setMessage([]);
            importClaims(tableName.split(" ").join("_"), fields, claimsImportData.file, setMessage, setLoaded, setLoading, options, setContentData, claimsImportData.headersAvail, 'claims', setPageData, account.user.userData.username, mainPageData, context.setModels);
        };
    };

    function handleMessage(e) {
        e.preventDefault()
        const noTable = 'Please make sure a table name is provided'
        const noHeaders = "Please map all the necessary column headers below"

        const table = handleMessageHelper(!tableName, noTable)
        const names = handleMessageHelper(Object.keys(fields).length!==6, noHeaders)

        return [table, names]
    };

    useEffect(() =>{
        getSubmissionLayouts(setUploadNoFields);
    }, [])

    function updateTableName(e) {
        let typed = e.target.value;

        if ( !typed.match("^[\b]") !== null) {
            setTableName(typed);
        };
        if (/^[a-zA-Z][a-zA-Z0-9_ ]*$/.test(typed) ) {
            setTableName(typed);
            setMessage([]);
        } else {
            setMessage(['Table Names must only include alphanumeric characters, underscores, and cannot start with a number!']);
        };
        if (!typed) setMessage([]);
    };

    function handleMessageHelper(what, note) {
        if (what) {
            if (!messages.includes(note)) {
                setMessage([
                    ...messages,
                    note
                ])
            }
        } else {
            if (messages.includes(note)) {
                setMessage(messages.filter(mess => mess !== note))
            };
        };
    };

    const headerKeys = Object.keys(Object.assign({}, ...table));

    function handleClickAgreement(type){
        clicked(clicks => ({
            ...clicks,
            [type]: !agreement[type]
        }));
    };

    useEffect(() => {
        setMessage([]);
    }, [step]);

    const selectStyles = "px-2 py-2  border-none rounded-md w-full ";
    const tableHeadStyles = "border px-2 py-1 bg-primary-dark text-white";
    const tableBodyStyles = "border px-2 text-sm";

    function handleAgreeNextStep(e) {
        e.preventDefault();
        if (agreement.standard) {
        // this is the standard layout agreement
            nextStep(4);
        } else {
            nextStep(step+1);
        }
        loadSetup(claimsImportData.file, XLSXData.worksheetNumber, setTable, setXLSX, claimsImportData.headersAvail);
    };

    function handleLoadTable(e) {
        handleInputChange(e, context);
        globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false);
    };

    function handleEscape(){
        globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false);
    };
    HandleKeydownEscape(handleEscape);

    function handleNoFieldsSubmit(e) {
        e.preventDefault();
        if (uploadNoFields.layoutSelection) {
            importClaimSimple(tableName.split(" ").join("_"), JSON.parse(uploadNoFields.layoutSelection), claimsImportData.file, setMessage, setLoaded, setLoading, setContentData, uploadNoFields.headers, 'claims', setPageData, account.user.userData.username, mainPageData, context.setModels, nextStep);
        } else {
            uploadCSV2500(uploadNoFields.filename, uploadNoFields.headers, 'claims', claimsImportData.file, toggler, setLoading, nextStep);
        }
    };

    return (

    <>
        <div className='absolute top-0 z-[100] h-[100vh] w-[100vw] bg-gray-100 opacity-[1.0]'></div>
        <div className='absolute top-[0%] z-[200] w-full overflow-y-auto'>
            
            <div style={{height: toggler.pageHeight-30}} className={`relative ${step === 0?"translate-y-[0%]":"translate-y-[0%]"} max-w-4xl mx-auto justify-center`}>
                <div className=' mx-auto bg-white opacity-1 overflow-y-auto py-6 rounded-md h-full' >
                <form  onSubmit={handleSubmit}>
                    <div className='text-xl font-medium text-center'>Claims Import Wizard</div>
                    {step === 0 ? <div className=''>
                        <div className='p-4 flex justify-around items-center text-sm border-b-2'>
                            <div className='flex items-center'>
                                {!claimsImportData.file?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                                <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                                upload file
                            </div>
                            <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                            <div className='flex items-center'>
                                {Object.keys(fields).length!==6?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                                <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                                column mapping
                            </div>
                            <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                            <div className='flex items-center'>
                                {((Object.keys(fields).length===6)&&tableName)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                                <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                                Ready to load
                            </div>
                            <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                            <div className='flex items-center'>
                                {((Object.keys(fields).length===6)&&loaded)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                                <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                                File Loaded
                            </div>
                        </div>
                        <div className='px-6 pb-20'>

                            <div className='text-center font-medium text-sm mt-6'>STEP 1 OF 3</div>
                            <div className='text-center font-bold mt-3 mb-6 text-lg'>Choose a file</div>
                            <div className="text-center h-12 font-medium py-1 text-sm flex items-center justify-center ">
                            {messages.length > 0&&
                                <div className=''>
                                    {messages.map(message => (
                                        <div className={message.includes('next')?"text-primary-green":`${message.includes('moment')?"text-orange-400 w-[600px]":"text-primary-red"}`}>
                                            <FontAwesomeIcon className={`text-lg mr-4 ${message.includes('next')&&"hidden"}`} icon={faTriangleExclamation}/>
                                            {message}
                                        </div>
                                    ))}
                                </div>
                            }
                            </div>
                            <ImportPanel {...{file: claimsImportData.file, setFile: setContentData, setMessage}} />
                            <div className='pt-8 pb-1 flex items-center'>
                                <input className='mr-4 w-[16px] h-[16px]'  type='checkbox' onChange={() => handleClickAgreement('phi')} checked={agreement.phi} />
                                <span className='text-sm '>“I certify that the attached data file does not contain any <b>Protected Health Information (PHI)</b>.“</span>
                            </div>
                            {/*  ------------------------------------------- hiding until fully functional -------------------------------------------  */}
                            <div className='pt-2 flex items-center'>
                                <input className='mr-4 w-[16px] h-[16px]'  type='checkbox' onChange={() => handleClickAgreement('standard')} checked={agreement.standard} />
                                <span className='text-sm '>“I am using a Standard Claims Submission Layout.“</span>
                            </div>
                            <ImportGuide />

                        </div>
                        <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 bg-white'>
                            <button className='border mt-4 px-6 py-2 rounded-md border-light-blue text-light-blue font-medium hover:bg-light-blue hover:text-white' onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                            {agreement.phi&&(
                                // agreement.standard?<button className=' disabled:cursor-not-allowed border float-right mt-4 px-6 py-2 rounded-md bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={() => console.log('hello')} disabled={!claimsImportData.file ? true : false}>next step</button>:
                                <button className=' disabled:cursor-not-allowed border float-right mt-4 px-6 py-2 rounded-md bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={handleAgreeNextStep} disabled={!claimsImportData.file ? true : false}>next step</button>)
                            }
                        </div>
                    </div>:
                    step === 1 ? <div className='pb-20'>
                    <div className='p-4 flex justify-around items-center text-sm border-b-2'>
                        <div className='flex items-center'>
                            {!claimsImportData.file?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                            <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                            upload file
                        </div>
                        <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                        <div className='flex items-center'>
                            {Object.keys(fields).length!==6?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                            <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                            column mapping
                        </div>
                        <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                        <div className='flex items-center'>
                            {((Object.entries(fields).length===6)&&tableName)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                            <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                            Ready to load
                        </div>
                        <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                        <div className='flex items-center'>
                            {((Object.keys(fields).length===6)&&loaded)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                            <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                            File Loaded
                        </div>
                    </div>
                    <div className='px-6'>
                        <div className="text-center font-medium text-sm mt-6">STEP 2 OF 3</div>
                        <div className="text-center font-bold my-3 text-lg">Map Header Columns</div>
                        <div className="text-center font-light mt-3 mb-2 text-sm">Confirm the mapping of core item attributes with the columns in your import file and add a title to your <span className='underline underline-offset-2'>Table Name</span> </div>
                        <div className="text-center h-12 font-medium py-1 text-sm flex items-center justify-center ">
                            {messages.length > 0&&
                                <div className=''>
                                    {messages.map(message => (
                                        <div className={message.includes('next')?"text-primary-green":`${message.includes('moment')?"text-orange-400 w-[600px]":"text-primary-red"}`}>
                                            <FontAwesomeIcon className={`text-lg mr-4 ${message.includes('next')&&"hidden"}`} icon={faTriangleExclamation}/>
                                            {message}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className='py-2 flex '>
                            <label htmlFor='tableNameInput' className='w-full'> Table Name
                                <input name='tableNameInput' className="border-2 w-full rounded-md px-3 py-2" pattern="[a-zA-Z0-9 ]+" placeholder='Add a table name..' value={tableName} onInput={updateTableName} aria-label='Name your table here.'/>
                            </label>
                            {claimsImportData.file.name.endsWith(".xlsx")&&<label className=''><span className='pl-2'>Worksheet</span>
                                <select className='border h-[42.17px] rounded ml-2 px-2' onChange={handleWorksheetChange} value={XLSXData.worksheetNumber}>
                                    {XLSXData.worksheets.map((worksheet, index)=> (
                                        <option value={index} key={`${index}: selection of ${worksheet}`}>
                                            {worksheet}
                                        </option>
                                    ))}
                                </select>
                            </label>}
                            <label htmlFor='tableHeaders' className='' ><span className='whitespace-nowrap pl-1'>Headers available?</span>
                                <div onClick={() => setContentData(prev=>({ ...prev, headersAvail:!claimsImportData.headersAvail}))} className='text-lg font-extrabold cursor-pointer border rounded h-[42.17px] flex items-center justify-center'>
                                    <input name='tableHeaders' className="h-0 w-0 opacity-0 " type='checkbox' />
                                    <span className={claimsImportData.headersAvail?"text-light-blue":"text-primary-dark"}>Yes</span> / <span className={claimsImportData.headersAvail?"text-primary-dark":"text-light-blue"}>No</span>
                                </div>
                            </label>
                        </div>
                        <table className='w-full mt-2'>
                                <thead>
                                    <tr className='text-left'>
                                        <th className={tableHeadStyles}>Core Item Attribute</th>
                                        <th className={tableHeadStyles}>Columns in Uploaded Document</th>
                                        <th className={tableHeadStyles}>Sample Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ImportTableRow keyNumber={1} rowTitle={'Carrier'} name={'carrier'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["carrier"]} included={["id"]} optional={false} options={table[0]?.[fields['carrier']]} headerKeys={headerKeys} unavailable={true}/>
                                    <ImportTableRow keyNumber={2} rowTitle={'NDC'} name={'ndc'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["ndc"]} included={["id","ndc"]} optional={false} options={table[0]?.[fields['ndc']]} headerKeys={headerKeys}/>
                                    <ImportTableRow keyNumber={3} rowTitle={'QTY'} name={'qty'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["qty"]} included={["quantity","qty","supply"]} optional={false} options={table[0]?.[fields['qty']]} headerKeys={headerKeys}/>
                                    <ImportTableRow keyNumber={4} rowTitle={'Days Supply'} name={'days_supply'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["days_supply"]} included={["days"]} optional={false} options={table[0]?.[fields['days_supply']]} headerKeys={headerKeys}/>
                                    <ImportTableRow keyNumber={5} rowTitle={'PharmacyID'} name={'pharmacy_id'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["pharmacy_id"]} included={["id","nabp","npi"]} optional={false} options={table[0]?.[fields['pharmacy_id']]} headerKeys={headerKeys}/>
                                    <ImportTableRow keyNumber={6} rowTitle={'Net Claims'} name={'net_claims'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["net_claims"]} included={["contract","service","cnt","wac"]} optional={false} options={table[0]?.[fields['net_claims']]} headerKeys={headerKeys} unavailable={true}/>
                                    <ImportTableRow keyNumber={7} rowTitle={'Channel'} name={'channel'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["channel"]} included={["channel","mail","retail","indicator","cd","code"]} optional={true} options={table[0]?.[options['channel']]} headerKeys={headerKeys}/>
                                    <ImportTableRow keyNumber={8} rowTitle={'Compound Indicator'} name={'compound_indicator'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["compound_indicator"]} included={["claim"]} optional={true} options={table[0]?.[options['compound_indicator']]} headerKeys={headerKeys} unavailable={true}/>
                                    <ImportTableRow keyNumber={9} rowTitle={'DAW Code'} name={'daw_code'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["daw_code"]} included={["claim"]} optional={true} options={table[0]?.[options['daw_code']]} headerKeys={headerKeys} unavailable={true}/>
                                    <ImportTableRow keyNumber={10} rowTitle={'Date of Fill'} name={'date_of_fill'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["date_of_fill"]} included={["date"]} optional={true} options={table[0]?.[options['date_of_fill']]} headerKeys={headerKeys}/>
                                    <ImportTableRow keyNumber={11} rowTitle={'Claim Status'} name={'claim_status'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["claim_status"]} included={["claim"]} optional={true} options={table[0]?.[options['claim_status']]} headerKeys={headerKeys}/>
                                </tbody>
                        </table>
                    </div>
                    <div className='px-6 mt-6'>
                        {
                            !tableName||Object.entries(fields).length!==6 ? <FormButton buttonText={'load'} onClick={handleMessage} typeB={true} textSize={"text-sm"} addClass={'cursor-not-allowed'}/>:
                            <FormButton buttonText={'load'} loading={loading} loadingSize={15} textSize={"text-sm"}/>
                        }
                    </div>
                    <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 bg-white pt-4'>
                        <button className='border  px-6 py-2 rounded-md border-light-blue text-light-blue font-medium hover:bg-light-blue hover:text-white' onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                        <div className='float-right'>
                            <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(step-1)}>prev step</button>
                            {claimsImportData.validated&&tableName&&Object.keys(fields).length===6&&loaded&&<button className='border rounded-md px-6 py-2 ml-3 bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={() => nextStep(step+1)}>next step</button>}
                        </div>
                    </div>
                    </div>:
                    step === 2 ? <>
                    <div className='px-6'>
                        <div className="text-center mt-20 font-medium text-sm">STEP 3 OF 3</div>
                        <div className="text-center font-bold my-6 text-xl">Check column values</div>
                        <div className="text-center font-light my-6 text-sm">Check the consistency of your table data. Once finished, click load to initiate the process. </div>
                        <div className='text-sm font-medium pb-2'>Tablename: <span className=''>{tableName}</span></div>
                        <div style={{height: toggler.pageHeight*0.5}} className='overflow-auto ' >
                            <table className='text-xs'>
                                <thead>
                                    <tr className='border' key={"header"}>
                                            {headerKeys.map((key, idx) => (
                                                <th key={`${idx} - ${key}`} className='px-2 border py-3'>{key}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className='text-center'>
                                    {table.map((el,idx) => (
                                        <tr key={`${idx} table ${el.id}`}>
                                            {Object.values(el).map((val) => (
                                                <td className='border'>{val}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 pt-4 bg-white'>
                        <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(step-1)}>prev step</button>
                        <button name='tName' value={tableName} className='border rounded-md px-6 py-2 bg-light-blue text-white font-medium hover:bg-hover-blue float-right' onClick={handleLoadTable}>load table</button>
                    </div>
                    </>:null}
                    </form>
                    {step === 4 && <>
                        <div style={{height: toggler.pageHeight-110}} className='flex flex-col justify-center items-center mx-auto px-20' > 
                            <form onSubmit={handleNoFieldsSubmit} className='w-full mb-4'>
                                <label className='flex flex-col capitalize tracking-widest'>table name
                                    <input onChange={updateTableName} className='border py-3 px-2 mb-2 rounded' type='text' required />
                                </label>
                                <label className='flex flex-col capitalize tracking-widest mb-4 mt-2'>layout
                                    <select name='layoutSelection' onChange={e => globalChangeHandler(e, null, setUploadNoFields)} className='border py-4 px-5 mt-1 rounded'>
                                        <option value={null}>--- optional ---</option>
                                        {uploadNoFields.standardLayouts.map(layout => (
                                            <option value={layout.mapping}>
                                                {layout.layoutname}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <label className='flex flex-col capitalize tracking-widest mb-4 mt-2'>headers available
                                    <select name='headers' onChange={e => globalChangeHandler(e, null, setUploadNoFields)} className='border py-4 px-5 mt-1 rounded'>
                                        <option value={'-'}>---select one---</option>
                                        <option value={true}>true</option>
                                        <option value={false}>false</option>
                                    </select>
                                </label>
                                <FormButton loading={loading} buttonText={'upload'} />
                            </form>
                            <button className='border rounded-md px-6 py-2 border-light-blue font-medium text-xs w-full text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(0)}>prev step</button>
                            {/* <button className='border rounded-md px-6 py-2 bg-light-blue font-medium text-xs w-full text-white hover:bg-hover-blue hover:text-white' onClick={() => nextStep(0)}>next step</button> */}
                        </div>
                    </>}
                </div>
            </div>
        </div>
    </>
    )
};



