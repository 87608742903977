import { globalBoolHandler } from "./globalHandlers";
import { extractFromLocal, loadSaved } from "./loadSave";

export async function login({username, password, scope}, account, auth2FA, forced, setLoading, setMessage) {

    const formData = new FormData();
    formData.append("scope", scope)
    formData.append("username", username)
    formData.append("password", password)

    try {
        const data = await fetch(`/token${forced?"?forceLogin=true":""}`, {
            method: "POST",
            body: formData
        });

        var accessToken = undefined;
        const tokenResults = await data.json();
        
        if (data.ok) {
            accessToken = tokenResults.access_token;
        } else {
            setMessage(tokenResults.detail);
            setLoading(false);
        }

        if (tokenResults.token_type) {
            return logInUser(accessToken, account, null)
        } else {
            return auth2FA(tokenResults);
        }


    } catch (err) {
        console.log(err)
    };
};


export async function checkUser(setUser, context, toggler){

    try {
        var results = undefined;
    
        const data = await fetch('/users/me/', {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });

        if (data.ok) {
            results = await data.json()
            setUser({
                loggedIn: true,
                token: extractFromLocal()?.accessToken,
                userData: results
            });
            if (extractFromLocal()?.claimnumber) {
                loadSaved(extractFromLocal().claimnumber, context, toggler)
            }
            return results
        } else {
            return await data.json()
        }
    } catch(err) {
        console.log(err)
    };

};

export async function refreshUser(setUser, context, toggler){

    try {
        var results = undefined;
    
        const data = await fetch('/users/me/', {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });

        if (data.ok) {
            results = await data.json()
            setUser({
                loggedIn: true,
                token: extractFromLocal()?.accessToken,
                userData: results
            });
            if (extractFromLocal()?.casenumber) {
                loadSaved(extractFromLocal().casenumber, context, toggler)
                sessionStorage.setItem('259rx_data', JSON.stringify({
                    accessToken: extractFromLocal()?.accessToken,
                    casenumber: extractFromLocal()?.casenumber?extractFromLocal().casenumber:"",
                    loggedInAt: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
                }));
            }
            return results
        } else {
            return await data.json()
        }
    } catch(err) {
        console.log(err)
    };

};

export async function signup(payload, setMessage){

    const userpayload = 
        {
            "username": payload.username,
            "email": payload.email,
            "full_name": `${payload.first_name} ${payload.last_name}`,
            "user_type": payload.user_type,
            "password": payload.password,
            "orgid": payload.orgid
        };

    try {
        const data = await fetch("/api/add_user", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify(userpayload)
        });
        let response = await data.json();

        if (data.ok) {
            return response;
        }else {
            setMessage(response.detail);
        };

    } catch (err) {
        console.log(err)
    }
};

export async function getUserLogs(){

    try {
        const data = await fetch("/api/login-logs", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();

        if (data.ok) {
            return response;
        }

    } catch (err) {
        console.log(err)
    }
};

export async function getOrgTags(set){
    
    try {
        const data = await fetch("/api/get_org_tags", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();

        if (data.ok) {
            if (response.length>0) {
                set(response.map(ele => ({value: ele, label: ele})))
            }else {
                set(response);
            }

        }

    } catch (err) {
        console.log(err)
    };
};

export async function logout(handleGlobalLogout, account, context, toggler){
    
    try {
        const data = await fetch("/logout", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();

        if (data.ok) {
            handleGlobalLogout(account, context, toggler)
            return console.log(response)
        }

    } catch (err) {
        console.log(err)
    };
};

export async function changePassword(currentPassword, newPassword, setError, toggle){
    
    try {
        const data = await fetch(`/api/change_password?current_password=${currentPassword}&new_password=${newPassword}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();

        if (data.ok) {
            alert('Password has been updated!!')
            toggle(toggs => ({
                ...toggs,
                password: false,
            }));
        } else {
            setError(data => ({
                ...data,
                error: response.detail
            }))
        }

    } catch (err) {
        setError(data => ({
            ...data,
            error: err.detail
        }))
        setTimeout(() => {
            setError(data => ({
                ...data,
                error: ""
            }))
        }, 5000)
    };
};

export async function checkVerificationSMS(payload, setLoad, account, setResp, setTogg, toggle) {
    setLoad(true)
    try {   
        const data = await fetch(`/check-verification-sms`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify(payload)
        })
        const response = await data.json();

        setResp(response);

        var accessToken
        if (data.ok) {
            accessToken = response.access_token;
        }
        
        if (response.token_type) {
            if (setTogg) {
                globalBoolHandler('challenge', toggle, setTogg, false, "password", true, "authenticated", true)
                setLoad(false);
            } else {
                logInUser(accessToken, account, setLoad)
                setLoad(false);
            }
        } 
        
    } catch (err) {
        setLoad(false);
        console.log(err)
    };
};

async function logInUser(accessToken, account, setLoad) {
    try {
        const data = await fetch('/users/me/', {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
        });
        if (data.ok) {
            const loginResults = await data.json()
            
            sessionStorage.setItem('259rx_data', JSON.stringify({
                accessToken,
                casenumber: extractFromLocal()?.casenumber?extractFromLocal().casenumber:"",
                loggedInAt: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
            }));
            
            account.setUser({
                loggedIn: true,
                token: accessToken,
                userData: loginResults
            });
            setLoad(false);
            return loginResults
        };
    } catch (err) {
        console.log(err)
    }
}

export async function resendOTP(challengeId, setR) {
    try {
        const data = await fetch(`/resend-otp`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify({
                challenge_id: challengeId
            })
        });
        let response = await data.json();

        if (data.ok) {
           setR({
            detail: response.detail
           });
           setTimeout(() => {
               setR({});
           }, [3000]);
        };
    
    } catch (err) {
        console.log(err)
    };
};

export function handle2Factor(e, twoFactorCode, username, challengeId, setLoader, account, setR) {
    e.preventDefault();
    setLoader(true)
    const payload = {
        phone_number: "",
        code: twoFactorCode,
        username: username,
        scopes: ["me"],
        challenge_id: challengeId,
    };

    checkVerificationSMS(payload, setLoader, account, setR);
};