import React from 'react';
import FormButton from '../Buttons/FormButton';
import { UseContextClues } from '../../context/ContextClues';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { applyEvents, convertList, deleteScenario } from '../../utils/events';

export default function EventScenarioMain({ toggle, accessToggler, updateList, control, controller, deleteFunction }) {

    const context = UseContextClues();

    function switchToEdit(current) {
        control(keepData => ({
            ...keepData,
            currentPage: "PUT",
            editEvent:  {
                id: current.scenario_id,
                name: current.name,
                description: current.description,
                event_ids: [...current.event_ids],
            },
        }))
    };

    return (
        <>
            <div className=' overflow-y-auto h-full'>
                <table className='border w-full'>
                    <thead>
                        <tr className='text-left sticky top-0 bg-white shadow uppercase tracking-widest'>
                            <th className='pl-4'>
                                #
                            </th>
                            <th className='pl-4'>
                                name
                            </th>
                            <th className='px-4'>
                                description
                            </th>
                            <th className='px-4'>
                                events
                            </th>
                            <th>
                                apply
                            </th>
                            <th>
                                edit
                            </th>
                            <th>
                                delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {controller.scenarios.map((scenario, index) => (
                            <tr key={`${index} - scenario: ${scenario.name}`} className='border-y h-[100px] '>
                                <td className='pl-4 '>
                                    {index+1}
                                </td>
                                <td className='pl-4'>
                                    {scenario.name}
                                </td>
                                <td className='p-4'>
                                    {scenario.description}
                                </td>
                                <td className='p-4 whitespace-pre-wrap max-w-[400px] '>
                                    {scenario.event_ids.map((eventId, idx) => (
                                        <>
                                            {idx===scenario.event_ids.length-1?"and ":""}<span>{controller?.eventsObject?.[eventId]?.ename?controller?.eventsObject?.[eventId]?.ename:"NA"}</span><span className={`${idx===scenario.event_ids.length-1?"hidden":""}`}>,&nbsp;</span>
                                        </>
                                    ))}
                                </td>
                                {!deleteFunction&&<td className='pr-4'>
                                    <FormButton typeB={true} onClick={() => applyEvents(scenario, context, () => globalBoolHandler('scenarios', accessToggler, toggle, false ))} buttonText={'apply'} marginTop={"mt-0"} />
                                </td>}
                                <td className='pr-4'>
                                    <FormButton typeB={true} onClick={() => switchToEdit(scenario)} buttonText={'edit'} marginTop={"mt-0"} />
                                </td>
                                <td className='pr-4'>
                                    <FormButton typeB={true} onClick={() => deleteScenario(scenario.scenario_id, scenario.name, controller.scenarios, control, context)} buttonText={'delete'} marginTop={"mt-0"} />             
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};