import { tailChase } from 'ldrs';

export default function FormButton ({buttonId, buttonText, onClick, addClass, disabled, typeB, loading, loadingSize, textSize, marginTop, opposite}) {
tailChase.register();
    return <button 
        id={buttonId} 
        onClick={onClick} 
        type={typeB?"button":"submit"}
        disabled={disabled} 
        className={`disabled:cursor-not-allowed ${opposite?"bg-white border border-light-blue hover:bg-light-blue text-light-blue hover:text-white":"bg-light-blue hover:bg-hover-blue text-white "} py-3 px-4 ${marginTop?marginTop:"mt-4"} rounded capitalize w-full ${textSize?textSize:"text-xs"} font-bold shadow-lg ${addClass}`}>
            {loading ? 
            <l-tail-chase
                size={loadingSize ? loadingSize : "10"}
                speed="1.75" 
                color="white" 
            ></l-tail-chase>
            :buttonText}
    </button>
}