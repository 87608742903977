import React, { useEffect } from "react";
import FormButton from "../Buttons/FormButton";
import CollapsibleButton from "../Buttons/CollapsibleButton";
import { loadInitialClaim } from "../../utils/claimsLoad";
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler, handleToggle } from "../../utils/globalHandlers";
import SearchField from "../SearchField/SearchField";
import QuickYear from "./QuickYear";

export default function ClaimsControls () {

    const id = 'claimsControl';

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const formLabel = "flex flex-col font-medium";
    const formInput = "py-1 px-2 border my-2 rounded-lg font-light w-full";
    const importButtonStyles = "border px-3 py-1 rounded-lg mt-2 bg-light-blue hover:bg-hover-blue text-white";

    const handleSubmit = (e) => {
        e.preventDefault();
        loadInitialClaim(
            context.settings.claims.tName.split(' ').join("_"), 
            context.settings.claims.startDate, 
            context.settings.claims.endDate, 
            context.settings.claims.enablePhcyAnalytics, 
            context.setMainData, 
            toggler.setLoader, 
            toggler.setStatus,
            context.settings.modelSettings,
            context
        )
    };

    useEffect(() => {
        if (toggler.isLoading) {
            context.setExclusionSettings(data => ({
                ...data,
                startDate :context.settings.claims.startDate
            }));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.settings.claims.startDate]);

    function handleSearchChange(e) {
        if (e==="") {
            toggler.setDateRange({
                location: {
                    pageX: 0,
                    pageY: 0
                },
                data: [],
                dates: {
                  min: null,
                  max: null,
                }
            })
        };
        context.setSettings(previous => ({
            ...previous,
            locked: true,
            savedCase: false,
            lockClaims: true,
            claims: {
                ...previous.claims,
                tName: e
            }
        }));
    };

    function handleLockInput(e) {
        const {name, value, checked} = e.target;
        context.setSettings(previous => ({
            ...previous,
            locked: true,
            savedCase: false,
            lockClaims: true,
            claims: {
                ...previous.claims,
                [name]: checked?checked:value
            }
        }));
    }

    return (
        <div id={id}>
            <CollapsibleButton useName={id} onClick={e => handleToggle(e, toggler)} buttonTitle={"Claims"} useValue={toggler?.collapsible[id]} chevron={toggler?.collapsible[id]}/>
            <form className={`flex flex-col px-4 transition-all duration-700 text-xs border-b border-primary-dark ${toggler?.collapsible[id] ? "opacity-1 translate-y-[0] pb-3 pt-2 px-4 max-h-[487.2px]" : "opacity-0 overflow-hidden max-h-0 "}`} onSubmit={handleSubmit}>
                <label className={formLabel}> Model settings:
                    <button 
                        type="button" 
                        className={importButtonStyles+" my-4"} 
                        onClick={() => globalBoolHandler("channelDesignationPanel", toggler.toggle, toggler.setToggle, !toggler.toggle.channelDesignationPanel)}>
                            Select options
                    </button>
                </label>
                <label className={formLabel}> Table Name:
                    <div className="flex flex-col border p-2 my-3">
                        <label> Choose existing file or dataset
                            <SearchField 
                                specialObj={context.models.modelList?.claims_tables_details?Object.entries(context.models.modelList?.claims_tables_details):[]} 
                                searchId={"modelList"} 
                                current={context.settings.claims.tName} 
                                name="tName" 
                                setName={handleSearchChange} 
                                addedStyle={"py-1 px-2 border rounded-lg font-light w-full"}  
                                dateRange={true} 
                                setDateRange={toggler.setDateRange} 
                                required
                            />
                        </label>
                        <div className="relative my-1 w-[250px] mx-auto">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-b border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center">
                                <span className="bg-white px-4 text-sm text-gray-500">or</span>
                            </div>
                        </div>
                        <label className="flex flex-cols"> Import new file</label>
                        <button 
                            onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, !toggler.toggle.importWindow)} 
                            type="button" 
                            className={importButtonStyles}>
                                Import
                        </button>
                    </div>
                </label>
                <label className={formLabel}>
                    <div>Base Period Start Date: 
                        <QuickYear setting="claims" set={context.setSettings} />
                    </div> 
                    <input 
                        name="startDate" 
                        value={context.settings.claims.startDate} 
                        className={formInput} 
                        min={toggler.dateRange.dates.min?toggler.dateRange.dates.min:0} 
                        max={toggler.dateRange.dates.max?toggler.dateRange.dates.max:0} 
                        type="date" 
                        onChange={handleLockInput} 
                        required
                    />
                </label>
                <label className={formLabel}> Base Period End Date:
                    <input 
                        name="endDate" 
                        value={context.settings.claims.endDate} 
                        className={formInput} 
                        min={toggler.dateRange.dates.min?toggler.dateRange.dates.min:0} 
                        max={toggler.dateRange.dates.max?toggler.dateRange.dates.max:0} 
                        type="date" 
                        onChange={handleLockInput} 
                        required
                    />
                </label>
                <label className={"flex justify-between font-medium"}> Enable Phcy Analytics?
                    <input 
                        name="enablePhcyAnalytics" 
                        value={context.settings.claims.enablePhcyAnalytics} 
                        className={"mr-1 "} 
                        type="checkbox" 
                        onChange={handleLockInput} 
                    />
                </label>
                <FormButton buttonText="load initial data"/>
            </form>
        </div>
    );
};