import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import ContractualDefinition from '../ContractualDefinition/ContractualDefinition';
import { faChevronLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { getVendorDealDetails,deleteVendorRate } from '../../utils/vendorConsole';
import { UseToggleContext } from '../../context/ToggleContexts';
import moment from 'moment';

export default function MainPage({page, vendorName, next, data, set}) {

    const toggler = UseToggleContext();

    const [ vendorDetails, setDetails ] = useState(null);
    const [ choice, choose ] = useState(null);

    useEffect(() => {
        getVendorDealDetails(setDetails, vendorName);
    },[vendorName]);

    function handleDelete(id) {
        if (window.confirm("are you sure?")) deleteVendorRate(id, vendorName, setDetails, vendorDetails, next, data, set, choose);
    };

    function handleOpenCard(e, idx) {
        e.stopPropagation();
        choose(idx);
    };

    const detailStyles = "flex justify-between font-light capitalize border py-4 px-1";
    const spanStyles = "font-medium";

    return (
        <>
            <div className='flex flex-col text-xs xl:text-sm h-full items-center'>
                <div className={`relative w-full border min-h-[100px] mb-4 mt-4 shadow px-10 flex items-center text-2xl rounded ${page.section==="edit"?"bg-primary-yellow ":'bg-white'} transition-all duration-500 justify-between`}>
                    <span>VENDOR NAME: <span className='font-bold'>&nbsp;{vendorName?vendorName:"N/A"}</span></span>
                    {page.section==="edit"&&<div className='uppercase font-bold tracking-widest pr-[300px]'>
                        edit mode
                    </div>}
                    <button className='' onClick={() => (next(data => ({...data, page:0, section: "main"})))}>
                        <FontAwesomeIcon className={'text-3xl text-primary-blue hover:text-light-blue '} icon={faChevronLeft} />
                    </button>
                </div>
                <div className='grid grid-cols-[auto,20%,auto] gap-4 w-full h-full pb-8 flex-grow'>
                    <div >
                        <ContractualDefinition {...{page, next, choice, vendorName, setDetails, vendorDetails}} />
                    </div>
                    <div style={{ height: toggler.pageHeight-260 }}  className='w-full border shadow rounded overflow-y-auto p-4 gap-4 bg-white tracking-tighter'>
                        {vendorDetails?.[vendorName]?vendorDetails[vendorName].map((detail, idx) => (
                            <button type='button' onClick={e => handleOpenCard(e, idx)}  className='w-full overflow-x-auto flex flex-col gap-2 justify-between border rounded my-1 shadow px-2 py-6 hover:shadow-xl bg-light-gray hover:bg-white cursor-pointer transition-all duration-200' key={`${idx}: vendor id ${detail.id} - formulary ${detail.formulary}`}>
                                <span>{idx+1}.</span>
                                <span className="whitespace-nowrap pl-10">Formulary: <span className="font-bold">{detail.formulary_name}</span></span>
                                <span className="whitespace-nowrap pl-10">LOB: <span className="font-bold">{detail.lob_name}</span></span>
                                <span className="whitespace-nowrap pl-10">Channel: <span className="font-bold">{detail.channel}</span></span>
                                <span className="whitespace-nowrap pl-10">Start date: <span className="font-bold">{ moment(detail.start_date).format("MMM Do, YYYY")}</span></span>
                                <span className="whitespace-nowrap pl-10">End date: <span className="font-bold">{moment(detail.end_date).format("MMM Do, YYYY")}</span></span>
                            </button>
                        )):<div className='flex items-center justify-center h-full'><span className='w-[200px] text-center'>No rates available. Add a new rate using the form on the left.</span></div>}
                    </div>
                    <div style={{ height: toggler.pageHeight-260 }} className=' overflow-y-auto bg-white border shadow w-full tracking-tighter rounded'>
                        {choice!==null?
                            <div className='p-4 rounded'>
                                <div className={detailStyles+" rounded-t"}>vendor name: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.vendor_name}</span></div>
                                <div className={detailStyles}>formulary name: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.formulary_name}</span></div>
                                <div className={detailStyles}>lob name: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.lob_name}</span></div>
                                <div className={detailStyles}>channel: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.channel}</span></div>
                                <div className={detailStyles}>start date: <span className={spanStyles}>{moment(vendorDetails?.[vendorName]?.[choice]?.start_date).format("MMM Do, YYYY")}</span></div>
                                <div className={detailStyles}>end date: <span className={spanStyles}>{moment(vendorDetails?.[vendorName]?.[choice]?.end_date).format("MMM Do, YYYY")}</span></div>
                                <div className={detailStyles}>rate: <span className={"font-bold"}>{vendorDetails?.[vendorName]?.[choice]?.rate}</span></div>
                                {vendorDetails?.[vendorName]?.[choice]?.id?<div className='border flex items-center justify-between px-1 py-2'><span>Edit</span> <button onClick={() => next(data => ({...data, section: "edit"}))} className='border border-light-blue text-light-blue hover:bg-light-blue hover:text-white py-1 px-6 rounded-full '>
                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                </button></div>:null}
                                {vendorDetails?.[vendorName]?.[choice]?.id?<div className='border flex items-center justify-between px-1 py-2'><span>Delete</span> <button className='border py-1 px-6 border-primary-red bg-primary-red hover:bg-red-800 hover:border-red-800 text-white rounded-full' type='button' onClick={() => handleDelete(vendorDetails?.[vendorName]?.[choice]?.id)}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </button></div>:null}
                                <div className={detailStyles+""}>brand definition: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.brand_definition}</span></div>
                                <div className={detailStyles}>custom lists: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.custom_lists}</span></div>
                                <div className={detailStyles}>otc definition: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.otc_definition}</span></div>
                                <div className={detailStyles+" rounded-b"}>exclusions: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.exclusions}</span></div>
                                {/* <div className={detailStyles}>id: <span className={spanStyles}>{vendorDetails?.[vendorName]?.[choice]?.id}</span></div> */}
                            </div>
                        :<div className='flex items-center justify-center h-full'>
                            <span className='w-[200px] text-center'>Preview window. Select a vendor on the left to preview its contents.</span>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
};