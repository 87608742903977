import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import FormButton from '../Buttons/FormButton';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseContextClues } from '../../context/ContextClues';
import { runStandard } from '../../utils/resultsSummary';
import Select from 'react-select';
import moment from 'moment';
import { applyEvents } from '../../utils/events';

export default function EventsModal() {

    const toggler = UseToggleContext();
    const context = UseContextClues();

    const [ eventsObject, setEvents ] = useState(null);

    const runstandard = function(){
        if (context.settings.formulary.baseFormulary && 
            context.settings.formulary.targetFormulary && 
            context.settings.year.year1 && 
            context.settings.claims.tName && 
            context.settings.claims.endDate && 
            context.settings.claims.startDate) {
            globalBoolHandler('eventsModal', toggler.toggle, toggler.setToggle, false)
            runStandard({
                year1StartDateValue: context.settings.year.year1,
                parameters: context.exclusionSettings,
                selectedFormularyId: context.settings.formulary.baseFormulary,
                selectedStartDateValue: context.settings.claims.startDate,
                selectedEndDateValue: context.settings.claims.endDate
            }, context, toggler);
        } else {
            toggler.setStatus("You are missing an input parameter. Please check your status in the console to confirm setup and try again. ")
            setTimeout(() => {
                toggler.setStatus("")
            }, 5000)
        }
    };

    useEffect(() => {
        var library = {}
        context.events.forEach(event => (
            library[event.ename] = event
        ));
        setEvents(library)
    }, [context.events]);

    return ( 
        <>
            <PopUpSection popone={'absolute top-0  h-[99vh] w-[100%] bg-gray-500 opacity-[0.6]'} poptwo={'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] pr-6 w-full overflow-y-auto '}>
                <div className='max-w-[800px] h-[510px] mx-auto bg-white shadow-xl border rounded p-4'>
                    <div className=' '>
                        <div className='text-xl tracking-widest font-bold py-6  box-border justify-between sticky top-0 bg-white'>
                            <div>Currently applied events</div>
                            <div className='text-lg flex items-center'><span className='font-bold pr-2'>Scenario: </span>
                                {/* <span className='underline font-light underline-offset-4'>{context.settings.currentScenario}</span> */}
                                <Select 
                                    options={context.controller.scenarios.map((value, idx) => ({
                                        name: `${value.name}`,
                                        label: `${idx+1}: ${value.name}`,
                                        date_created: `${value.date_created}`,
                                        description: `${value.description}`,
                                        event_ids: [...value.event_ids],
                                        org_id: `${value.org_id}`,
                                        scenario_id: `${value.scenario_id}`,
                                    }))}
                                    onChange={e => applyEvents(
                                        {
                                            name: `${e.name}`,
                                            date_created: `${e.date_created}`,
                                            description: `${e.description}`,
                                            event_ids: [...e.event_ids],
                                            org_id: `${e.org_id}`,
                                            scenario_id: `${e.scenario_id}`,
                                        }, 
                                        context, 
                                        null
                                    )}
                                    menuShouldScrollIntoView={false}
                                    name='constraint'
                                    className='w-full'
                                    menuPosition='fixed'
                                    value={{
                                        name: 'currentScenario',
                                        label: context.settings.currentScenario,
                                        value: context.settings.currentScenario,
                                    }}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 500, }) }}
                                 />
                            </div>
                        </div>
                        {context.exclusionSettings.events.length>0?
                        <div className=' h-[300px] overflow-y-auto border-4'>
                            <table className=' w-full '>
                                <thead className='sticky top-[0.5px] bg-white shadow'>
                                    <tr>
                                        <th className='text-left uppercase tracking-wide py-2 pl-2'>
                                            name
                                        </th>
                                        <th className='text-left uppercase tracking-wide py-2 '>
                                            startdate
                                        </th>
                                        <th className='text-left uppercase tracking-wide py-2 '>
                                            target
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border ' >
                                    {context.exclusionSettings.events.map((event, ind) => (
                                        <tr key={`${ind}`} className='border-y h-[70px] '>
                                            <td className='pl-2'>
                                                {event}
                                            </td>
                                            <td className=''>
                                                {moment(eventsObject?.[event]?.startdate).format('MMM DD, YYYY')}
                                            </td>
                                            <td className='max-w-[300px]'>
                                                {eventsObject?.[event]?.target}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        
                        :<div className='h-[290px] border flex items-center justify-center text-lg p-20'>
                            No events have been selected. Select a new scenario or you can choose to 'Run models' without including any events. 
                        </div>}
                    </div>
                    <div className='flex flex-row gap-4'>
                        <FormButton typeB={true} buttonText={'cancel'} onClick={() => globalBoolHandler('eventsModal', toggler.toggle, toggler.setToggle, false)} />
                        <FormButton typeB={true} buttonText={'run models'} onClick={runstandard} />
                    </div>
                </div>
            </PopUpSection>
        </>
    );
};