import React from 'react';
import{ default as UserGuider} from '../components/UserGuide/UserGuide'
import { UseToggleContext } from '../context/ToggleContexts';

export default function UserGuide() {
    const toggler = UseToggleContext();
    return (
        <>
            <div className='mx-auto max-w-[1400px] bg-white '>
                <div style={{height: ((toggler.pageHeight-100)/2)}} className='border-4 p-3 bg-primary-dark'>
                    <div className=' '>
                        {/* <iframe src='https://drive.google.com/file/d/1WcmRg6W7rQKatm35kx2Is85T-GDF8iB0/view?usp=drive_link' className='w-[800px] h-[430px] mx-auto' /> */}
                        <iframe className='w-full h-[450px]' src="https://player.vimeo.com/video/1068927271?h=7fb6b9b1b4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" title="Recording 2023-11-14 161244"></iframe>
                    </div>

                </div>
                <div style={{height: ((toggler.pageHeight-155)/2)}} className={`h-[500px] overflow-y-auto border`}>
                    <UserGuider />
                </div>
            </div>
        </>
    );
};

