import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import XButton from '../Buttons/XButton';
import { globalBoolHandler, handleCustomListChange } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import FormButton from '../Buttons/FormButton';
import { UseContextClues } from '../../context/ContextClues';
import { formatDecimal, formatDecimalToPercentage, runMultiFormulary } from '../../utils/resultsSummary';
import { downloadOptimized } from '../../utils/download';
import Select from 'react-select'
import { FixedSizeList } from "react-window";
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { formatCurrency } from '../../utils/analytics';
import { handleInputChange } from '../../utils/claimsLoad';

export default function MuiltiFormulary() {
    
    const toggler = UseToggleContext();
    const context = UseContextClues();

    
    const [ chosenSettings, chooseSettings ] = useState({
        formularySelection: {
            selections: []
        },
        multiFormularyList: null,
        loadingInitial: false,
        loading: false
    });
    
    function handleMultiSubmit(e, selectedStartDateValue = context.settings.claims.startDate, selectedEndDateValue = context.settings.claims.endDate, year1StartDateValue = context.settings.year.year1) {
        var selectedStartDateObj = new Date(selectedStartDateValue);
        var selectedEndDateObj = new Date(selectedEndDateValue);
        var base_midpointTimestamp = selectedStartDateObj.getTime() + (selectedEndDateObj - selectedStartDateObj) / 2;
        var base_midpoint = dateToDaysSinceEpoch(new Date(base_midpointTimestamp));
    
        var year1StartDateObj = new Date(year1StartDateValue);
        year1StartDateObj.setDate(year1StartDateObj.getDate() + 180);  // add 180 days
        var yr1midpoint = dateToDaysSinceEpoch(year1StartDateObj);
    
        var annualizationFactor = 365 / (selectedEndDateObj - selectedStartDateObj) * (1000 * 60 * 60 * 24);
        annualizationFactor = parseFloat(annualizationFactor.toFixed(3));
    
        function dateToDaysSinceEpoch(dateObj) {
            // Convert milliseconds since Epoch to days since Epoch
            return Math.floor(dateObj.getTime() / (1000 * 60 * 60 * 24));
        }
        e.preventDefault();
        var payload = {
            formularyId: chosenSettings.formularySelection.selections,
            parameters: context.exclusionSettings 
        }

        runMultiFormulary(payload, base_midpoint, yr1midpoint, annualizationFactor, context.casenumber, chooseSettings)
    };

    function xOutPanel() {
        globalBoolHandler("multiFormulary", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(xOutPanel);
    

    const lineStyles = 'w-full h-full flex items-center px-3'
    const labelStyles = 'flex flex-col mt-2 tracking-widest capitalize'
    const buttonStyles = 'px-20 py-3 border bg-light-blue hover:bg-hover-blue text-white font-bold rounded-lg'

    const multiFormulary = ({data, index, style,}) => {
        return ( 
            <div key={`${index}: specialty sweeper data ${data[index]}`} style={style} className={`${index%2===0?"bg-gray-400":" "} flex bg-background-light border w-full items-center`}>
                    <div key={`${index}: multi formulary id ${data[index].formularyId} drugname ${data[index].drugname}`} className={'flex items-center w-full h-full'}>
                        {Object.entries(data[index]).map((drug, ind) => (
                            <div key={`${index}:${ind}-${drug[1]} special`} className={`${lineStyles} border whitespace-pre-wrap w-full overflow-x-auto text-sm`}>
                                {ind===2||ind===6?formatDecimalToPercentage(drug[1]):ind===3||ind===5||ind===8?formatCurrency(drug[1]):ind===4?formatDecimal(drug[1]):drug[1]}
                            </div>
                        ))}
                    </div>
            </div>
        );
    };

    return (
        <>
            {!chosenSettings.multiFormularyList?<PopUpSection>
                <div className='relative bg-white w-[400px] h-full mx-auto'>
                    <XButton additionalClasses={'mt-3'} clickHandler={() =>xOutPanel()} />
                    <div className='text-center pt-3'> 
                        Multi formulary model
                    </div>
                    <form onSubmit={handleMultiSubmit} className='p-8'>
                        <label className={labelStyles}>base period start date
                            <input
                                name="startDate" 
                                value={context.settings.claims.startDate} 
                                type="date" 
                                className='border p-3 rounded my-1'
                                onChange={e => handleInputChange(e, context)} 
                                required
                            />
                        </label>
                        <label className={labelStyles}>base period end date
                            <input
                                name="endDate" 
                                value={context.settings.claims.endDate} 
                                type="date" 
                                className='border p-3 rounded my-1'
                                onChange={e => handleInputChange(e, context)} 
                                required
                            />
                        </label>
                        <label className={labelStyles}>Year 1 start date
                            <input
                                name='year1'
                                type="date" 
                                className='border p-3 rounded my-1'
                                onChange={e => handleInputChange(e, context)} 
                                value={context.settings.year.year1} 
                                required
                            />
                        </label>
                        <label><span className='tracking-widest'>Formulary selections </span>
                            <Select 
                                isMulti
                                options={context.formularies.map((formulary, idx) => ({
                                    value: `${formulary.formularyid}`,
                                    label: `${idx+1}: ${formulary.formularyid}`,
                                    toString: () => formulary.formularyid
                                }))}
                                required
                                onChange={e => handleCustomListChange(e, 'selections', chooseSettings, null, "formularySelection")}
                                menuShouldScrollIntoView={false}
                                name='multiFormulary'
                                className='react-select-container mt-1'
                                menuPosition='fixed'
                                // value={defaultList.constraint}
                                menuPortalTarget={document.body} 
                                styles={{ 
                                    menuPortal: base => ({ ...base, zIndex: 700, fontSize: "small", }),
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                      }),
                                }}
                            />
                            <FormButton loading={chosenSettings.loadingInitial} buttonText={'run multi'} />
                        </label>
                    </form>
                </div>    
            </PopUpSection>:
            <PopUpSection poptwo={'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full'}>
                <div className='flex items-center justify-center '>
                    <div className='px-20'>
                        {/* <div className='tracking-widest text-xl mb-3 font-bold'>
                            {chosenSettings.formularySelection.selections.map(selects => (<>
                                {selects}
                            </>))}
                        </div> */}
                        <div style={{width:toggler.pageWidth-100}} className='flex border h-[60px] bg-background-light font-bold capitalize tracking-widest rounded-t-xl'>
                            {Object.keys(chosenSettings.multiFormularyList[0]).map((headers, idx) => (
                                <div key={`${idx} specialty sweeper headers ${headers}`} className={lineStyles}>
                                    {headers.split("_").join(" ")}
                                </div>
                            ))}
                        </div>
                        <FixedSizeList
                            height={toggler.pageHeight-400}
                            width={toggler.pageWidth-100}
                            initialScrollOffset={true}
                            itemCount={chosenSettings.multiFormularyList?.length}
                            itemSize={100} // Height of each row
                            itemData={chosenSettings.multiFormularyList}
                            >
                                {multiFormulary}   
                        </FixedSizeList>  
                        <div className='bg-primary-dark rounded-b-xl h-[80px] flex justify-between gap-[60%] p-4'>
                            <FormButton buttonText={'cancel'} marginTop={'mt-0'} onClick={() => xOutPanel()} className={buttonStyles} />
                            <FormButton onClick={() => downloadOptimized(context.casenumber, chooseSettings) } loading={chosenSettings.loading} buttonText={'download'}  marginTop={'mt-0'} className={buttonStyles} />
                        </div>
                    </div>
                </div>
            </PopUpSection>}
        </>
    );
};