import React, { useState } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { changePassword } from "../../utils/accounts";
import { globalBoolHandler, globalChangeHandler } from "../../utils/globalHandlers";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AccountSettings({toggle, setTogg, setAuth}) {

    const toggler = UseToggleContext();
    const account = UseUserContext();

    const [ payload, update ] = useState({
        currentp: "",
        confrimnewp: "",
        newp: "",
        error: ""
    });

    function handleSubmit(e){
        update(content => ({
            ...content,
            error: ""
        }));
        e.preventDefault();
        if (payload.newp===payload.confrimnewp) {
            changePassword(payload.currentp, payload.newp, update, setTogg);
        } else {
            update(content => ({
                ...content,
                error: "Passwords must match!"
            }))
        }
    };


    const labelStyles = "flex flex-col mb-4"
    const inputStyles = "border py-3 px-3 rounded mt-2"

    function fillSpec(criteria){
        if (criteria) {
            return "text-primary-green"
        }
    };
    const atLeastOneUpperLowercaseRegex = /(?=.*[a-z])(?=.*[A-Z])/;
    const atLeastOneSpecialRegex = /(?=.*\W)(?=.*[0-9])/;
    const chekcAll = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,32}$/;

    function hidePanel() {
        globalBoolHandler('challenge', toggle, setTogg, false, "authenticated", false, "password", false)
        setAuth({
            challenge_id: ""
        });
    };

    return (
        <>
            <div style={{height: toggler.pageHeight}} className="pt-10 px-20 overflow-y-auto pb-[300px] pr-20">
                    <h3 className="h-[130px] flex items-center tracking-widest text-2xl font-medium">
                        Account Settings
                    </h3>
                    <div className="text-lg flex gap-40 font-medium pt-10">
                        <div>
                            First Name: <span className="font-bold pl-2">{account.user.userData?.full_name.split(' ')[0]}</span>
                        </div>
                        <div>
                            Last Name: <span className="font-bold pl-2">{account.user.userData?.full_name.split(' ')[1]}</span>
                        </div>
                    </div>
                    <div className="text-lg font-medium pt-10 pb-6">
                        Username: <span className="font-bold pl-2">{account.user.userData.username}</span>
                    </div>
                    <div className="text-lg pt-2 pb-10">
                        Your email address is: <span className="font-bold pl-2">{account.user.userData.email}</span>
                    </div>
                    <div content=" " className="border-b"></div>
                    <div className="text-lg font-medium pt-10">
                        Password 
                        {!toggle.authenticated?<button type="button" onClick={() => globalBoolHandler('password', toggle, setTogg, !toggle.password, 'authenticated', account.user.userData.twofa_enabled?false:true, "challenge", true)} className="float-right text-light-blue hover:text-hover-blue">
                            Change
                        </button>:
                        <button type="button" onClick={hidePanel} className="float-right text-light-blue hover:text-hover-blue">
                            Hide
                        </button>}
                    </div>
                    {toggle.authenticated&&<form onSubmit={handleSubmit}>
                        <div className="text-primary-red ">
                            {payload.error}
                        </div>
                        <div className="pt-4">
                            <label className={'hidden'}>username
                                <input required value={account.user.userData.username} autoComplete='username' name="username" type="text" onChange={() => console.log()}/>
                            </label>
                            <label className={labelStyles}>Current Password
                                <input required value={payload.currentp} autoComplete='current-password' name="currentp" onChange={e => globalChangeHandler(e, null, update)} type="password" className={inputStyles}/>
                            </label>
                            <div className="border-t mt-10">
                                <label className={labelStyles+" mt-10"}>New Password
                                    <input required value={payload.newp} autoComplete='new-password' name="newp" onChange={e => globalChangeHandler(e, null, update)} type="password" className={inputStyles}/>
                                </label>
                                <label className={labelStyles}>Confirm New Password
                                    <input required value={payload.confrimnewp} autoComplete='new-password' name="confrimnewp" onChange={e => globalChangeHandler(e, null, update)} type="password" className={inputStyles}/>
                                </label>
                            </div>
                            <div className="flex flex-col">
                                Password must contain:
                                <div className="flex pl-2 pt-2">
                                    <FontAwesomeIcon className={`text-primary-blue text-xl float-right cursor-pointer ${fillSpec(payload.newp.length >= 8)}`}  icon={faCircleCheck}/>
                                    <div className="pl-2">
                                        At least 8 characters with a maximum of 32
                                    </div>
                                </div>
                                <div className="flex pl-2 pt-1">
                                    <FontAwesomeIcon className={`text-primary-blue text-xl float-right cursor-pointer ${fillSpec(atLeastOneUpperLowercaseRegex.test(payload.newp))}`} icon={faCircleCheck}/>
                                    <div className="pl-2">
                                        Mixture of uppercase and lowecase characters 
                                    </div>
                                </div>
                                <div className="flex pl-2 pt-1">
                                    <FontAwesomeIcon className={`text-primary-blue text-xl float-right cursor-pointer ${fillSpec(atLeastOneSpecialRegex.test(payload.newp) )}`} icon={faCircleCheck}/>
                                    <div className="pl-2">
                                        At least 1 special character and 1 number
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-8">
                            Did you forget your password? <button type="button" className="text-light-blue underline underline-offset-2 hover:underline-offset-8 transition-all duration-500">Reset your password</button>
                        </div>
                        <button disabled={!payload.newp||!payload.confrimnewp||!chekcAll.test(payload.newp)} className="mt-8 bg-light-blue text-white hover:bg-hover-blue py-3 px-8 rounded disabled:cursor-not-allowed ">
                            Update Password
                        </button>
                    </form>}
                    <div content=" " className="border-b pt-20"></div>
                    <div className="text-lg font-medium pt-10 flex justify-between">
                        <div>
                            2FA Enabled?:
                            <span className="font-bold pl-4 ">{account.user.userData.twofa_enabled?"Enabled":"Disabled"}</span>
                        </div>
                        <div>
                            <button type="button" disabled={true} onClick={() => globalBoolHandler('password', toggle, setTogg, !toggle.password, 'authenticated', false)} className="float-right pl-4 text-light-blue hover:text-hover-blue disabled:cursor-not-allowed">
                                {account.user.userData.twofa_enabled?"Disable":"Enable"}
                            </button>
                        </div>
                    </div>
                </div>      
        </>
    );
};