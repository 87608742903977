import React from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FixedSizeList } from "react-window";

export default function SpecialtySweeper({inputs, rowStyles, }) {

    const toggler = UseToggleContext();

    const specialtySweeper = ({data, index, style,}) => {
        return ( 
            <div key={`${index}: specialty sweeper data ${data[index].medispan_drugname}`} style={style} className={`${index%2===0?"bg-gray-400":" "} flex bg-background-light border w-full items-center`}>
                {Object.entries(data[index]).map(ele => (<>
                    <div className={rowStyles+" last:overflow-y-auto"}>
                        {ele[1]}
                    </div>
                </>))}
            </div>
        );
    };

    return (
        <>
             <div className='font-bold uppercase tracking-widest text-white'>
                Specialty Sweeper
            </div>
            <div style={{width:toggler.pageWidth-410}} className='flex h-[60px] bg-background-light font-bold capitalize tracking-widest'>
                {Object.keys(inputs.specialtyData[0]).map((headers, idx) => (
                    <div key={`${idx} specialty sweeper headers ${headers}`} className={rowStyles+" whitespace-pre-line last:mr-1"}>
                        {headers.split("_").join(" ")}
                    </div>
                ))}
            </div>
            <FixedSizeList
                height={(toggler.pageHeight-368)/2}
                width={toggler.pageWidth-410}
                initialScrollOffset={true}
                itemCount={inputs?.specialtyData.length}
                itemSize={100} // Height of each row
                itemData={inputs?.specialtyData}
                >
                    {specialtySweeper}   
            </FixedSizeList>  
        </>
    );
};