import React, { useEffect, useRef, useState } from 'react';
import { runStandard } from '../../utils/resultsSummary';
import FormButton from '../Buttons/FormButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFileClipboard, faFileExcel, faFilePdf, faHamburger, faXmark } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard, downloadToExcel, downloadToPDF } from '../../utils/download';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import Loader from '../Loader/Loader';
import ResultsTable from '../Tables/ResultsTable';
import SettingsTable from '../Tables/SettingsTable';
import Select from 'react-select'
import Year1StartDate from '../YearStartDate/Year1StartDate';
import { handleInputChange } from '../../utils/claimsLoad';
import { UseUserContext } from '../../context/UserContext';
import { globalBoolHandler } from '../../utils/globalHandlers';
import EventsModal from '../Events/EventsModal';
import HandleOutsideClick from '../../hooks/HandleOutsideClick';

export default function ResultSummarySection(){

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const pageRef = useRef(null);
    const settingsRef = useRef();
    const oldStandard = useRef(null);
    const targetForm = useRef(null);
    
    const [ selected, setList ] = useState([]);
    const [ defaultCarriers, setDefCarriers ] = useState([]);
    const [ RXPtoggler, toggleRXP ] = useState(false);

    function flagError(){
        toggler.setStatus(`One or more of your setting parameters have been updated. ${context.settings.lockClaims?"You may need to 'Load Initial Data' prior to executing 'run models'. This ensures the accuracy of your results.":"Click 'Run Models' to re-run models in order to display accurate results."}`)
        setTimeout(() => {
            toggler.setStatus("");
        }, 5000)
    };

    function handleChange(e) {

        context.setSettings(data => ({
            ...data,
            locked: true
        }))
        let list = []
        e.forEach(ele => {
            ele.label!=="All"&&list.push(`${ele.value}`)
        });
        setList(list);
    };

    useEffect(() => {
        context.setExclusionSettings(data => ({
            ...data,
            carriers: selected.length>0?selected:["All"]
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        if (toggler.isLoading) {
            context.setExclusionSettings(data => ({
                ...data,
                year1: context.settings.year.year1
            }));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.settings.year.year1]);
    
    useEffect(() => {   
        let arr = context.exclusionSettings.carriers.map(ele => ({label: ele, value: ele}))
        setDefCarriers(arr);
    }, [context.exclusionSettings.carriers, toggler.isLoading])

    HandleOutsideClick(pageRef, toggleRXP);

    const iconStyle = "text-3xl text-light-blue hover:text-hover-blue ";

    return (
        <div className='relative' >
            <div style={{ height: toggler.pageHeight-100 }} className={` h-full bg-white border py-8 pl-4 pr-2 xl:pr-12  `}>
                <div className='relative overflow-y-auto h-full pr-2 '>
                {/* <!-- results summary tab --> */}
                    <div className='text-2xl font-medium'>Results Summary</div>
                    {/* <!-- Standard Formulary --> */}
                    <Year1StartDate  handleInput={e => handleInputChange(e, context)} updateValue={context.settings.year.year1} textSize={"text-sm"} inputWidth={'w-[225px]'}/>
                    {toggler.isLoading&&<label className='flex justify-between items-center text-sm font-medium ' htmlFor="multi">Select Carriers:
                        <Select 
                            isMulti
                            options={context.models.carriers}
                            onChange={handleChange}
                            value={defaultCarriers}
                            name='carrier'
                            className='ml-8 min-w-[225px]'
                        />
                    </label>}
                    <div className='flex gap-x-10 items-center mt-4'>
                        {account.user.userData.orgid==="rxp01"&&
                        <button   type='button'  onClick={() => toggleRXP(!RXPtoggler)}>
                            <FontAwesomeIcon className={'h-[40px] ml-4 text-light-blue hover:text-hover-blue'} icon={faBars} />
                        </button>}
                        {context.settings.lockClaims?
                        <FormButton marginTop={'mt-0'}  onClick={() => flagError()} id="runStandardButton" buttonText={"Run Models"} addClass="cursor-not-allowed" />:
                        <FormButton marginTop={'mt-0'} disabled={context.settings.lockClaims} onClick={() => globalBoolHandler("eventsModal", toggler.toggle, toggler.setToggle, true)} id="runStandardButton" buttonText={"Run Models"} addClass="disabled:cursor-not-allowed" />}
                    </div>
                    {context.results ? <>
                    <div id="standardCharts" className='pt-4'>
                        <div className='text-xl font-medium my-4 flex justify-between'><span>Standard Formulary Projections ({context.settings.formulary.baseFormulary})</span>
                            <div className='w-40 flex justify-between'>
                                {context.settings.locked?<button aria-label='download to pdf not allowed' >
                                    <FontAwesomeIcon className={iconStyle + " cursor-not-allowed"}  icon={faXmark}  onClick={flagError}/>
                                </button>:<button aria-label='download to pdf' >
                                    <FontAwesomeIcon className={iconStyle} icon={faFilePdf} onClick={() => downloadToPDF(oldStandard, targetForm, settingsRef, context.settings.claims?.tName, toggler.setStatus)}/>
                            </button>}
                                {context.settings.locked?<button aria-label='download to excel not allowed' >
                                    <FontAwesomeIcon className={iconStyle + " cursor-not-allowed"}  icon={faXmark}  onClick={flagError}/>
                                </button>:<button aria-label='download to excel' >
                                    <FontAwesomeIcon className={iconStyle}  icon={faFileExcel}  onClick={() => downloadToExcel(oldStandard, targetForm, settingsRef, toggler.setStatus)}/>
                                </button>}
                                {context.settings.locked?<button aria-label='copy to clipboard not allowed' >
                                    <FontAwesomeIcon className={iconStyle + " cursor-not-allowed"}  icon={faXmark}  onClick={flagError}/>
                                </button>:<button aria-label='copy to clipboard' >
                                    <FontAwesomeIcon className={iconStyle} icon={faFileClipboard}  onClick={() => copyToClipboard(oldStandard.current.textContent + "\n\n" + targetForm.current.textContent + "\n\n" + settingsRef.current.textContent)}/>
                                </button>}
                            </div>
                        </div>
                    </div></>:<div className='pt-6'><Loader addClass="" custom={true} loading={toggler.loader === "results"} textContent="load data" /></div>}
                    <ResultsTable thisRef={oldStandard} context={context.results} next={context} dealLength={context.exclusionSettings.dealLength} annualization={context?.mainData?.annualization} />
                    {RXPtoggler?<>
                        <div ref={pageRef} className='absolute top-[160px] left-[15px] border w-[280px] flex flex-col gap-4 bg-primary-dark shadow-xl p-2 rounded-lg'>
                            <button className='text-white tracking-widest hover:bg-light-blue py-3 capitalize font-medium ' onClick={() => globalBoolHandler("multiFormulary", toggler.toggle, toggler.setToggle, true)}>
                                bulk formulary
                            </button> 
                            <button className='text-white tracking-widest hover:bg-light-blue py-3 capitalize font-medium ' onClick={() => globalBoolHandler("repriceOnlyModel", toggler.toggle, toggler.setToggle, true)}>
                                reprice only model
                            </button> 
                            <button className='text-white tracking-widest hover:bg-light-blue py-3 capitalize font-medium ' onClick={() => globalBoolHandler("monthlyBreakout", toggler.toggle, toggler.setToggle, true)}>
                                monthly breakout
                            </button> 
                        </div>
                    </>:<></>}
                    {/* <!-- Target Formulary --> */}
                    {context.target ?<>
                        <div id="targetCharts" className='pt-8'>
                            <div className='text-xl font-medium my-4 flex justify-between'><span>Target Formulary Projections ({context.settings.formulary.targetFormulary})</span>
                            </div>
                        </div></>:<div className='pt-6'><Loader addClass="" custom={true} loading={toggler.loader === "target"} textContent="load data" /></div>
                    }
                    <ResultsTable thisRef={targetForm} context={context.target} next={context}  dealLength={context.exclusionSettings.dealLength} annualization={context?.mainData?.annualization} />
                    <SettingsTable thisRef={settingsRef} context={context} />
                </div>
            </div>
            {toggler.toggle.eventsModal&&<EventsModal />}
        </div>
    );
};