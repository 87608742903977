import { extractFromLocal } from "./loadSave";

export function fetchAndFillTable(tableId, endpoint, table, headers, formatFunction, context) {
    fetch(endpoint)
        .then(response => {
            if (!response.ok) {
                console.error('Fetch failed:', response);
                return response.json().then(error => {
                    throw new Error(error.detail);
                });
            }
            return response.json();
        })
        .then(data => {
            context.setAnalytics({
                ...context.analytics,
                [tableId]: {
                    ...context.analytics[tableId],
                    [table]:data
                }
            })

            return context.analytics
        })
        .catch(error => {
            console.error('Error:', error);
        });
    
}

// helper functions

// export function combineAndTransform(table) {
//     const standard = table?.standard;
//     const target = table?.target;
//     return standard.map((item, index) => ({
//         productName: item.drugname,
//         category: item.category,
//         standard: item.value,
//         target: target[index].value,
//         manufacturer: item.mfr
//     }))
// };

export function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

export async function macroPerformanceMetrics(casenumber) {
    try {
        const data = await fetch(`/api/get_rbt_pct_wac?casenumber=${casenumber}`, {
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });

        if (data.ok) {
            return await data.json();
        }
    } catch(err) {
        return err;
    };
};

export function callAllAnalytics(context) {

    const authenticator = {
        headers: {
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${extractFromLocal()?.accessToken}`
        }
    };

    // if (context.target&&context.results&&context.casenumber) {
        Promise.all([
            fetch(`/api/top_20_drugs?scenario=standard&casenumber=${context.casenumber}`, authenticator 
            ).then(res => res.json(), "top20DrugsTable", "standard"), // 1
            fetch(`/api/top_20_drugs?scenario=target&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "top20DrugsTable", "target"), // 2
            fetch(`/api/top_20_categories?scenario=standard&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "top20CategoriesTable", "standard"),  // 3
            fetch(`/api/top_20_categories?scenario=target&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "top20CategoriesTable", "target"), // 4
            fetch(`/api/highest_products_no_rebates?scenario=standard&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "highestProductsNoRebatesTable", "standard"), // 5
            fetch(`/api/highest_products_no_rebates?scenario=target&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "highestProductsNoRebatesTable", "target"), // 6
            fetch(`/api/top_20_mfrs?scenario=standard&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "mfrs", "standard"),  // 7
            fetch(`/api/top_20_mfrs?scenario=target&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "mfrs", "target"),  // 8
            fetch(`/api/get_rdl?scenario=standard&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "rebatable", "standard"),  // 9
            fetch(`/api/get_rdl?scenario=target&casenumber=${context.casenumber}`, authenticator
            ).then(res => res.json(), "rebatable", "target"),  // 10
        ]).then(([one,two,three, four, five, six, seven, eight, nine, ten]) => {
            
            return context.setAnalytics({
                top20DrugsTable : {
                    standard: one,
                    target: two
                },
                top20CategoriesTable : {
                    standard: three,
                    target: four
                },
                highestProductsNoRebatesTable : {
                    standard: five,
                    target: six
                },
                mfrs : {
                    standard: seven,
                    target: eight
                },
                rebatable : {
                    standard: nine,
                    target: ten

                }
            });

        }).catch(err => console.log(err));
    // };
}

export function findTotal(data) {
    var total
    if (data) total = data.reduce((acc, curr) => acc+curr.value, 0);
    let initialValue = 0
    const user = data.map(ele => {
        initialValue += ele.value/total*100
        return {
            ...ele,
            percentage: ele.value/total*100,
            overall: initialValue 
        };
    });

    return user
};

export async function exclusionWaterfall(type, casenumber, set) {
    try {
        const data = await fetch(`/api/exclusion_waterfall/{case_number}?scenario=${type}&casenumber=${casenumber}` , {
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });

        const result = await data.json();

        let changeup = [];

        if (result) {
            changeup = result.map((ele, idx) => ({
                ...ele,
                RXs: (idx===0||idx===result.length-1)?ele.RXs:ele.Delta,
                lowerLimit: (idx===0||idx===result.length-1)?0:ele.RXs,
                Step: !(idx===0||idx===result.length-1)?(ele.Step.split('(Denominator Exclusions)')[0]).split('After exclusion')[1]:ele.Step
            }),);
        };

        if (changeup) {
            set(data => ({
                ...data,
                waterfall: changeup
            }));
        };

    } catch (err)  {
        console.log(err);
    };
};

export async function getMacroYear1Stats(casenumber, set) {
    try {
        const data = await fetch(`/api/get_macro_year1_stats?casenumber=${casenumber}`, {
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            } 
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                macro: {
                    ...results,
                    biosimilar_penetration: {
                        "Adalimumab (Humira)": results.biosimilar_penetration.adalimumab,
                        "Insulin Glargine (Lantus)": results.biosimilar_penetration['insulin glargine'],
                        "Infliximab (Remicade)": results.biosimilar_penetration.infliximab,
                        "Etanercept (Enbrel)": results.biosimilar_penetration.etanercept,
                        "Ustekinumab (Stelara)": results.biosimilar_penetration.ustekinumab,
                        "Filgrastim (Neupogen)": results.biosimilar_penetration.filgrastim,
                        "Pegfilgrastim (Neulasta)": results.biosimilar_penetration.pegfilgrastim,
                    }
                }
            }));
        };

    } catch (err) {
        console.log(err);
    };
};

export async function getTopVolumeCategories(scenario, casenumber, set) {
    try {
        const data = await fetch(`/api/top_volume_categories?scenario=${scenario}&casenumber=${casenumber}`, {
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            } 
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                topVolume: results
            }));
        };

    } catch (err) {
        console.log(err);
    };
};

export async function movingAvgWAC(tablename, category, set) {
    try {
        const data = await fetch(`/api/moving_avg_wac?table=${tablename}${category?'&category='+category:""}`, {
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            } 
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                movingAvgWAC: results
            }));
        };

    } catch (err) {
        console.log(err);
    };
};

export async function getAllCategories(set) {
    try {
        const data = await fetch(`/api/get_all_catnames`, {
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            } 
        });
        const results = await data.json();

        if (data.ok) {
            set(prev => ({
                ...prev,
                categories: results
            }));
        };

    } catch (err) {
        console.log(err);
    };
};