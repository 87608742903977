import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import HandleOutsideClick from '../../hooks/HandleOutsideClick';

export default function CustomSelect({data, type, fileSelector, selectFile, builder, width}) {

    const [ dropped, toggleDrop ]= useState(false);

    const ref = useRef(null);

    function selectChoice(e, value) {
        e.stopPropagation();
        
        if (builder) {
            const current = [...fileSelector.pmt_files];
            if (value) {
                current[type] = value
            }
            selectFile(data => ({
                ...data,
                pmt_files: current
            }));
        } else {
            selectFile(data => ({
                ...data,
                [type]: value
            }));
        }
        toggleDrop(false);
    };

    const focused = HandleOutsideClick(ref, toggleDrop);

    return (
        <div className='' ref={ref} >
            <div className={`border px-4 rounded-lg py-1 ${width?width:'w-[320px]'} flex justify-between`} onClick={() => toggleDrop(!dropped)}>
                <span className='pr-4'>{builder&&fileSelector.pmt_files[type]?fileSelector.pmt_files[type]:!builder&&fileSelector.claims_file?fileSelector.claims_file:" --- Choose one --- "}</span>
                <FontAwesomeIcon className={`float-right`} icon={faChevronDown}/> 
            </div>
            {dropped&&!focused&&<ul className={`fixed z-[400] border max-h-[400px] bg-white overflow-y-auto min-h-[150px] ${width?width:'w-[320px]'} rounded-b-lg shadow-xl `}>
                {data&&data.map((content, idx) => (
                    <li key={`${idx}: custom selector ${content.table_name}`} name="hello" onClick={(e) => selectChoice(e, content.table_name)} className='py-2 border hover:bg-primary-dark hover:text-white px-2'>
                        <div className='flex justify-between'>
                            <span className='font-bold pr-10'>{content.table_name}</span>
                            <span>{content.created_by}</span>
                        </div>
                        <span className='text-xs flex'>{content.created_at.substring(0,10)}</span>
                    </li>
                ))}
            </ul>}
        </div>
    )
};