import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import { handleDelete, handleDownload, listMyFiles, uploadMyFile } from '../../utils/userFiles';
import GenericTable from '../Tables/GenericTable';
import ImportPanel from '../ImportWindow/ImportPanel';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import LoadingButton from '../Buttons/LoadingButton';
import { faDownload, faTrashCan } from '@fortawesome/free-solid-svg-icons';

export default function MyFiles() {

    const toggler = UseToggleContext();

    const [ data, setData ] = useState(null);
    const [ message, setMessage ] = useState([]);
    const [ file, setFile ] = useState(null);
    const [ loading, setLoading ] = useState({
        upload: false,
        download: false,
        delete: false,
    });

    useEffect(() => {
        listMyFiles(setData)
    }, []);

    function handleUpload(e) {
        e.preventDefault();
        uploadMyFile(file.file, setData, setFile, setLoading)
    };

    function handleEscape(){
        globalBoolHandler("myFiles", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(handleEscape);


    return (
        <>
            <PopUpSection popone={'absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]'}
                poptwo={`absolute  left-[50%] translate-x-[-50%]  z-[200] ${toggler.pageHeight<800?'top-[0%] bottom-0 translate-y-[-0%]':"top-[50%] translate-y-[-50%]"} w-full overflow-y-auto`}
            >
                 <form className=' bg-primary-dark rounded-xl max-w-[1440px] h-[800px] flex mx-auto justify-center flex-col px-8 overflow-y-auto'>
                    <div className='text-white mb-2 tracking-widest uppercase text-xs gap-5' >
                        My files
                    </div>
                    <div className='grid grid-cols-[1fr,3fr] gap-5 '>
                        <div className='rounded h-[650px] overflow-y-auto flex items-center flex-col'>
                            {message&&<span className='text-white'>{message}</span>}
                            <ImportPanel {...{ setMessage, file, setFile, accept: true, height:"h-full", acceptedFileType: true, alt :true}} />
                        </div>
                        <div className='bg-white rounded overflow-y-auto h-[650px] px-1 '>

                            {data?
                                data.length>0?
                                <GenericTable {...{headerStyle: "sticky top-0", headers: 
                                    [
                                        {content: "Index", class: "bg-primary-dark text-white h-12 text-left pl-4  "}, 
                                        {content: "File Name", class: "bg-primary-dark text-white h-12 "}, 
                                        {content: "Download", class: "bg-primary-dark text-white h-12 "},
                                        {content: "Delete", class: "bg-primary-dark text-white h-12 "}
                                    ]
                                }}>
                                    {data.map((filename, idx) => (
                                        <tr className=' h-20 hover:text-white hover:bg-primary-dark'>
                                            <td className='pl-4'>
                                                {idx+1}
                                            </td>    
                                            <td className=' text-center font-medium border-r'>
                                                {filename}
                                            </td>
                                            <td className=' h-10'>
                                                <LoadingButton {...{ filename: filename, color: "hover:text-light-blue", onclick: handleDownload, icon: faDownload }} />
                                            </td>
                                            <td className=' h-10'>
                                                <LoadingButton {...{filename: filename, setData, data, hexColor:"#F24236", color: "hover:text-primary-red", onclick: handleDelete, icon: faTrashCan }} />
                                            </td>
                                        </tr>
                                    ))}
                                </GenericTable>:<div className='flex items-center justify-center h-full'>You have no files to load. Please upload a new file. </div>
                            :
                                <div className='flex justify-center items-center h-full'>
                                    <l-leapfrog color="black"></l-leapfrog>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <button className='text-white border rounded-lg  mt-4 py-2 w-40 bg-light-blue hover:bg-inherit' onClick={() => globalBoolHandler("myFiles", toggler.toggle, toggler.setToggle, false)}>
                            Cancel
                        </button>
                        <button disabled={!file} type='button' className='text-white disabled:cursor-not-allowed border rounded-lg border-white mt-4 py-2 w-40  hover:bg-light-blue float-right' onClick={handleUpload}>
                            {!loading.upload?"Upload":<l-tail-chase size={"15"} speed="1.75" color="white" ></l-tail-chase>}
                        </button>
                    </div>
                </form>
            </PopUpSection>
        </>
    )
};