import React from 'react';
import { formatDecimalToPercentage, formatPerBrandEstimates } from '../../utils/resultsSummary';

export default function RebtatableClaimsTable({useNewData}) {

    const rowStyles = "border py-1 pl-2"

    return (
        <>
            <table className='w-full text-sm' >
                <thead>
                    <tr>
                        <th className='text-left pl-2 pb-2 pt-6'>
                            Year 1 Macro Performance KPI
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody className=' align-top'>
                    {Object.entries(useNewData).map((macros, outerIndex)=> (
                        <tr key={`${outerIndex} - ${macros[0]}`} className='border'>
                            <td className={rowStyles+" capitalize"}>
                                {macros[0]==="b340b_Distribution"?macros[0].slice(1).split("_").join(" "):macros[0].split("_").join(" ")}
                            </td>
                            {Object.entries(macros[1]).map((macro, idx)=>(
                                (macro[0] ==="R30"||macro[0] === "R90"|| macro[0] ==="S"|| macro[0] ==="M")?
                                <td key={`${outerIndex}:${idx} - rebateable tables macros ${macro[0]}`} className={rowStyles+" pl-0 flex"}>
                                    <table className='w-full'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={`${idx}: ${macro[0]} - ${macro[1].rebates_per_rebated_claim} `} className='border-b last:border-none  hover:bg-primary-dark hover:text-white'>
                                                <td className={'pl-2'}>
                                                    {macro[0]}
                                                </td>
                                                <td className={'text-right pr-2'}>
                                                    {formatPerBrandEstimates(macro[1].rebates_per_rebated_claim)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                :
                                (macro[0]==='compliance_rate'||macro[0]==='rebated_brand_pct')?
                                <td key={`${outerIndex}:${idx} - rebateable tables macros ${macro[0]}`}  className='text-right pr-2 py-1  hover:bg-primary-dark hover:text-white'>
                                    {formatDecimalToPercentage(macro[1])}
                                </td>
                                :null
                            ))}
                            {(macros[0]==='biosimilar_penetration')?<td>
                                <table className='w-full '>
                                    <thead>
                                        <tr>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(macros[1]).map((rebateableStats, idx) => (
                                            <tr key={`${outerIndex}:${idx}: ${rebateableStats[0]} - ${rebateableStats[1].rebates_per_rebated_claim} `} className='border-b last:border-none  hover:bg-primary-dark hover:text-white'>
                                                <td className={'pl-2 py-1 '}>
                                                    {rebateableStats[0]}
                                                </td>
                                                <td className={'text-right pr-2'}>
                                                    {formatDecimalToPercentage(rebateableStats[1])}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                            :null}
                            {(macros[0]==='b340b_Distribution')?<td>
                                {Object.entries(macros[1]).map((rebateableStats, index) => (
                                    <div key={`${index}: 340b distribution ${rebateableStats}`} className={'pl-2 flex justify-between capitalize'}> 
                                        <div className='py-1'>
                                            {rebateableStats[0].split('_').join(' ')}
                                        </div>
                                        <div className='border min-w-[300px]'>
                                            {Object.entries(rebateableStats[1]).map((stats, idx) => (
                                                <div key={`${outerIndex}:${index}:${idx} b340 ${rebateableStats} - ${stats[0]}`} className='flex justify-between border-b last:border-none py-1  hover:bg-primary-dark hover:text-white'>
                                                    <div div className={' pl-2'}>
                                                        {stats[0]}
                                                    </div>
                                                    <div className={'pr-2'}>
                                                        {rebateableStats[0]==="rebates_by_340b_pct"&&formatDecimalToPercentage(stats[1])}
                                                        {rebateableStats[0]==="rebates_by_340b"&&formatPerBrandEstimates(stats[1])}
                                                        {rebateableStats[0]==="rebates_by_340b_by_channel"&&<table className='w-[160px]'>
                                                            <tbody>
                                                                {Object.entries(stats[1]).map((rebates, num)=>(
                                                                    <tr key={`${index}:${idx}:${num} b340 ${rebateableStats} - ${stats[0]} - ${rebates[0]}`} className='border-b last:border-none '>
                                                                        <td className=''>
                                                                            {rebates[0]}
                                                                        </td>
                                                                        <td className='text-right'>
                                                                            {formatPerBrandEstimates(rebates[1])}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>}
                                                        {rebateableStats[0]==="rebates_by_340b_by_channel_pct"&&<table className='w-[160px]'>
                                                            <tbody>
                                                                {Object.entries(stats[1]).map((rebates, num)=>(
                                                                    <tr key={`${index}-${idx} b340 ${rebateableStats} - ${stats[0]} - ${rebates[0]}`} className='border-b last:border-none '>
                                                                        <td className=''>
                                                                            {rebates[0]}
                                                                        </td>
                                                                        <td className='text-right'>
                                                                            {formatDecimalToPercentage(rebates[1])}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </td>
                            :null}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}