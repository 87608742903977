import React from 'react';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function EventScenarioControls({ controller, control, toggle, accessToggler }) {
    const buttonStyles = "py-1 px-6 bg-light-blue text-white font-bold hover:bg-hover-blue rounded";
    return (
        <>
            <div className='flex justify-between bg-primary-dark rounded-b-xl p-4'>
                <div className='flex gap-6'>
                    <button onClick={() => globalBoolHandler('currentPage', controller, control, "GET" )} className={buttonStyles}>
                        MAIN
                    </button>
                    {/* <button onClick={() => globalBoolHandler('currentPage', controller, control, "PUT" )} className={buttonStyles}>
                        EDIT
                    </button> */}
                    <button onClick={() => globalBoolHandler('currentPage', controller, control, "POST" )} className={buttonStyles}>
                        CREATE
                    </button>
                </div>
                {/* <button onClick={() => globalBoolHandler('currentPage', controller, control, "PATCH" )} className={buttonStyles}>
                    PATCH (events)
                </button> */}
                {/* <button onClick={() => globalBoolHandler('currentPage', controller, control, "DELETE" )} className={buttonStyles}>
                    DELETE
                </button> */}
                <button onClick={() => globalBoolHandler('scenarios', accessToggler, toggle, false )} className={buttonStyles}>
                    CANCEL
                </button>
            </div>
        </>
    );
};