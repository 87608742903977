import React from 'react';
import { formatBytes } from '../../utils/formularySelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function ImportPanel({setFile, file, setMessage, accept, height, acceptedFileType, alt }) {

    function handleFileDrop(e) {
        e.stopPropagation();
        e.preventDefault();
        setMessage([]);

        const {files} = e?.dataTransfer;
        
        const uploadedFile = files[0];
        if (accept) {
            setFile(prev => ({
                ...prev,
                file: uploadedFile
            }));
        } else {
            if (uploadedFile?.size >= 5000000000) {
                alert("File size cannot be over 5GB")
        } else {
            if (uploadedFile?.type.includes("csv")||uploadedFile?.name.endsWith(".psv")||uploadedFile?.name.endsWith(".xlsx")) {
                setFile(prev => ({
                    ...prev,
                    file: uploadedFile
                }));
            } else {
                if (uploadedFile) {
                    setMessage(["This file type is not allowed. Please try again with a csv or psv file type."]);
                }
            };
        }}
    };

    window.addEventListener("dragover",function(e){
        e.preventDefault();   
      },false);
    window.addEventListener("drop",function(e){
        e.preventDefault();  
    },false);

    function handleFileLoad(e) {
        setMessage([]);
        const uploadedFile = e.target.files[0];

        if (accept) {
            setFile(prev => ({
                ...prev,
                file: uploadedFile
            }));
        } else {
            if (uploadedFile?.size >= 5000000000) {
            alert("File size cannot be over 5GB")
        } else {
            if (uploadedFile?.type.includes("csv")||uploadedFile?.name.endsWith(".psv")||uploadedFile?.name.endsWith(".xlsx")) {
                setFile(prev => ({
                    ...prev,
                    file: uploadedFile
                }));
            } else {
                setMessage(["This file type is not allowed. Please try again with a csv or psv file type."]);
            };
        }};
    };

    function handleCancel(e) {
        e.preventDefault();
        setFile(prev => ({
            ...prev, 
            file: null})
        );
    };
    
    return (
        <>
            <label 
                htmlFor="dropzone-file" 
                className={`flex flex-col items-center justify-center w-full ${height?height:'h-[300px]'} border-2 border-gray-300 border-dashed rounded-md cursor-pointer bg-gray-50 dark:bg-gray-300 hover:bg-gray-200 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-200 `} 
                onDrop={handleFileDrop} onDrag={handleFileDrop} onDragEnter={handleFileDrop} onDragLeave={handleFileDrop} 
                >
                <div className="flex flex-col items-center justify-center pt-5 pb-6 px-6 ">
                    {!file ?
                        <>
                            <svg className="w-8 h-8 mb-4 text-gray-600 dark:text-gray-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                            </svg>
                            <p className="mb-1 text-sm text-gray-600 dark:text-gray-600 "><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-sm mb-1 text-gray-600 dark:text-gray-600 ">{acceptedFileType?acceptedFileType:'.xlsx, .csv, & .psv files only'}</p>
                            {/* <p className="text-sm mb-1 text-gray-600 dark:text-gray-600 ">{acceptedFileType?acceptedFileType:'for all .xlsx file types, please select your worksheet tab'}</p> */}
                            <p className="text-xs font-medium text-gray-500 dark:text-primary-red">Maximum file size 5 GB</p>
                        </>:<div className='flex gap-4'>
                            <div className='text-center max-w-[230px] overflow-x-hidden tracking-tighter text-ellipsis'>
                                <span className='pr-2 '>
                                    {alt?file.file.name:file.name}
                                </span>
                                <br />
                                <span className='pr-3 whitespace-nowrap'>
                                    ({formatBytes(alt?file.file.size:file.size)})
                                </span>
                            </div>
                            <button type='button' onClick={handleCancel}>
                                <FontAwesomeIcon className='text-2xl hover:text-primary-light' icon={faXmark} />
                            </button>
                        </div>
                    }
                </div>
                <input name='csvfile' id="dropzone-file" className='hidden' type='file' accept={!accept?'.csv, .psv':""} onChange={handleFileLoad} onClick={e => e.target.value = null} />
            </label>
        </>
    );
}