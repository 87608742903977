import React from 'react';
import { tailChase } from 'ldrs';

export default function TailChaseLoader({size, color}) {
    tailChase.register();
    return (
        <l-tail-chase
            size={size?size:"20"}
            speed="1.75" 
            color={color?color:'#00141c'} 
        ></l-tail-chase>
    );
};