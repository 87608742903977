import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateForward} from "@fortawesome/free-solid-svg-icons";
import PopUpSection from "../../components/Section/PopUpSection";
import FormButton from "../../components/Buttons/FormButton";
import { UseUserContext } from '../../context/UserContext';
import { checkVerificationSMS, resendOTP } from '../../utils/accounts';

export default function TwoFactorAuth({ toggle, setTogg, authenticate2FA }) {
    
    const account = UseUserContext();

    const [ twoFactorCode, addTFCode ] = useState(null);
    const [ loading, setLoader ] = useState(null);
    const [ __, setR ] = useState({});

    const [ resend, timer ] = useState(10);
    
    useEffect(() => {
        var setTimer = setInterval(() => {
            timer(number => number-1)
        }, 1000);
        return () => clearInterval(setTimer)
    }, [resend]);

    useEffect(() => {
        resendOTP(authenticate2FA.challengeID, setR);
    }, []);

    function handleAuthentication(e) {
        e.preventDefault();
        const payload = {
            phone_number: "",
            code: twoFactorCode,
            username: account.user.userData.username,
            scopes: ["me"],
            challenge_id: authenticate2FA.challenge_id,
        };
    
        checkVerificationSMS(payload, setLoader, account, setR, setTogg, toggle);
    };

    return (
        <>
            <PopUpSection>
                <div className="w-[600px] h-[370px] mx-auto bg-white rounded-xl py-20 px-8" >
                    <div>
                        {account.user.userData.full_name} * {account.user.userData.orgid}
                    </div>
                    <div>
                        Enter the code sent to <span className="font-bold text-xl ">{`(***) *** - ${account.user.userData.phone.slice(-4)}`}</span>
                    </div>
                    <form onSubmit={handleAuthentication} className="w-full">
                        <label>
                            <input onChange={e => addTFCode(e.target.value)} placeholder="Enter code" type='text' minLength={6} maxLength={6} className=" border text-center tracking-[10px] w-full py-3 px-3 rounded my-5" />
                        </label>
                        <div className="mb-4 flex gap-2 items-center">
                            {resend<=0?
                                <button type='button' className='text-light-blue hover:text-hover-blue' onClick={() => (resendOTP(authenticate2FA.challenge_id, setR), timer(10))}>
                                    Get a new code <FontAwesomeIcon className={`text-light-blue hover:text-hover-blue cursor-pointer `}  icon={faArrowRotateForward}/>
                                </button>
                            :`We can resend a code in ${resend}`}
                        </div>
                        <FormButton buttonText={'Continue'} loading={loading} addClass="rounded-full" />
                    </form>
                </div>
            </PopUpSection>
        </>
    )
}