import React, { useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import Select from 'react-select'
import { saveVendorRate, updateVendorrRate } from '../../utils/vendorConsole';
import { globalBoolHandler, handleCustomListChange } from '../../utils/globalHandlers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

export default function ContractualDefinition({page, next, choice, vendorName, setDetails, vendorDetails}) {

    const toggler = UseToggleContext();
    const context = UseContextClues();

    const inputStyles = "border px-2 py-1 rounded font-normal";
    const labelStyles = "font-medium flex flex-col rounded mb-3 w-full ";

    const checkboxStyles = "mr-2 ";

    const labelStyle = "font-medium mt-3 ";
    const inputStyle = "font-normal w-full border py-2 px-4 mt-2 mb-2 rounded-lg";

    const [ settings, setSettings ] = useState({
        "brand_definition": "mnoBt",
        "otc_definition": "medispanOpLessDiabeticSupplies",
        "exclusions": {
            "exclusionLdd": false,
            "exclusionAuthGenerics": false,
            "exclusionVaccines": true,
            "exclusionVa": true,
            "exclusionItu": true,
            "exclusionLtc": true,
            "exclusionHomeInfusion": true,
            "exclusionOTC": true,
            "exclusion340b": true,
            "exclusionCosmetic": false,
            "exclusionRepack": false,
            "exclusionInstitutional": false,
            "exclusionMCO": false,
            "exclusionClinic": false,
            "exclusionNuclear": false,
            "exclusionMilitary": false,
            "exclusionCovidDrugs": false,
            "exclusionDAW3456": false,
            "exclusionCompounds": false,
            "exclusionVitamins": false,
            "exclusionNonFdaDrugs": false,
            "exclusionMSB": false
        },
        "rate": 0,
        "channel": "",
        "start_date": "2024-06-11",
        "end_date": "2024-06-11",
        "vendor_name": vendorName,
        "formulary_name": "",
        "lob_name": "",
        "custom_lists": {
            "exclusion": [],
            "constraint": []
        }
    });

    const [ defaultList, setDefaults ] = useState({
        exclusion : [],
        constraint : [],
    });
    
    const [ defaultEdit, updateEdit ] = useState({
        "brand_definition": "mnoBt",
        "otc_definition": "medispanOpLessDiabeticSupplies",
        "exclusions": {
            "exclusionLdd": false,
            "exclusionAuthGenerics": false,
            "exclusionVaccines": true,
            "exclusionVa": true,
            "exclusionItu": true,
            "exclusionLtc": true,
            "exclusionHomeInfusion": true,
            "exclusionOTC": true,
            "exclusion340b": true,
            "exclusionCosmetic": false,
            "exclusionRepack": false,
            "exclusionInstitutional": false,
            "exclusionMCO": false,
            "exclusionClinic": false,
            "exclusionNuclear": false,
            "exclusionMilitary": false,
            "exclusionCovidDrugs": false,
            "exclusionDAW3456": false,
            "exclusionCompounds": false,
            "exclusionVitamins": false,
            "exclusionNonFdaDrugs": false,
            "exclusionMSB": false
        },
        "rate": 0,
        "channel": "",
        "start_date": "2024-06-11",
        "end_date": "2024-06-11",
        "vendor_name": vendorName,
        "formulary_name": "",
        "lob_name": "",
        "custom_lists": {
            "exclusion": [],
            "constraint": []
        }
    });

    useEffect(() => {
        if (page.section==="edit"&&vendorDetails?.[vendorName]?.[choice].exclusions) {
            updateEdit(data => ({
                ...data,
                ...vendorDetails?.[vendorName]?.[choice],
                exclusions: JSON.parse(vendorDetails?.[vendorName]?.[choice].exclusions),
                custom_lists: JSON.parse(vendorDetails?.[vendorName]?.[choice].custom_lists)
            }));
        };
    }, [choice, page.section]);


    useEffect(() => {
        var constraints 
            if (page.section==='edit'&&vendorDetails?.[vendorName]?.[choice]?.custom_lists) {
                constraints = JSON.parse(vendorDetails?.[vendorName]?.[choice]?.custom_lists)?.constraint.map(ele => ({label: ele, value: ele}));
            } else {
                if (settings.custom_lists?.constraint.length>0) {
                    constraints = settings.custom_lists?.constraint.map(ele => ({label: ele, value: ele}));
                }
            };
        if (constraints)setDefaults(data => ({...data, constraint: constraints}));

    }, [choice, page.section ]);
    useEffect(() => {
        var constraint 
        if (page.section==="edit"&&defaultEdit.custom_lists?.constraint) {
            constraint = defaultEdit.custom_lists?.constraint.map(ele => ({label: ele, value: ele}));
        } else {
            constraint = settings.custom_lists?.constraint.length>0?settings.custom_lists?.constraint.map(ele => ({label: ele, value: ele})):[];
        };

        if (constraint)setDefaults(data => ({...data, constraint: constraint}));
    }, [defaultEdit.custom_lists?.constraint  ]);
    useEffect(() => {
        var constraint = settings.custom_lists?.exclusion.map(ele => ({label: ele, value: ele}));

        if (constraint)setDefaults(data => ({...data, exclusion: constraint}));
    }, [settings.custom_lists?.constraint, page.section  ]);

    useEffect(() => {
        var exclusions 
        if (page.section==="edit"&&vendorDetails?.[vendorName]?.[choice]?.custom_lists) {
            exclusions = JSON.parse(vendorDetails?.[vendorName]?.[choice]?.custom_lists)?.exclusion.map(ele => ({label: ele, value: ele}));
        } else {
            exclusions = settings.custom_lists?.exclusion.map(ele => ({label: ele, value: ele}));
        };

        if (exclusions)setDefaults(data => ({...data, exclusion: exclusions}));
    }, [context.exclusionSettings.custom_lists.exclusion, choice, page.section  ]);
    useEffect(() => {
        var exclusions 
        if (page.section==="edit"&&defaultEdit.custom_lists?.exclusion) {
            exclusions = defaultEdit.custom_lists?.exclusion.map(ele => ({label: ele, value: ele}))
        } else {
            exclusions = settings.custom_lists?.exclusion.length>0?settings.custom_lists?.exclusion.map(ele => ({label: ele, value: ele})):[]
        };

        if (exclusions)setDefaults(data => ({...data, exclusion: exclusions}));
    }, [defaultEdit.custom_lists?.exclusion  ]);
    useEffect(() => {
        var exclusions = settings.custom_lists?.exclusion.map(ele => ({label: ele, value: ele}))

        if (exclusions)setDefaults(data => ({...data, exclusion: exclusions}));
    }, [settings.custom_lists?.exclusion, page.section  ]);

    function handleChange(e){
        const { name, value, checked } = e.target;

        if(name.includes("exclusion")) {
            if (page.section==='edit') {
                updateEdit(data => ({
                    ...data,
                    exclusions: {
                        ...data.exclusions,
                        [name]: checked
                    }
                }));
            } else {
                setSettings(data => ({
                    ...data,
                    exclusions: {
                        ...data.exclusions,
                        [name]: checked
                    }
                }));
            };
        } else {
            if (page.section==='edit') {
                updateEdit(data => ({
                    ...data,
                    [name]: value,
                }));
            } else {
                setSettings(data => ({
                    ...data,
                    [name]: value,
                }));
            };
        };
    };

    function saveEditRate(e) {
        e.preventDefault();
        if (page.section === 'edit') {
            updateVendorrRate(vendorDetails?.[vendorName]?.[choice]?.id, defaultEdit, setDetails, vendorDetails, vendorName, next);
        }else {
            saveVendorRate(settings, vendorDetails, setDetails, vendorName);
        };
    };

    return (
        <div className='flex border rounded shadow bg-white justify-center overflow-y-auto p-4 xl:p-4 '>
            <form style={{height: toggler.pageHeight-300}} onSubmit={saveEditRate} className='w-full border rounded p-3 capitalize overflow-y-auto'>
                <div className='flex gap-6'>
                    <div className='w-full text-[0.7rem] xl:text-sm'> 
                        <div className='flex gap-4'>
                            {/* <label htmlFor="vendor_name" className={labelStyles}>vendor Name
                                <input value={settings.vendor_name} name="vendor_name" onChange={handleChange} type='text' className={inputStyles} />
                            </label> */}
                            <label htmlFor='formulary_name' className={labelStyles}>formulary name
                                <input value={page.section==='edit'?defaultEdit.formulary_name:settings.formulary_name} name='formulary_name' onChange={handleChange} type='text' className={inputStyles} />
                            </label>
                        </div>
                        <div className='xl:flex gap-4'>
                            <label htmlFor='lob_name' className={labelStyles}>line of business name
                                <input value={page.section==='edit'?defaultEdit.lob_name:settings.lob_name} name='lob_name' onChange={handleChange} type='text' className={inputStyles} />
                            </label>
                            <label htmlFor='rate' className={labelStyles}>Rate
                                <input value={page.section==='edit'?defaultEdit.rate:settings.rate} name='rate' onChange={handleChange} type='number' className={inputStyles} />
                            </label>
                        </div>
                        <label htmlFor='channel' className={labelStyles}>Channel
                            <input value={page.section==='edit'?defaultEdit.channel:settings.channel} name='channel' onChange={handleChange} type='text' className={inputStyles} />
                        </label>
                        <div className='xl:flex gap-4'>
                            <label htmlFor='start_date' className={'flex flex-col border  p-3 w-full rounded'}>Start date
                                <input value={page.section==='edit'?defaultEdit.start_date:settings.start_date} name='start_date' onChange={handleChange} type='date' className={''} />
                            </label>
                            <label htmlFor='end_date' className={'flex flex-col border  p-3 w-full rounded'}>End date
                                <input value={page.section==='edit'?defaultEdit.end_date:settings.end_date} name='end_date' onChange={handleChange} type='date' className={''} />
                            </label>
                        </div>
                        <div className='xl:flex gap-4'>
                            <label className={labelStyle} htmlFor="brand_definition">Brand Definition:
                                <select className={inputStyle} id="brand_definition" value={page.section==='edit'?defaultEdit.brand_definition:settings.brand_definition} name="brand_definition" onChange={handleChange}>
                                    <option value="mnoBt">MNO/BT</option>
                                    <option value="mno">MNO</option>
                                </select>
                            </label>
                            
                            <label className={labelStyle} htmlFor="otc_definition">OTC Definition:
                                <select className={inputStyle} value={page.section==='edit'?defaultEdit.otc_definition:settings.otc_definition} id="otc_definition" name="otc_definition" onChange={handleChange}>
                                    <option value="medispanOpLessDiabeticSupplies">Medispan OP less diabetic supplies</option>
                                    <option value="medispanOpLessTestStrips">Medispan OP less test strips</option>
                                    <option value="medispanOp">Medispan OP</option>
                                </select>
                            </label>
                        </div>
                        <label  className={"mb-auto mt-2 pointer-events-none "+labelStyles}><span className='flex justify-between items-center'><span>custom list exclusions</span><button type='button' className='pointer-events-auto text-light-blue hover:text-hover-blue text-xl' onClick={() => globalBoolHandler("userDefinedLists", toggler.toggle, toggler.setToggle, !toggler.toggle.userDefinedLists)}>
                                <FontAwesomeIcon icon={faPlusSquare}/>
                            </button></span>
                        </label>
                            <Select 
                                isMulti
                                options={context.models.customList.map((value, idx) => ({
                                    value: `${value.list_name}`,
                                    label: `${idx+1}: ${value.list_name}`,
                                    toString: () => value.id
                                }))}
                                onChange={e => (page.section==="edit"?handleCustomListChange(e, 'exclusion', updateEdit, null, "custom_lists"):handleCustomListChange(e, 'exclusion', setSettings, null, "custom_lists"))}
                                name='exclusion'
                                menuShouldScrollIntoView={false}
                                className='mb-2 mt-1'
                                menuPosition='fixed'
                                value={page.section==="edit"?defaultList?.exclusion:undefined}
                                menuPortalTarget={document.body} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 500, fontSize: "x-small"  }) }}
                            />
                        <label className={labelStyles}>custom list constraints
                            <Select 
                                isMulti
                                options={context.models.customList.map((value, idx) => ({
                                    value: `${value.list_name}`,
                                    label: `${idx+1}: ${value.list_name}`,
                                    toString: () => value.id
                                }))}
                                onChange={e => page.section==="edit"?handleCustomListChange(e, 'constraint', updateEdit, null, "custom_lists"):handleCustomListChange(e, 'constraint', setSettings, null, "custom_lists")}
                                menuShouldScrollIntoView={false}
                                name='constraint'
                                className='mt-1'
                                menuPosition='fixed'
                                value={page.section==="edit"?defaultList?.constraint:undefined}
                                menuPortalTarget={document.body} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 500, fontSize: "x-small", }) }}
                            />
                        </label>
                    </div>

                    <div className='flex flex-col gap-x-6 mb-2 text-[0.7rem] tracking-tighter'>         
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionLdd" name="exclusionLdd"  checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionLdd:settings?.exclusions?.exclusionLdd} onChange={handleChange}/>
                            <label htmlFor="exclusionLdd">LDD</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionVaccines" name="exclusionVaccines" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionVaccines:settings?.exclusions?.exclusionVaccines} onChange={handleChange}/>
                            <label htmlFor="exclusionVaccines">Vaccines</label>
                        </div>
                        <div className='whitespace-nowrap'>
                            <input className={checkboxStyles} type="checkbox" id="exclusionAuthGenerics" name="exclusionAuthGenerics" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionAuthGenerics:settings?.exclusions?.exclusionAuthGenerics}  onChange={handleChange}/>
                            <label className='' htmlFor="exclusionAuthGenerics">Authorized Generics</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionVa" name="exclusionVa" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionVa:settings?.exclusions?.exclusionVa} onChange={handleChange}/>
                            <label htmlFor="exclusionVa">VA</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionItu" name="exclusionItu" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionItu:settings?.exclusions?.exclusionItu} onChange={handleChange}/>
                            <label htmlFor="exclusionItu">ITU</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionLtc" name="exclusionLtc" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionLtc:settings?.exclusions?.exclusionLtc} onChange={handleChange}/>
                            <label htmlFor="exclusionLtc">LTC</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionHomeInfusion" name="exclusionHomeInfusion" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionHomeInfusion:settings?.exclusions?.exclusionHomeInfusion} onChange={handleChange}/>
                            <label htmlFor="exclusionHomeInfusion">Home Infusion</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionOTC" name="exclusionOTC" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionOTC:settings?.exclusions?.exclusionOTC} onChange={handleChange}/>
                            <label htmlFor="exclusionOTC">OTC</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusion340b" name="exclusion340b" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusion340b:settings?.exclusions?.exclusion340b} onChange={handleChange}/>
                            <label htmlFor="exclusion340b">340B</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionCosmetic" name="exclusionCosmetic" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionCosmetic:settings?.exclusions?.exclusionCosmetic} onChange={handleChange}/>
                            <label htmlFor="exclusionCosmetic">Cosmetic</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionRepack" name="exclusionRepack" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionRepack:settings?.exclusions?.exclusionRepack} onChange={handleChange}/>
                            <label htmlFor="exclusionRepack">Repackager</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionInstitutional" name="exclusionInstitutional" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionInstitutional:settings?.exclusions?.exclusionInstitutional} onChange={handleChange}/>
                            <label htmlFor="exclusionInstitutional">Institutional</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionMCO" name="exclusionMCO" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionMCO:settings?.exclusions?.exclusionMCO} onChange={handleChange}/>
                            <label htmlFor="exclusionMCO">MCO</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionClinic" name="exclusionClinic" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionClinic:settings?.exclusions?.exclusionClinic} onChange={handleChange}/>
                            <label htmlFor="exclusionClinic">Clinic</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionNuclear" name="exclusionNuclear" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionNuclear:settings?.exclusions?.exclusionNuclear} onChange={handleChange}/>
                            <label htmlFor="exclusionNuclear">Nuclear</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionMilitary" name="exclusionMilitary" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionMilitary:settings?.exclusions?.exclusionMilitary} onChange={handleChange}/>
                            <label htmlFor="exclusionMilitary">Military</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionCovidDrugs" name="exclusionCovidDrugs" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionCovidDrugs:settings?.exclusions?.exclusionCovidDrugs} onChange={handleChange}/>
                            <label htmlFor="exclusionCovidDrugs">Covid Drugs</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionDAW3456" name="exclusionDAW3456" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionDAW3456:settings?.exclusions?.exclusionDAW3456} onChange={handleChange}/>
                            <label htmlFor="exclusionDAW3456">DAW 3456</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionCompounds" name="exclusionCompounds" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionCompounds:settings?.exclusions?.exclusionCompounds} onChange={handleChange}/>
                            <label htmlFor="exclusionCompounds">Compounds</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionVitamins" name="exclusionVitamins" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionVitamins:settings?.exclusions?.exclusionVitamins} onChange={handleChange}/>
                            <label htmlFor="exclusionVitamins">Vitamins</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionNonFdaDrugs" name="exclusionNonFdaDrugs" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionNonFdaDrugs:settings?.exclusions?.exclusionNonFdaDrugs} onChange={handleChange}/>
                            <label htmlFor="exclusionNonFdaDrugs">Non FDA Drugs</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionMSB" name="exclusionMSB" checked={page.section === "edit"?defaultEdit?.exclusions?.exclusionMSB:settings?.exclusions?.exclusionMSB} onChange={handleChange}/>
                            <label htmlFor="exclusionMSB">Multisource Brands - O</label>
                        </div>
                    </div>
                </div>
                <div className='flex gap-3 my-5'>
                    <button className='border py-3 px-8 rounded-xl bg-light-blue text-white font-bold hover:bg-hover-blue' type='submit'>{page.section==="main"?"Save":page.section==="edit"?"Edit":"N/A"} vendor rates</button>
                    {page.section==="edit"?<button onClick={() => next(data => ({...data, section:'main'}))} className='font-bold text-light-blue hover:text-hover-blue' type='button'>Cancel</button>:null}
                </div>
            </form>
        </div>

    );
};