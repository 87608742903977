import { useState } from "react"
import CollapsibleButton from "../Buttons/CollapsibleButton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { UseContextClues } from "../../context/ContextClues";

export default function Spec() {

    const context = UseContextClues();
    
    const [collapsible, toggle] = useState(true)

    const handleToggle = () => {
        toggle(!collapsible)
    };    

    function status (item){
        if (!item) {
            return <FontAwesomeIcon className="text-primary-red" icon={faCircleXmark} />
        } else {
            return <FontAwesomeIcon className="text-primary-green" icon={faCheck} />
        }
    }
    
    return (
        <>  
            <CollapsibleButton onClick={handleToggle} buttonTitle={'Status'} addClasses={'mt-1'} chevron={collapsible}/>
            <div className={` transition-all duration-700 px-4 text-xs border-b border-primary-dark ${collapsible ? "translate-y-[0] opacity-1 max-h-[204px] pb-3 pt-2" : "opacity-0 overflow-hidden  max-h-0 "}`}>
                <div className="" >
                    <div className="text-xs font-bold mb-2 uppercase tracking-widest">Specifications</div>
                    <div>
                        <strong>Data Accuracy:</strong>&nbsp;
                        <span className="underline">Initial</span>&nbsp;
                        {status(!context.settings?.lockClaims)}
                        <span className="pl-1 underline">Models</span>&nbsp;
                        {status(!context.settings.locked)}
                    </div>
                    <div>
                        <strong>Filename:</strong>&nbsp;
                        <span>{context.settings.claims?.tName}</span>&nbsp;
                        {status(context.settings.claims?.tName)}
                    </div>
                    <div>
                        <strong>Claims Data Start Date:</strong>&nbsp;
                        <span>{context.settings.claims?.startDate}</span>&nbsp;
                        {status(context.settings.claims?.startDate)}
                    </div>
                    
                    <div>
                        <strong>Claims Data End Date:</strong>&nbsp;
                        <span>{context.settings.claims?.endDate}</span>&nbsp;
                        {status(context.settings.claims?.endDate)}
                    </div>
                    
                    <div>
                        <strong>Base Formulary ID:</strong>&nbsp;
                        <span>{context.settings.formulary?.baseFormulary}</span>&nbsp;
                        {status(context.settings.formulary?.baseFormulary)}
                    </div>
                    <div>
                        <strong>Target Formulary ID:</strong>&nbsp;
                        <span>{context.settings.formulary?.targetFormulary}</span>&nbsp;
                        {status(context.settings.formulary?.targetFormulary)}
                    </div>

                    <div>
                        <strong>Year 1 Start Date:</strong>&nbsp;
                        <span>{context.settings.year?.year1}</span>&nbsp;
                        {status(context.settings.year?.year1)}
                    </div>
                    <div>
                        <strong>Selected Scenario:</strong>&nbsp;
                        <span>{context.settings.currentScenario?context.settings?.currentScenario:<span>No scenario selected! <FontAwesomeIcon className="text-primary-red" icon={faCircleXmark} /></span>}</span>&nbsp;
                        {/* {status(context.settings.currentScenario)} */}
                    </div>
                </div>
            </div>
        </>
    )
}