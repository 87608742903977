import { globalBoolHandler } from "./globalHandlers";
import {extractFromLocal} from "./loadSave";

export function addEvent(event, currentEvents, addEvents, setExclusions, setFields, setTarget) {
    // Send the new event to the server
    fetch('/api/events/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`,
        },
        body: JSON.stringify(event)
    })
    .then(response => response.json())
    .then(data => {
        // Check if the request was successful
        if (data.success) {
            const use = data.data
            const payload = {
                id: use.id,
                mag: use.mag,
                modify: use.modify,
                startdate: use.startdate,
                target: use.target,
                etype: use.etype,
                ename: use.ename,
                basis: use.basis,
                description: use.description,
            }
            addEvents([
                ...currentEvents,
                payload
            ]);
            setExclusions(data => ({
                ...data,
                events: [...data.events, use.ename]
            }));
            setFields({
                basis: "absolute",
                etype: "drug",
                ename: "",
                mag: 0,
                startdate: "",
                modify: "rebaterate",
                description: ""
            });
            setTarget('');
        };
    })
    .catch(error => console.error('Error:', error));
};

// Function for loading the events
export function loadEvents(addEvents) {
    fetch(`/api/events/`,
    {       
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
    })
        .then(response => response.json())
        .then(data => {
            if (data.detail) {
                console.log(data)
            } else {
                addEvents(data)
            }
        }
    );
};

export function deleteEvent(id, addEvents) {
    fetch(`/api/events/${id}`, {method: 'DELETE',
    headers: { 
        'Content-Type' : 'application/json',
        Authorization : `Bearer ${extractFromLocal().accessToken}`,
    }})
    .then(response => {
        if (response.ok) {
            // If the delete was successful, reload the events
            loadEvents(addEvents);
        } else {
            console.error('Error:', response.status);
        }
    });
};

export function convertList(exclusionEvents, contextEvents, set, bool) {

    let dict = {};

    if (exclusionEvents?.length>0&&!bool) {
        contextEvents.forEach(ele => {
            if (!dict[ele.ename]) {
                if (exclusionEvents.includes(ele.ename)) {
                    dict[ele.ename] = true;
                } else {
                    dict[ele.ename] = false;
                };
            }
        });
    } else {
        contextEvents.forEach(ele => {
            if (!dict[ele.ename]) {
                dict[ele.ename] = bool;
            }
        });
    };
    set(dict);
};

export function convertToArray(list, name, set) {

    let array = []

    if (Object.entries(list).length>0) {
        Object.entries(list).forEach(list => {
            list[1] === true && array.push(list[0])  
        });
    };

    set(data => ({
        ...data,
        [name]: array
    }))

};

export function handleApplyEvent(e, set, list, lock, converted, scenario, deck) {
    const { name, checked } = e.target;

    lock(data => ({
        ...data,
        savedCase: false,
        locked: true,
    }));

    set(appliedList => ({
        ...appliedList,
        [name] : checked
    }));

    // return convertToArray(converted, scenario, deck);
};

export async function editSingleEvent(payload, id, settings, currentEvents, alterEvents, edit) {

    settings(data => ({
        ...data,
        savedCase: false,
        locked: true,
    }));

    try {

        const data = await fetch(`/api/events/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });

        await data.json();

        if (data.ok) {
            const current = [...currentEvents];
            const arr = current.map(obj => obj.id === id? payload:obj);
            alterEvents(arr);
            edit({});
        };

    } catch (err) {
        console.log(err)
    }
};

export async function getAllScenarios(set, context) {

    try {
        const data = await fetch(`/api/scenarios`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });

        const response = await data.json();

        if (data.ok) {
            set(prev => ({
                ...prev,
                scenarios: response,
                scenarioObject: restructureArray2ObjectbyID(response, "scenario_id"),
                eventsObject: restructureArray2ObjectbyID(context.events, "id")
            }))
        };
    } catch (err) {
        console.log(err)
    };
};

function restructureArray2ObjectbyID(array, idName) {
    var  dict = {};
    for(let i = 0; i < array.length; i++) {
        dict[array[i][idName]]= array[i]
    }
    return dict
};

export async function getScenario(id, set) {

    try {
        const data = await fetch(`/api/scenarios/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });

        await data.json();
        // if (data.ok) {
        //     set(prev => ({
        //         ...prev,
        //         chosenScenario: data
        //     }))
        // } else {
        //     set(prev => ({
        //         ...prev,
        //         errorMessage: data.detail 
        //     }));
        // };

    } catch (err) {
        console.log(err)
    };
};

export async function postScenario(payload, set) {

    try {
        const data = await fetch(`/api/scenarios`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });

        const response = await data.json();
        if (data.ok) {
            set(prev => ({
                ...prev,
                currentPage: "GET",
                scenarios: [
                    ...prev.scenarios,
                    response
                ],
                createEvents: {
                    name: "",
                    description: "",
                    event_ids: [],
                }
            }));
            alert(`Scenario ${response.name} has been saved successfully.`)
        };
    } catch (err) {
        console.log(err)
    };
};

export async function updateScenario(id, payload, set, currentScenario, scenario, context) {

    const usePayload = {
        name: payload.name,
        description: payload.description,
        event_ids: [...payload.event_ids],
    }

    try {
        const data = await fetch(`/api/scenarios/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(usePayload)
        });

        const response = await data.json();
        if (data.ok) {
            set(prev => ({
                ...prev,
                currentPage: "GET",
                scenarios: prev.scenarios.map(scenario => scenario.scenario_id===response.scenario_id?response:scenario),
                createEvents: {
                    name: "",
                    description: "",
                    event_ids: [],
                }
            }));
            alert(`Edit for ${response.name} has been saved successfully.`)

            if (currentScenario===payload.name) {
                return applyEvents(scenario, context);
            };

        };
    } catch (err) {
        console.log(err)
    };
};

export async function patchScenario( id) {

    try {
        const data = await fetch(`/api/scenarios/${id}/events`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });

        await data.json();
        if (data.ok) {

        };
    } catch (err) {
        console.log(err)
    };
};

export async function deleteScenario(id, name, previous, set, context) {

    try {
        const data = await fetch(`/api/scenarios/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });

        let response = await data.json();
        const newData = [...previous];
        if (data.ok) {
            set(prev => ({
                ...prev,
                scenarios: newData.filter(scenes => scenes.scenario_id !== response.scenario_id)
            }));
            alert(`Scenario ${name} has been deleted!`);
            if (context.settings.currentScenario===name){
                context.setSettings(prev => ({
                    ...prev,
                    currentScenario: ''
                }));
                context.setExclusionSettings(prev=> ({
                    ...prev,
                    events: []
                }));
                context.updateList({});
            };
        };
    } catch (err) {
        console.log(err)
    };
};


export function applyEvents(array, context, passBack) {
    var controlEvents = [];
    array.event_ids.forEach(id => controlEvents.push(context.controller.eventsObject?.[id]?.ename));
    globalBoolHandler('events', context.exclusionSettings, context.setExclusionSettings, controlEvents);
    convertList(controlEvents, context.events, context.updateList, null);
    context.setSettings(prevData => ({
        ...prevData,
        savedCase: false,
        locked: true,
        currentScenario: array.name
    }));
    return passBack?passBack():null;
};