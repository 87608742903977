import React, { useState } from 'react';
import { UseToggleContext } from '../context/ToggleContexts';
import ImportClaim from '../components/ImportWindow/ImportClaim';
import ImportFormulary from '../components/ImportWindow/ImportFormulary';
import ImportPayment from '../components/ImportWindow/ImportPayment';
import FilesSection from '../components/RevenueCycleManagement/FilesSection';
import JoinsPage from '../components/RevenueCycleManagement/JoinsPage';
import PayStatsTable from '../components/Tables/PayStatsTable';
import ConstraintBuilder from '../components/RevenueCycleManagement/ConstraintBuilder';
import PageTransition from '../components/PageTransition/PageTransition';
import OpeningTransition from '../components/RevenueCycleManagement/OpeningTransition';

export default function RevenueCycleManagement() {

    const toggler = UseToggleContext();

    const [pageData, setPageData] = useState({
        page: '0', 
        payment_tables: [],
        claims_tables: [],
        formulary_rate_files: [],
        claims_columns: [],
        pmt_columns: [],
    });

    const [ fileSelector, selectFile ] = useState({
        pmt_file: "",
        pmt_files: [],
        claims_file: "",
        formulary_rate_file: "",
    });
    
    const [formData, setForm] = useState({
        startDate: "",
        endDate: "",
        priceDate: "",
        case_name: "",
    });
    const [ constraint, constrain ] = useState({
        setting : {
            type: "",
            choice: "",
            operator: "",
            freeform: "",
        },
        temp: {},
        rules: {}
    });

    const [ fields, setFeilds ] = useState([
        {
            on_claims_column: "",
            on_pmt_column: "",
        },
    ]);

    const [ stats, setStats ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ constraintBuilder, toggleCB ] = useState(false);

    return (
        <>  
            <PageTransition unmount="true" cycle={'starting'}>
                <>
                    <div style={{height: toggler.pageHeight-130}} className='flex bg-white '>
                        <div className='overflow-y-auto min-w-[450px] '>
                            <FilesSection {...{pageData, setPageData, fileSelector, selectFile}}/>
                            <JoinsPage {...{stats, pageData, setPageData, fileSelector, fields, setFeilds, setStats, setLoading, setForm, formData, constraintBuilder, toggleCB}} />
                        </div>
                        <PayStatsTable {...{stats, loading, formData, constraint}} />
                    </div>
                    {constraintBuilder&&<ConstraintBuilder {...{ formData, fields, fileSelector, setStats, setLoading, pageData, constraintBuilder, constraint, constrain, toggleCB, stats}} />}
                    {toggler.toggle?.importWindow&&<ImportClaim {...{setPageData}} />} 
                    {toggler.toggle?.importFormulary&&<ImportFormulary  />} 
                    {toggler.toggle?.importPayment&&<ImportPayment {...{setPageData}} />} 
                </>
            </PageTransition>
            <PageTransition cycle={'ending'}>
                <OpeningTransition />
            </PageTransition>
  
        </>
    )
};