import React, { useRef, useState } from 'react';
import { UseContextClues } from '../context/ContextClues';
import { globalChangeHandler, handleGlobalToggle } from '../utils/globalHandlers';
import { fuzzySweeper, specialtyAddNDC, specialtySweeper } from '../utils/qualityAssurance';
import FormButton from '../components/Buttons/FormButton';
import SpecialtySweeper from '../components/QualityAssurance/SpecialtySweeper';
import FuzzySweeper from '../components/QualityAssurance/FuzzySweeper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import CustomHover from '../components/Hover/CustomHover';
import Logo259 from '../components/SVG/Logo259';
import { UseToggleContext } from '../context/ToggleContexts';
import TailChaseLoader from '../components/Loader/TailChaseLoader';
import { copyToClipboard, downloadToPDF22, simplePDFDownload } from '../utils/download';

export default function QualityAssurance() {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const fuzzyRef = useRef(null);
    const specialRef = useRef(null);

    const [ inputs, set ] = useState({
        specialty : "",
        specialtyData : [],
        fuzzyOrg : "",
        fuzzyFormulary : "",
        fuzzyData : [],
        hoverSpecial: false,
        hoverFuzzy: false,
        loadSpecial: false,
        loadFuzzy: false,
        loadFuzzyPDF: false,
        loadSpecialPDF: false
    });

    const labelStyles = "flex flex-col border p-6 mb-10 tracking-widest bg-background-light rounded";
    const inputStyles = "flex flex-col border p-4 mt-2 w-full "; 
    const rowStyles = "w-full border  p-3 h-full "; 

    function handleSweeperChange(e) {
        if (e.target.value) {
            specialtySweeper(e.target.value, set);
        };
    };

    function handleFuzzySweeper(e) {
        e.preventDefault();
        fuzzySweeper(inputs.fuzzyOrg, inputs.fuzzyFormulary, set)
    };

    function bulkAddNDC() {
        const list = [];
        inputs.specialtyData.forEach(drugs => (
            list.push(drugs.NDC)
        ));
        specialtyAddNDC('Sweeptest', list);
    };
    
    function handleFuzzyDownload() {
        copyToClipboard(fuzzyRef.current.innerText)
    };
    function handleSpecialDownload() {
        copyToClipboard(specialRef.current.innerText)
    };

    function handlefuzzyPDF() {
        downloadToPDF22(fuzzyRef, `${inputs.fuzzyOrg}:${inputs.fuzzyFormulary}`, set, 'loadFuzzyPDF')
    };
    function handleSpecialPDF() {
        downloadToPDF22(specialRef, `${inputs.specialty}`, set, 'loadSpecialPDF')
    };
    
    return (
        <>
            <div className='flex h-screen'>
                <div className='border pb-[200px] p-10 w-[425px] bg-primary-dark h-full overflow-y-auto '>
                    <h2 className='text-white text-2xl mb-8'>
                        Quality Assurance Panel
                    </h2>
                    <div className={labelStyles}>
                        <div className='flex justify-between'>
                            <span>Specialty Sweeper</span>  
                            <FontAwesomeIcon onMouseEnter={() => handleGlobalToggle(set, "hoverSpecial", false)} onMouseLeave={() => handleGlobalToggle(set, "hoverSpecial", true)} className={'text-xl text-light-blue hover:text-hover-blue'} icon={faCircleInfo} />    
                        </div>
                        <label className='pt-4'>Select from specialty list:
                            <select name='specialty' onChange={handleSweeperChange} className={inputStyles}>
                                <option value={''}>
                                    --- Choose one ---
                                </option>
                                {context.models.modelList?.specialty_lists.map((listname, idx) => (
                                    <option key={`${idx}: specialty model ${listname}`} value={listname}>
                                        {listname}
                                    </option>
                                ))}
                            </select>
                        </label>
                        {inputs.specialtyData.length>0&&<button type="button" onClick={handleSpecialDownload} className='mt-4 border text-white py-2 w-full bg-light-blue text-sm font-bold rounded hover:bg-hover-blue'>
                            Copy 2 Clip
                        </button>}
                        {inputs.specialtyData.length>0&&<button type="button" onClick={handleSpecialPDF} className='mt-4 border text-white py-2 w-full bg-light-blue text-sm font-bold rounded hover:bg-hover-blue'>
                            {inputs.loadSpecialPDF?
                            <TailChaseLoader size={'20'} color={"white"} />
                            :'Download PDF'}
                        </button>}
                        {inputs.specialtyData.length>0&&<label className='pt-4 '>Add NDC reuslts
                            <button type='button' onClick={() => bulkAddNDC()} className='bg-light-blue hover:bg-hover-blue text-white w-full py-2 mt-2 rounded font-bold text-sm '>
                                Add all values
                            </button>
                        </label>}
                    </div>
                    <form onSubmit={handleFuzzySweeper} className={labelStyles}>
                        <div className='flex justify-between'>
                            <span>
                                Fuzzy Rate Chart Sweeper
                            </span>  
                            <FontAwesomeIcon onMouseEnter={() => handleGlobalToggle(set, "hoverFuzzy", false)} onMouseLeave={() => handleGlobalToggle(set, "hoverFuzzy", true)} className={'text-xl text-light-blue hover:text-hover-blue'} icon={faCircleInfo} />    
                        </div>
                        <label className={'mt-10'}>Organization:
                            <input required name='fuzzyOrg' onChange={(e) => globalChangeHandler(e, null, set)} className={inputStyles} />
                        </label>
                        <label className={''}>Formulary:
                            <select onChange={e => globalChangeHandler(e, null, set)} name='fuzzyFormulary' required className={inputStyles}>
                                <option value={""}>
                                    --- Choose one ---
                                </option>
                                {context.formularies.map((forms, idx) => (
                                    <option key={`${idx}: formulary model ${forms.formularyid}`}>
                                        {forms.formularyid}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <FormButton buttonText={'run'} />
                        {inputs.fuzzyData.length>0&&<button type="button" onClick={handleFuzzyDownload} className='mt-4 border text-white py-2 w-full bg-light-blue text-sm font-bold rounded hover:bg-hover-blue'>
                            Copy 2 Clip
                        </button>}
                        {inputs.fuzzyData.length>0&&<button type="button" onClick={handlefuzzyPDF} className='mt-4 border text-white py-2 w-full bg-light-blue text-sm font-bold rounded hover:bg-hover-blue'>
                            {inputs.loadFuzzyPDF?
                            <TailChaseLoader size={'20'} color={"white"} />
                            :'Download PDF'}
                        </button>}
                    </form>
                </div>
                <div className='border bg-primary-dark w-full'>
                    {!inputs.loadSpecial&&inputs.specialtyData.length>0?<div className='border p-4'>
                            <SpecialtySweeper {...{ inputs, rowStyles }} />
                        </div>:
                        <div style={{height: (toggler.pageHeight-131)/2}} className='flex items-center justify-center border'>
                            {inputs.loadSpecial?<TailChaseLoader size={'100'} color={"white"} />
                            :<Logo259 className={" h-[200px] w-[200px]"} />}
                    </div>}

                    {!inputs.loadFuzzy&&inputs.fuzzyData.length>0?<div className='border p-4'>
                            <FuzzySweeper {...{ inputs, rowStyles }} />
                        </div>:
                        <div style={{height: (toggler.pageHeight-131)/2}} className='flex items-center justify-center border'>
                            {inputs.loadFuzzy?
                                <TailChaseLoader size={'100'} color={"white"} />
                            :<Logo259 className={" h-[200px] w-[200px]"} />}
                    </div>}
                </div>
                <CustomHover {...{alternativeBot:" hidden", hovered:inputs.hoverSpecial, left:"left-[365px]", top: "top-[200px]", width:"max-w-[400px]", text:"text-sm text-center" }}  >
                    Analyzes an existing specialty list for potential missing products to add to the list. Sweeps for two match types, exact and fuzzy. Exact matches are non-existing NDCs for existing drug names on the list. Fuzzy matches are suggestions for drug names (along with corresponding NDCs) to add based on existing similarly-named drugs. The similarly-named drugs are provided for reference.
                </CustomHover>
                <CustomHover {...{hovered:inputs.hoverFuzzy, left:"left-[365px]", top: "top-[420px]", alternativeBot:'hidden', width:"max-w-[400px]", text:"text-sm text-center" }}  >
                    Analyzes a formulary rate chart for missing drug names that may qualify for a rate, and suggests a rate based on associated existing drug names with that rate.
                </CustomHover>
            </div>

            {inputs.specialtyData.length>0&&<table ref={specialRef} className='hidden text-left text-xs'>
                <thead>
                    <tr>
                        {Object.keys(inputs.specialtyData[0]).map(headers => (
                            <>
                                <th className='h-[100px] border p-4 bg-primary-dark text-white font-bold'>
                                    {headers}
                                </th>
                                {'\t'}
                            </>
                        ))}
                        
                    </tr>              
                </thead>
                    {'\n'}
                <tbody>
                    {inputs.specialtyData.map((bodyContent, index) => (
                        <>
                            <tr className={`border ${index%2===0?"bg-gray-300":""} `}>
                                {Object.keys(bodyContent).map((ele, ind) => (
                                    <td className='h-[50px] p-4'>
                                        {/* {console.log(ind, Object.keys(bodyContent).length)} */}
                                        {inputs.specialtyData[index][ele]}{ind===Object.keys(bodyContent).length-1?'\n':'\t'}
                                    </td>
                                ))}
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>}

            {inputs.fuzzyData.length>0&&<table ref={fuzzyRef} className='hidden  text-left text-xs'>
                <thead>
                    <tr>
                        {Object.keys(inputs.fuzzyData[0]).map(headers => (
                            <>
                                <th className='h-[80px] p-4 border bg-primary-dark text-white font-bold'>
                                    {headers}
                                </th>
                                {'\t'}
                            </>
                        ))}
                        
                    </tr>              
                </thead>
                    {'\n'}
                <tbody>
                    {inputs.fuzzyData.map((bodyContent, index) => (
                        <>
                            <tr className={`border ${index%2===0?"bg-gray-300":""} `}>
                                {Object.keys(bodyContent).map((ele, ind) => (
                                    <td className='h-[50px] p-4 '>
                                        {/* {console.log(ind, Object.keys(bodyContent).length)} */}
                                        {inputs.fuzzyData[index][ele]}{ind===Object.keys(bodyContent).length-1?'\n':'\t'}
                                    </td>
                                ))}
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>}
        </>
    );
};