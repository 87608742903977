import {  reloadClaim } from "./claimsLoad";
import { globalBoolHandler } from "./globalHandlers";

export async function loadList(set) {
    try {
        if (extractFromLocal().accessToken) {
            const data = await fetch('/api/list-uploaded-files/', {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                }
            });
            if (data.ok) {
                const results = await data.json()
                set(results);
            }
        }
    } catch (err) {
        console.log(err)
    };
};

export async function saveCase(context, overwrite, memo, toggler) {
    toggler.setStatus("Saving current case, please wait....");
    const currentCase = context.casenumber;
    
    const payload =  {[currentCase]: {
        exclusionSettings: context.exclusionSettings,
        mainData: context.mainData,
        settings: context.settings,
        results: context.results,
        target: context.target,
        carriers: context.models.carriers,
        pricingLens: context.pricingLens,
        analytics: context.analytics,
        analyticsData: context.analyticsData,
        memo: memo
    }};

    try {
        globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, false);
        const data = await fetch(`/api/upload-scenario-files/?casenumber=${currentCase}&overwrite=${overwrite}`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });
        if (data.ok) {
            alert("Current scenario has been saved");
            toggler.setStatus("");
            context.setSettings({
                ...context.settings,
                savedCase: true,
            });
            context.setModels(data => ({
                ...data,
                memo
            }));
        };
    } catch (err) {
        console.log(err)
        toggler.setStatus(err);
        setTimeout(() => {
            toggler.setStatus('');
        }, 5000)
    };
};

export async function loadSaved(casename, context, toggler, setLoader) {
    if (setLoader) {
        setLoader(true);
    };
    
    try {

        if (extractFromLocal().accessToken) {
            const data = await fetch(`/api/load-scenario/${casename}`, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                }
            });
            if (data.ok) {

                    const results = await data.json();

                    if (results.state) {                        
                        const use = results.state[casename];

                        context.setExclusionSettings(use.exclusionSettings);
                        context.setMainData(use.mainData);
                        context.setResults(use.results);
                        context.setTarget(use.target);
                        context.setCase(casename);
                        context.setSettings({
                            ...use.settings,
                            savedCase: true,
                            locked: false
                        });
                        context.setModels(data => ({
                            ...data,
                            carriers: use.carriers,
                        }))
                        // context.setCarriers(use.carriers);
                        context.setPricingLens(use.pricingLens);
                        context.setModels(data => ({
                            ...data,
                            memo: use.memo
                        }))
                        context.addAnalyticsData(use.analyticsData?use.analyticsData:{
                            type: "standard",
                            drugs: [],
                            categories: [],
                            waterfall: [],
                            macro: null,
                            topVolume: []
                          })
                        context.setAnalytics(use.analytics?use.analytics:{
                            top20DrugsTable : {
                              standard: [],
                              target: []
                            },
                            top20CategoriesTable : {
                              standard: [],
                              target: []
                            },
                            highestProductsNoRebatesTable : {
                              standard: [],
                              target: []
                            },
                            mfrs : {
                              standard: [],
                              target: []
                            },
                            rebatable : {
                              standard: [],
                              target: []
                            },
                        })

                        globalBoolHandler("loadSave", toggler.toggle, toggler.setToggle, false)
                    }
                    refreshCasenumber(casename);
                };
            };
        if (setLoader) {
            setLoader(false)
        };
    } catch (err) {
        console.log(err)
    };
};

export function extractFromLocal(){
    const storage = sessionStorage.getItem('259rx_data');
    return JSON.parse(storage);
};

export async function refreshCasenumber(casenumber) {
    if (casenumber) {
        sessionStorage.setItem("259rx_data", JSON.stringify({
            ...extractFromLocal(),
            casenumber: casenumber,
        }));
    };
};

export async function getCasesForOrganization() {
    try {
        let data = await fetch("/api/get_org_deal_stats", {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        if (data.ok) {
            return await data.json()
        }
    } catch (err) {
        console.log(err);
    };
};

export async function getAggStatsForOrg(hashtags) {

    var hashtagstring = ""
    hashtags.forEach(tag => {
        hashtagstring += `?hashtags=${tag}`
    });

    try {
        let data = await fetch(`/api/get_agg_org_stats${hashtagstring}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        if (data.ok) {
            return await data.json()
        }
    } catch (err) {
        console.log(err);
    };
};

export async function saveDealStatus(casenumber, newStatus) {
    try {
        let data = await fetch(`/api/update-case-status/${casenumber}?new_status=${newStatus}`, {
            method: "PATCH",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        if (data.ok) {
            return await data.json()
        }
    } catch (err) {
        console.log(err);
    };
};

export async function deleteSavedCase(casenumber, caseuser) {
    try {
        let data = await fetch(`/api/delete-case/${caseuser}/${casenumber}`, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });
        
        return data;

    } catch (err) {
        alert(err);
        console.log(err);
    };
};

export async function cloneCase(current, newCase, username, assigntome, memo, setCase, toggler, context) {

    try {
        let data = await fetch(`/api/clone-case/?current_casenumber=${current}&new_casenumber=${newCase}&case_username=${username}&assigntome=${assigntome?true:false}&memo=${memo}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }

        });

        let response = await data.json();
        console.log(response)
        
        if (data.ok) {
            alert(response.message);
            setCase(response.payload.new_casenumber);
            globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, false);
            reloadClaim( 
                response.payload.tablename, 
                context.settings.claims.startDate, 
                context.settings.claims.endDate, 
                context.settings.claims.enablePhcyAnalytics, 
                context.setMainData, 
                context.settings.modelSettings,
                response.payload.new_casenumber
            );
            const local = sessionStorage.getItem("259rx_data");
            const stored = JSON.parse(local);

            sessionStorage.setItem("259rx_data", JSON.stringify({
                ...stored,
                casenumber: response.payload.new_casenumber,
            }));
        };

    } catch (err) {
        alert(err);
        console.log(err);
    };
};

export async function downloadCase(caseuser, casenumber, loading) {
    loading(true);
    try {
        let data = await fetch(`/api/download-excel/${caseuser}/${casenumber}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }

        });

        let response = await data.blob();
        
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = casenumber+".xlsx";
        link.href = window.URL.createObjectURL(
            new Blob([response]), {type: "application/octet-stream"},
        );
        link.click();
        loading(false);
        
    } catch (err) {
        alert(err);
        loading(false);
        console.log(err);
    };
};

export function formatDate(date){
    const useDate = new Date(date);
    return `${useDate.getMonth()+1}/${useDate.getDate()}/${useDate.getFullYear()} - ${useDate.getHours()}:${useDate.getMinutes()}:${ useDate.getSeconds()}`
};
