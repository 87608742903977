import React from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FixedSizeList } from "react-window";

export default function FuzzySweeper({inputs, rowStyles,}) {

    const toggler = UseToggleContext();

    const fuzzySweeper = ({data, index, style,}) => {
        return ( 
            <div key={`${index}: fuzzy sweeper data ${data[index].associated_drugnames}`} style={style} className={` ${index%2===1?"bg-gray-400 ":""} bg-background-light flex border w-full items-center`}>
                {Object.entries(data[index]).map(ele => (<>
                    <div className={rowStyles}>
                        {ele[1]}
                    </div>
                </>))}
            </div>
        );
    };
    return (
        <>
            <div className='font-bold uppercase tracking-widest text-white'>
                Fuzzy Sweeper
            </div>
            <div style={{width:toggler.pageWidth-410}} className='flex h-[60px] bg-background-light font-bold capitalize tracking-widest'>
                {Object.keys(inputs.fuzzyData[0]).map((headers, idx) => (
                    <div key={`${idx} fuzzy sweeper headers ${headers}`} className={rowStyles+" whitespace-pre-line last:mr-1"}>
                        {headers.split("_").join(" ")}
                    </div>
                ))}
            </div>
            <FixedSizeList
                height={(toggler.pageHeight-368)/2}
                width={toggler.pageWidth-410}
                initialScrollOffset={true}
                itemCount={inputs?.fuzzyData.length}
                itemSize={100} // Height of each row
                itemData={inputs?.fuzzyData}
                >
                    {fuzzySweeper}   
            </FixedSizeList>  
        </>
    );
};