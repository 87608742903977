import moment from "moment";
import { globalBoolHandler } from "./globalHandlers";
import {extractFromLocal} from "./loadSave";


let timer
export function loadInitialClaim (tName, baseStart, baseEnd, enablePhcyAnalytics, setMainData, setLoader, setStatus, channelDesignation, context) {
    clearTimeout(timer)
    setStatus("Loading Base Claims Data, please wait...")
    setLoader("kpi");

    fetch('/api/load_data/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
        },
        body: JSON.stringify({
            fileName: tName,
            startDate: baseStart,  // Pass start date
            endDate: baseEnd, // Pass end date,
            enablePhcyAnalytics, // Pass boolean,
            channelDesignation,
            casenumber: context.casenumber
        })
    })
    .then(response => response.json())
    .then(data => {

        if (data.success) {
            setMainData(data)
            getCarriers(context)
            context.setSettings(previous => ({
                ...previous,
                locked: true,
                savedCase: false,
                lockClaims: false,
            }));

            setLoader("");
            setStatus("");
	
        } else {
            console.error('Failed to load data');
            setTimeout(() => {
                setStatus("Failed to load data with message: " +data.error)
            }, 500)

            console.error(data.error);

            timer = setTimeout(() => {
                setLoader("")
                setStatus("")
            }, 10000)
        }
        
    })
    .catch(error => console.error('Error:', error));
};

export function reloadClaim (tName, baseStart, baseEnd, enablePhcyAnalytics, setMainData, channelDesignation, casenumber) {

    fetch('/api/load_data/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
        },
        body: JSON.stringify({
            fileName: tName,
            startDate: baseStart,  // Pass start date
            endDate: baseEnd, // Pass end date,
            enablePhcyAnalytics, // Pass boolean,
            channelDesignation,
            casenumber: casenumber
        })
    })
    .then(response => response.json())
    .then(data => {

        if (data.success) {
            setMainData(data)
            // getCarriers(context)
	
        } else {
            alert(data.error);
        }
        
    })
    .catch(error => console.error('Error:', error));
};


// -------------------- spec status --------------------------------------


export async function importClaims(tableName, fields, data, setMessage, setLoaded, setLoading, options, validate, headersAvail, fileType, setPageData, tableCreator, mainPageData, setModels){
    setLoading(true);
    validate(prev => ({
        ...prev,
        validated: false
    }));
    let timerSet = undefined;

    var map

    if (fileType === "claims") {
        const {
            ndc,
            qty,
            channel,
            days_supply,
            pharmacy_id,
            net_claims,
            carrier,
        } = fields;
        
        const { 
            compound_indicator,
            daw_code,
            date_of_fill,
            claim_status
        } = options;
    
        map = {
            [ndc] :  "ndc",
            [pharmacy_id] :  "pharmacy_id",
            [qty] :  "quantity",
            [carrier] :  "carrier",
            [channel] :  "channel",
            [net_claims] :  "net_claims",
            [days_supply] :  "days_supply",
            [date_of_fill] :  "date_of_fill",
            [claim_status] :  "claim_status",
            [daw_code] :  "daw_code",
            [compound_indicator] :  "compound_indicator"
        };
    } else if (fileType === 'payment') {
        const {
            ndc,
            gpi,
            date_of_service,
            lob1,
            lob2,
            lob3,
            lob4,
            lob5,
            // pass_through_1,
            // pass_through_2,
            // pass_through_3,
            guarantee_1,
            guarantee_2,
            guarantee_3,
            formulary_id,
            claim_id,
            gross_amt_1,
            gross_amt_2,
            gross_amt_3,
            net_amt_1,
            net_amt_2,
            net_amt_3
        } = fields;
        
        map = {
            [ndc]: "ndc",
            [gpi]: "gpi",
            [lob1]: "lob1",
            [lob2]: "lob2",
            [lob3]: "lob3",
            [lob4]: "lob4",
            [lob5]: "lob5",
            [guarantee_1]: "guarantee_1",
            [guarantee_2]: "guarantee_2",
            [guarantee_3]: "guarantee_3",
            [formulary_id]: "formulary_id",
            [claim_id]: "claim_id",
            [gross_amt_1]: "pmt_amt1",
            [gross_amt_2]: "pmt_amt2",
            [gross_amt_3]: "pmt_amt3",
            [date_of_service]: "date_of_fill",
            [net_amt_1]: "net_amt_1",
            [net_amt_2]: "net_amt_2",
            [net_amt_3]: "net_amt_3",
        };
    };

    const formData = new FormData();
    formData.append("csvfile", data)
    formData.append("mapping", JSON.stringify(map))

    await fetch(`/api/upload_csv/${tableName}?headers=${headersAvail}&file_type=${fileType}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
        },
        body: formData
    },
    timerSet = setTimeout(() => {
        setMessage(["Please wait a moment, this may take a few minutes. Please do not exit out of this wizard until the import process is fully complete!"])
    }, [4000])
    )
    .then(async response => await response.json())
    .then(data => {
        if (data.duration) {
            setMessage([
                `Your table has been imported, please move onto the next step..`
            ]);
            validate(prev => ({
                ...prev,
                validated: true
            }));
            setLoaded('working!', data);
            var currentDate = new Date();
            // if(mainPageData) {
                setModels(data => ({
                    ...data,
                    modelList: {
                        ...data.modelList,
                        [`${fileType}_tables_details`]:[
                            ...data.modelList.claims_tables_details,
                            {table_name: tableName, created_by: tableCreator, created_at: currentDate }
                        ]
                    }
                }))
            // } 
            if (!mainPageData) {
                setPageData(data => ({
                    ...data,
                    [`${fileType}_tables_details`]:[
                        ...data[`${fileType}_tables_details`],
                        {table_name: tableName, created_by: tableCreator, created_at: currentDate }
                    ]
                }));
            };
        } else {
            setMessage([
                `There is an error with the following issue: ${data.detail}`
            ]);
            validate(prev => ({
                ...prev,
                validated: false
            }));
        }
        setLoading(false);
        clearTimeout(timerSet);
    })
    .catch(err => {
        console.error(err);
        setLoading(false);
        clearTimeout(timerSet);
        validate(prev => ({
            ...prev,
            validated: false
        }));
        return setMessage([
            `There is an error with the following issue: ${err}`
        ]);
    });
};

export function handleInputChange (e, context, bool) {
    const {name, value, checked} = e.target;

    if (name === "baseFormulary" || name === "targetFormulary" ) {
        context.setSettings({
            ...context.settings,
            locked: true,
            savedCase: false,
            formulary: {
                ...context.settings.formulary,
                [name]: value
            },
        });
    } else if (name==="brandDefinition"||name==="otcDefinition"||name==="specialtyList") {
        context.setSettings({
            locked: true,
            savedCase: false,
            ...context.settings,
            exclusions: {
                ...context.settings.exclusions,
                [name]: value,
            }
        })
    } else if (name==="year1") {
 
        context.setSettings({
            ...context.settings,
            locked: true,
            savedCase: false,
            year: {
                [name]: value
            },
        })
    } else if (name==="estimateLife"||name==="dealLength") {
 
        context.setExclusionSettings(data => ({
            ...data,
            [name]: +value
        }))
    } else if (name==="CrystalBallContractYear"||name==="CrystalBallFormulary") {
        context.setSettings({
            ...context.settings,
            locked: true,
            savedCase: false,
            crystal: {
                ...context.settings.crystal,
                [name]: value
            },
        })
    }  else if (name==="channelDesignation"||name==="daysBreak"||name==="modelSpecialtyList"||name==="lDDList"||name==="priceDate") {
        context.setSettings({
            ...context.settings,
            locked: true,
            lockClaims: true,
            savedCase: false,
            modelSettings: {
                ...context.settings.modelSettings,
                [name]: value
            },
        });
    } else {
        context.setSettings({
            ...context.settings,
            locked: true,
            savedCase: false,
            claims: {
                ...context.settings.claims,
                [name]: bool?checked:value
            },
        });
    };
};

export function handleInputChangeSimple(setting, name, value, context) {
    context.setSettings({
        ...context.settings,
        locked: true,
        savedCase: false,
        [setting]: {
            ...context.settings.formulary,
            [name]: value
        },
    });
}

export async function loadModelSettings(addModels) {
    try {
        const data = await fetch('/api/serveDropdowns/',
            {       
                    headers: { 
                        'Content-Type' : 'application/json',
                        Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
                    }
            })
        if (data.ok) {
            const results = await data.json()
            addModels(data => ({
                ...data,
                modelList:results
            }));
        }
    } catch(err) {
        console.log(err);
    };
};

export async function getCarriers(context) {
    try {
        let data = await fetch(`/api/get_carriers?casenumber=${context.casenumber}`,
        {headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },});

        if (data.ok) {
            const response = await data.json(); 

            context.setModels(data => ({
                ...data, 
                carriers: convertToObject(response.carriers)
            }));
        };
        
    } catch (err) {
        console.log(err);
    };
};

function convertToObject(array) {
    let list = []

    array.forEach(ele => {
        list.push({
            value: ele,
            label: ele
        });
    });

    return list;
};

export async function deleteUserTable(tableName, set, loader) {
    loader(true);
    try {
        const data = await fetch(`/api/delete-table/${tableName}`, {
            method: "DELETE",
            headers: { 
                'Content-Type'  : 'application/json',
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`}
        });

        let response = await data.json();

        if (data.ok) {
            alert(`File ${tableName} has been successfully deleted!`);
            loadModelSettings(set);
            loader(false);
            // listCustomList(set);
            return response;
        };
        
    } catch (err) {
        alert(`Error deleting ${tableName} with following message : ${err}`);
        loader(false);
        console.log(err);
    };
};

export async function previewClaimsDistribution(filename, event, togglersetDataRange) {
    try {
        const data = await fetch(`/api/preview_claims_distribution/?fileName=${filename}`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });
        let response = await data.json();

        if (data.ok) {
            var dates = {}
            var min = new Date()
            var max = new Date('1980-01')
            if (response.chartData?.length>0) {
                response.chartData.forEach((date) => {
                    if (new Date(min).getTime() > new Date(date.name).getTime()) {
                        min = date.name
                    }
                    if (new Date(max).getTime() < new Date(date.name).getTime()) {
                        max = date.name
                    }
                });
            };

            dates = {
                min:min+"-01",
                max:checkMonths(max)?+"-31":max+"-30"
            };

            togglersetDataRange({
                "data": response.chartData,
                "dates": dates,
                "location": {
                    "pageX": event.pageX,
                    "pageY": event.pageY
                },
                "focus": true,
            });
        };

    } catch (err) {
        console.log(err)
    };
};

function checkMonths(date) {
    var thirtyone = ["01","03","05","07","08","10","12"]
    var month = date.substring(5);

    return thirtyone.some(el => (
        el === month
    ));
};





export async function importXLSXClaims(fileName, sheetName, fields, data, setMessage, setLoaded, setLoading, options, validate, headersAvail, fileType ){
    setLoading(true);
    validate(prev => ({
        ...prev,
        validated: false
    }));
    let timerSet = undefined;

    var map

    if (fileType === "claims") {
        const {
            ndc,
            qty,
            channel,
            days_supply,
            pharmacy_id,
            net_claims,
            carrier,
        } = fields;
        
        const { 
            compound_indicator,
            daw_code,
            date_of_fill,
            claim_status
        } = options;
    
        map = {
            [ndc] :  "ndc",
            [pharmacy_id] :  "pharmacy_id",
            [qty] :  "quantity",
            [carrier] :  "carrier",
            [channel] :  "channel",
            [net_claims] :  "net_claims",
            [days_supply] :  "days_supply",
            [date_of_fill] :  "date_of_fill",
            [claim_status] :  "claim_status",
            [daw_code] :  "daw_code",
            [compound_indicator] :  "compound_indicator"
        };
    } else if (fileType === 'payment') {
        const {
            ndc,
            gpi,
            date_of_service,
            lob1,
            lob2,
            lob3,
            lob4,
            lob5,
            // pass_through_1,
            // pass_through_2,
            // pass_through_3,
            guarantee_1,
            guarantee_2,
            guarantee_3,
            formulary_id,
            claim_id,
            gross_amt_1,
            gross_amt_2,
            gross_amt_3,
            net_amt_1,
            net_amt_2,
            net_amt_3
        } = fields;
        
        map = {
            [ndc]: "ndc",
            [gpi]: "gpi",
            [lob1]: "lob1",
            [lob2]: "lob2",
            [lob3]: "lob3",
            [lob4]: "lob4",
            [lob5]: "lob5",
            [guarantee_1]: "guarantee_1",
            [guarantee_2]: "guarantee_2",
            [guarantee_3]: "guarantee_3",
            [formulary_id]: "formulary_id",
            [claim_id]: "claim_id",
            [gross_amt_1]: "pmt_amt1",
            [gross_amt_2]: "pmt_amt2",
            [gross_amt_3]: "pmt_amt3",
            [date_of_service]: "date_of_fill",
            [net_amt_1]: "net_amt_1",
            [net_amt_2]: "net_amt_2",
            [net_amt_3]: "net_amt_3",
        };
    };

    const formData = new FormData();
    formData.append("csvfile", data)
    formData.append("mapping", JSON.stringify(map))

    await fetch(`/api/upload_excel/${fileName}?headers=${headersAvail}&file_type=${fileType}&sheet_name=${sheetName}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
        },
        body: formData
    },
    timerSet = setTimeout(() => {
        setMessage(["Please wait a moment, this may take a few minutes. Please do not exit out of this wizard until the import process is fully complete!"])
    }, [4000])
    )
    .then(async response => await response.json())
    .then(data => {
        if (data.duration) {
            setMessage([
                `Your table has been imported, please move onto the next step..`
            ]);
            validate(prev => ({
                ...prev,
                validated: true
            }));
            setLoaded('working!', data);
            // const currentDate = new Date();
            // if(mainPageData) {
            //     setModels(data => ({
            //         ...data,
            //         modelList: {
            //             ...data.modelList,
            //             [`${fileType}_tables_details`]:[
            //                 ...data.modelList.claims_tables_details,
            //                 {table_name: tableName, created_by: tableCreator, created_at: `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}T${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}.${currentDate.getMilliseconds()}` }
            //             ]
            //         }
            //     }))
            // } else {
            //     setPageData(data => ({
            //         ...data,
            //         [`${fileType}_tables_details`]:[
            //             ...data[`${fileType}_tables_details`],
            //             {table_name: tableName, created_by: tableCreator, created_at: `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}T${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}.${currentDate.getMilliseconds()}` }
            //         ]
            //     }));
            // };
        } else {
            setMessage([
                `There is an error with the following issue: ${data.detail}`
            ]);
            validate(prev => ({
                ...prev,
                validated: false
            }));
        }
        setLoading(false);
        clearTimeout(timerSet);
    })
    .catch(err => {
        console.error(err);
        setLoading(false);
        clearTimeout(timerSet);
        validate(prev => ({
            ...prev,
            validated: false
        }));
        return setMessage([
            `There is an error with the following issue: ${err}`
        ]);
    });
};

export async function uploadCSV2500(filename, headersAvail, fileType, fileContent, toggler, setLoading, step) {
    try {
        setLoading(true);
        const formData = new FormData();
        formData.append("stdfile", fileContent, 'body')

        const response = await fetch(`/api/upload_csv_2500/${filename}?file_type=${fileType}`, {
            method: "POST",
            headers: { 
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: formData
        });


        const data = await response.json();

        if (response.ok) {
            alert("Upload sucessfull with detail: "+data.detail);
            setLoading(false);
            globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false);
            step(2);
        } else {
            console.log(data.detail)
        }

    } catch (err) {
        alert(`Error with file upload with message from server: ${err}`)
        setLoading(false);
    };
};



export async function importClaimSimple(tableName, map, data, setMessage, setLoaded, setLoading, validate, headersAvail, fileType, setPageData, tableCreator, mainPageData, setModels, step){
    setLoading(true);
    validate(prev => ({
        ...prev,
        validated: false
    }));
    let timerSet = undefined;
  
    const formData = new FormData();
    formData.append("csvfile", data)
    formData.append("mapping", JSON.stringify(map))

    await fetch(`/api/upload_csv/${tableName}?headers=${headersAvail}&file_type=${fileType}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
        },
        body: formData
    },
    timerSet = setTimeout(() => {
        setMessage(["Please wait a moment, this may take a few minutes. Please do not exit out of this wizard until the import process is fully complete!"])
    }, [4000])
    )
    .then(async response => await response.json())
    .then(data => {
        if (data.duration) {
            setMessage([
                `Your table has been imported, please move onto the next step..`
            ]);
            validate(prev => ({
                ...prev,
                validated: true
            }));
            setLoaded('working!', data);
            var currentDate = new Date();
            // if(mainPageData) {
                setModels(data => ({
                    ...data,
                    modelList: {
                        ...data.modelList,
                        [`${fileType}_tables_details`]:[
                            ...data.modelList.claims_tables_details,
                            {table_name: tableName, created_by: tableCreator, created_at: currentDate }
                        ]
                    }
                }))
            // } 
            if (!mainPageData) {
                setPageData(data => ({
                    ...data,
                    [`${fileType}_tables_details`]:[
                        ...data[`${fileType}_tables_details`],
                        {table_name: tableName, created_by: tableCreator, created_at: currentDate }
                    ]
                }));
            };
            step(2);
        } else {
            setMessage([
                `There is an error with the following issue: ${data.detail}`
            ]);
            validate(prev => ({
                ...prev,
                validated: false
            }));
        }
        setLoading(false);
        clearTimeout(timerSet);
    })
    .catch(err => {
        console.error(err);
        setLoading(false);
        clearTimeout(timerSet);
        validate(prev => ({
            ...prev,
            validated: false
        }));
        return setMessage([
            `There is an error with the following issue: ${err}`
        ]);
    });
};